import { EventDetails } from '../../../molecules/eventDetails';
import {
  EventContent,
  EventDetailLogo,
  EventDetailsContainer,
  EventDetailTitleMobile,
  EventPrice,
  EventPriceButton,
  EventPriceButtons,
  EventPriceTitle,
  EventProgress,
  LolLogo,
  StyledChip,
} from '../EventStyled';
import { Typography } from '../../../components/typography';
import { breakpoints, colors } from '../../../theme';
import { ReactComponent as TrophyIcon } from '../../../assets/icons/trophy-icon.svg';
import { EventState } from '../EventState';
import { Skeleton } from '../../../components/skeleton';
import { useToggle } from '../../../hooks/useToggle';
import { Rules } from './rules';
import { Prizes } from './prizes';
import { eventDateFormatter } from '../../../utils/date-formatters';
import { EventLinearProgress } from '../../../molecules/linearProgress';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

export const EventDetail = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { event, progressTotalValue, progressLabel, isLoadingEvent } = EventState.useContainer();
  const { isOpen: isRulesModalOpen, handleOpen: handleOpenRulesModal, handleClose: handleCloseRulesModal } = useToggle();
  const { isOpen: isPrizeModalOpen, handleOpen: handleOpenPrizeModal, handleClose: handleClosePrizeModal } = useToggle();

  return (
    <>
      <EventDetails>
        <EventDetailsContainer>
          {isMobile ? (
            <EventDetailTitleMobile>
              <EventDetailLogo>{isLoadingEvent ? <Skeleton /> : <LolLogo fill={colors.primary} width={51} height={51} />}</EventDetailLogo>
              <Typography ml={1.6} variant="h4">
                {isLoadingEvent ? <Skeleton width={300} /> : event?.title}
              </Typography>
            </EventDetailTitleMobile>
          ) : (
            <EventDetailLogo>{isLoadingEvent ? <Skeleton /> : <LolLogo fill={colors.primary} width={51} height={51} />}</EventDetailLogo>
          )}

          <EventContent>
            {!isMobile && <Typography variant="h4">{isLoadingEvent ? <Skeleton width="90%" /> : event?.title}</Typography>}

            <Typography mt={0.4} variant="body6">
              {isLoadingEvent ? (
                <Skeleton width="60%" />
              ) : (
                `${eventDateFormatter(event?.startDate)} - ${eventDateFormatter(event?.endDate)}`
              )}
            </Typography>

            <EventProgress>
              {isLoadingEvent ? (
                <Skeleton width="50%" />
              ) : (
                <>
                  <StyledChip label="Members only" variant="filled" size="small" color="secondary" />
                  <StyledChip label="Bi-Weekly" variant="filled" size="small" color="secondary" disabled />

                  <EventLinearProgress variant="determinate" label={progressLabel} value={progressTotalValue} />
                </>
              )}
            </EventProgress>
          </EventContent>

          <EventPrice>
            {isLoadingEvent ? (
              <Skeleton width={210} />
            ) : (
              <>
                <EventPriceTitle>
                  <TrophyIcon width={18} height={18} fill={colors.primary} />

                  <Typography variant="body3" ml={1.2} color={colors.primary}>
                    Win up to $300
                  </Typography>
                </EventPriceTitle>

                <EventPriceButtons>
                  <EventPriceButton variant="outlined" size="small" onClick={handleOpenRulesModal}>
                    Rules
                  </EventPriceButton>

                  <EventPriceButton variant="outlined" size="small" onClick={handleOpenPrizeModal}>
                    Prizing
                  </EventPriceButton>
                </EventPriceButtons>
              </>
            )}
          </EventPrice>
        </EventDetailsContainer>
      </EventDetails>

      <Rules isOpen={isRulesModalOpen} onClose={handleCloseRulesModal} />
      <Prizes isOpen={isPrizeModalOpen} onClose={handleClosePrizeModal} />
    </>
  );
};
