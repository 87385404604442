import styled from '@emotion/styled';
import { ReactComponent as LolImage } from '../../assets/svg/lol-image.svg';
import { Button } from '../../components/button';
import { breakpoints, mediaQuery } from '../../theme';

export const AccountContent = styled.form`
  padding: 2rem 2rem 4rem 2rem;
  max-width: 424px;

  ${mediaQuery(breakpoints.mobile)} {
    max-width: 336px;
  }
`;

export const AccountContentElements = styled.div`
  padding: 0 2.4rem;

  ${mediaQuery(breakpoints.mobile)} {
    padding: 0 1.2rem;
  }
`;

export const GameInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3.2rem;
`;

export const LogoImage = styled(LolImage)`
  min-width: 64px;
  min-height: 64px;
  border-radius: 12px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 3.2rem;
`;

export const ConnectButton = styled(Button)`
  margin-bottom: 1.6rem;
`;
