import { createContainer } from 'unstated-next';
import { Auth } from '../auth';
import { useGetConnectInfo } from '../../queries/connect/useGetConnectInfo';
import { useMemo } from 'react';
import { useToggle } from '../../hooks/useToggle';

const useGameConnect = () => {
  const { user } = Auth.useContainer();
  const {
    data: connectInfo,
    isLoading: isLoadingConnectInfo,
    isError: isErrorConnectInfo,
    refetch: refetchConnectInfo,
  } = useGetConnectInfo({ enabled: Boolean(user) });

  const isConnected = useMemo(() => {
    return !isErrorConnectInfo;
  }, [isErrorConnectInfo]);

  const isConnectFinished = useMemo(() => isConnected && !isLoadingConnectInfo, [isConnected, isLoadingConnectInfo]);

  const { isOpen: isConnectedToastOpen, setIsOpen: setIsConnectedToastOpen } = useToggle();
  const { isOpen: isYourTeamMessageOpen, setIsOpen: setIsYourTeamMessageOpen } = useToggle();

  return {
    connectInfo,
    isLoadingConnectInfo,
    isConnected,
    isConnectFinished,
    isConnectedToastOpen,
    isYourTeamMessageOpen,
    setIsYourTeamMessageOpen,
    setIsConnectedToastOpen,
    refetchConnectInfo,
  };
};

export const GameConnect = createContainer(useGameConnect);
