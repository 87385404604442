import styled from '@emotion/styled';
import { breakpoints, mediaQuery } from '../../../theme';

export const TopDailyPerformersWrapper = styled.div`
  margin-top: 6.4rem;
`;

export const PerformersContainer = styled.div`
  margin-top: 1.4rem;

  ${mediaQuery(breakpoints.mobile)} {
    padding: 1.2rem 0;
    overflow: auto;
  }
`;

export const PerformersWrapper = styled.div`
  display: flex;
`;
