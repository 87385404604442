import { useCallback, useState } from 'react';

export const useToggle = (initialValue: boolean = false) => {
  const [isOpen, setIsOpen] = useState<boolean>(initialValue);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    setIsOpen,
    handleOpen,
    handleClose,
  };
};
