import { Container } from '../../../components/container';
import { Typography } from '../../../components/typography';
import { colors } from '../../../theme';
import {
  // CommunityActionsWrapper,
  CommunityAdmin,
  CommunityAdminImage,
  CommunityDescription,
  CommunityDetailsWrapper,
  CommunityImage,
  CommunityInfo,
  CommunityInfoWrapper,
  CommunitySocialItem,
  CommunitySocialWrapper,
  CommunityStatItem,
  CommunityStats,
  // StyledButton,
  UrlWrapper,
} from './CommunityInformationStyled';
// import { IconButton } from '../../../components/iconButton';
// import { ReactComponent as SettingsIcon } from '../../../assets/svg/settings-icon.svg';
import { CopyToClipboard } from '../../../components/copyToClipboard';
import { ReactComponent as TikTok } from '../../../assets/social-media/tiktok.svg';
import { ReactComponent as Facebook } from '../../../assets/social-media/facebook.svg';
import { ReactComponent as Discord } from '../../../assets/social-media/discord.svg';
import { ReactComponent as Twitch } from '../../../assets/social-media/twitch.svg';
import { ReactComponent as Instagram } from '../../../assets/social-media/instagram.svg';
import { ReactComponent as Youtube } from '../../../assets/social-media/youtube.svg';
import { ReactComponent as Twitter } from '../../../assets/social-media/twitter.svg';
import { CommunityState } from '../CommunityState';

export const CommunityInformation = () => {
  const { shareLink, community, isLoadingCommunity } = CommunityState.useContainer();

  if (!community || isLoadingCommunity) return <></>;
  return (
    <Container>
      <CommunityDetailsWrapper>
        <CommunityInfoWrapper>
          <CommunityImage src="https://picsum.photos/120/120" alt="community-image" />
          <CommunityInfo>
            <Typography variant="subtitle3" color={colors.white} textTransform="uppercase">
              Community
            </Typography>
            <Typography variant="h3" color={colors.white}>
              {community.name || 'Loading'}
            </Typography>

            <CommunityStats>
              <CommunityStatItem>
                <Typography variant="subtitle3" color={colors.white} textTransform="uppercase">
                  Members
                </Typography>
                <Typography variant="h4" color={colors.white}>
                  {community.membersCount}
                </Typography>
              </CommunityStatItem>
              <CommunityStatItem>
                <Typography mb={0.4} variant="subtitle3" color={colors.white} textTransform="uppercase">
                  Admin
                </Typography>

                <CommunityAdmin>
                  <CommunityAdminImage src="https://picsum.photos/32/32" alt="admin-image" />
                  <Typography ml={0.8} variant="body1" color={colors.white}>
                    {community.adminUsername}
                  </Typography>
                </CommunityAdmin>
              </CommunityStatItem>
            </CommunityStats>
          </CommunityInfo>
        </CommunityInfoWrapper>
        {/* <CommunityActionsWrapper>
          <StyledButton variant="contained" color="primary" size="medium">
            Join Community
          </StyledButton>

          <StyledButton variant="contained" color="primary" size="medium">
            Donate
          </StyledButton>

          <IconButton width={40} height={40}>
            <SettingsIcon width={20} height={20} color={colors.black} />
          </IconButton>
        </CommunityActionsWrapper> */}
      </CommunityDetailsWrapper>

      <CommunitySocialWrapper>
        <CommunitySocialItem>
          <Facebook width={28} height={28} />
          <Typography ml={0.8} color={colors.white} variant="subtitle2">
            link
          </Typography>
        </CommunitySocialItem>

        <CommunitySocialItem>
          <Instagram width={28} height={28} />
          <Typography ml={0.8} color={colors.white} variant="subtitle2">
            link
          </Typography>
        </CommunitySocialItem>

        <CommunitySocialItem>
          <Twitter width={28} height={28} />
          <Typography ml={0.8} color={colors.white} variant="subtitle2">
            link
          </Typography>
        </CommunitySocialItem>

        <CommunitySocialItem>
          <Youtube width={28} height={28} />
          <Typography ml={0.8} color={colors.white} variant="subtitle2">
            link
          </Typography>
        </CommunitySocialItem>

        <CommunitySocialItem>
          <TikTok width={32} height={32} />
          <Typography ml={0.8} color={colors.white} variant="subtitle2">
            link
          </Typography>
        </CommunitySocialItem>

        <CommunitySocialItem>
          <Discord width={28} height={28} />
          <Typography ml={0.8} color={colors.white} variant="subtitle2">
            link
          </Typography>
        </CommunitySocialItem>

        <CommunitySocialItem>
          <Twitch width={26} height={26} />
          <Typography ml={0.8} color={colors.white} variant="subtitle2">
            link
          </Typography>
        </CommunitySocialItem>
      </CommunitySocialWrapper>

      <CommunityDescription>
        <Typography mb={1.6} variant="subtitle1" color={colors.white} textTransform="uppercase">
          Bio
        </Typography>

        <Typography variant="body6" color={colors.white}>
          {community.description}
        </Typography>

        <UrlWrapper>
          <Typography variant="body6" color={colors.transparentLight50}>
            Share this link with your community so they can sign up and join your Nemesis event
          </Typography>

          <CopyToClipboard copyText={shareLink} />
        </UrlWrapper>
      </CommunityDescription>
    </Container>
  );
};
