import styled from '@emotion/styled';
import { Typography } from '../../components/typography';
import { breakpoints, colors, mediaQuery } from '../../theme';

export const SignupWrapper = styled.form`
  width: 250px;

  ${mediaQuery(breakpoints.mobile)} {
    width: 100%;
  }
`;

export const SignupTitle = styled(Typography)`
  color: ${colors.white};
  margin-bottom: 2.4rem;
`;

export const InputWrapper = styled.div`
  margin-bottom: 1.6rem;
`;

export const ConfirmationText = styled.div`
  font-size: 10px;
  font-weight: 450;
  color: ${colors.transparentLight25};
  margin-bottom: 1.6rem;
  letter-spacing: 0.04em;
`;
