import httpClient from '../../configs/axios';
import {
  CommunityCreatorUser,
  CommunityUser,
  UpdatePasswordData,
  UserPoints,
  UserProfile,
  UserProfileRequest,
  UserProfileResponse,
} from './types';

export const createCommunityUser = (data: CommunityUser) => {
  return httpClient.post('/users', data);
};

export const createCommunityCreator = (data: CommunityCreatorUser) => {
  return httpClient.post('/users/creator', data);
};

export const fetchUserPoints = (): Promise<UserPoints> => {
  return httpClient.get('/users/points');
};

export const claimUserPoints = () => {
  return httpClient.post('/users/points/claim');
};

export const fetchUserProfile = (): Promise<UserProfile> => {
  return httpClient.get('/users/me').then((response: UserProfileResponse) => ({
    id: response.id,
    teamId: response.teamId,
    teamName: response.teamName,
    username: response.username,
    email: response.email,
    lolUser: response.lolUser
      ? {
          id: response.lolUser.id,
          puuid: response.lolUser.puuid,
          summonerName: response.lolUser.summoner_name,
          userId: response.lolUser.user_id,
        }
      : null,
  }));
};

export const updateUserProfile = (userData: UserProfileRequest) => {
  return httpClient.post('/users/me', userData);
};

export const resetPassword = (email: string) => {
  return httpClient.post(`/users/password/reset`, { email });
};

export const updatePassword = (data: UpdatePasswordData) => {
  return httpClient.post(`/users/password/update`, data);
};
