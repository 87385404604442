import styled from '@emotion/styled';
import { breakpoints, mediaQuery } from '../../theme';

export const DialogContentWrapper = styled.div`
  padding: 2rem 2rem 4rem 2rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

export const DialogContent = styled.div`
  padding: 0 2.4rem;

  ${mediaQuery(breakpoints.mobile)} {
    padding: 0 1.2rem;
  }
`;

export const DialogCloseIcon = styled.div`
  display: flex;
  justify-content: flex-end;
`;
