import styled from '@emotion/styled';
import { Typography } from '../../components/typography';
import { breakpoints, colors, mediaQuery } from '../../theme';

export const LoginWrapper = styled.form`
  width: 250px;

  ${mediaQuery(breakpoints.mobile)} {
    width: 100%;
  }
`;

export const LoginTitle = styled(Typography)`
  color: ${colors.white};
  margin-bottom: 2.4rem;
`;

export const InputWrapper = styled.div`
  margin-top: 1.6rem;
  margin-bottom: 2.4rem;
`;
