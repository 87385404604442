import { StatItem, StatWrapper } from '../PlayerRankStyled';
import { colors } from '../../../theme';
import { Typography } from '../../../components/typography';
import { ReactComponent as KillIcon } from '../../../assets/icons/kill-icon.svg';
import { ReactComponent as AssistsIcon } from '../../../assets/icons/assists-icon.svg';
import { ReactComponent as PointsIcon } from '../../../assets/icons/points-icon.svg';

interface PlayerStatsProps {
  kills: number | string;
  assists: number | string;
  points: number | string;
}

export const PlayerStats = ({ kills, assists, points }: PlayerStatsProps) => {
  return (
    <>
      <StatWrapper>
        <KillIcon width={30} height={30} fill={colors.primary} />

        <StatItem>
          <Typography variant="subtitle3" textTransform="uppercase" color={colors.primary}>
            Kills
          </Typography>

          <Typography variant="h7" color={colors.primary}>
            {kills}
          </Typography>
        </StatItem>
      </StatWrapper>

      <StatWrapper>
        <AssistsIcon width={30} height={30} fill={colors.primary} />

        <StatItem>
          <Typography variant="subtitle3" textTransform="uppercase" color={colors.primary}>
            Assists
          </Typography>

          <Typography variant="h7" color={colors.primary}>
            {assists}
          </Typography>
        </StatItem>
      </StatWrapper>

      <StatWrapper>
        <PointsIcon width={30} height={30} fill={colors.primary} />

        <StatItem>
          <Typography variant="subtitle3" textTransform="uppercase" color={colors.primary}>
            Points
          </Typography>

          <Typography variant="h7" color={colors.primary}>
            {points}
          </Typography>
        </StatItem>
      </StatWrapper>
    </>
  );
};
