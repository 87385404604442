import { useQuery } from 'react-query';
import * as connectService from '../../services/connectService';
import QueryKeys from '../../constants/queryKeys';

interface GetConnectInfoParams {
  enabled?: boolean;
}

export const useGetConnectInfo = ({ enabled = true }: GetConnectInfoParams) => {
  return useQuery({
    queryKey: QueryKeys.ConnectInfo,
    queryFn: connectService.getConnectInfo,
    enabled: enabled,
  });
};
