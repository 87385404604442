import React from 'react';
import { PrizesProps } from './types';
import { Dialog } from '../../../../components/dialog';
import { PrizeItemWrapper, PrizesContent, PrizesContentWrapper } from './PrizesStyled';
import { CloseIconWrapper } from '../../EventStyled';
import { colors } from '../../../../theme';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/x-icon.svg';
import { Typography } from '../../../../components/typography';
import { prizes } from './helpers';

export const Prizes = ({ isOpen, onClose }: PrizesProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <PrizesContentWrapper>
        <CloseIconWrapper>
          <CloseIcon cursor="pointer" fill={colors.transparentLight50} width={16} height={16} onClick={onClose} />
        </CloseIconWrapper>

        <PrizesContent>
          <Typography textTransform="capitalize" mt={0.4} mb={3.2} color={colors.white} variant="h5" textAlign="center">
            Prize pool
          </Typography>

          {prizes.map((prizeItem, prizeItemIndex) => (
            <PrizeItemWrapper key={prizeItemIndex}>
              <Typography width={45} color={colors.primary} variant="body3">
                {prizeItem.label}
              </Typography>
              <Typography color={colors.white} variant="body4">
                {`$${prizeItem.prize}`}
              </Typography>
            </PrizeItemWrapper>
          ))}
        </PrizesContent>
      </PrizesContentWrapper>
    </Dialog>
  );
};
