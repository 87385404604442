import { ProfileFormValues, ProfileProps } from './types';
import { Dialog } from '../../components/dialog';
import {
  ButtonsWrapper,
  ConfirmButton,
  ConnectionButton,
  ConnectionItem,
  ConnectionTexts,
  ConnectionType,
  ConnectionWrapper,
  FullNameWrapper,
  IconWrapper,
  ProfileContent,
  ProfileContentWrapper,
  StyledInput,
} from './ProfileStyled';
import { breakpoints, colors } from '../../theme';
import { ReactComponent as CloseIcon } from '../../assets/icons/x-icon.svg';
import { ReactComponent as RiotIcon } from '../../assets/icons/riot-icon.svg';
import { ReactComponent as CircleDiscordIcon } from '../../assets/icons/circle-discord-icon.svg';
import { Typography } from '../../components/typography';
import { Button } from '../../components/button';
import { Switch } from '../../components/switch';
import { GameConnect } from '../../context/gameConnect';
import { Auth } from '../../context/auth';
import { useFormik } from 'formik';
import { profileSchema } from '../../validation/userSchemas';
import { useGetUserProfile } from '../../queries/user/useGetUserProfile';
import { useUpdateUserProfile } from '../../queries/user/useUpdateUserProfile';
import { UserProfileRequest } from '../../services/userService/types';
import { LoaderContainer } from '../loaderContainer';
import { CircularProgress } from '../../components/circularProgress';
import { useNavigate } from 'react-router-dom';
import Routes from '../../constants/routes';
import { MainLayoutState } from '../layout/mainLayout/MainLayoutState';
import { useDisconnectFromAccount } from '../../queries/connect/useDisconnectFromAccount';
import { redirectToDiscord } from '../../utils/helpers';
import { useMediaQuery } from '../../hooks/useMediaQuery';

export const Profile = ({ isOpen, onClose }: ProfileProps) => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const navigate = useNavigate();
  const { isConnected, refetchConnectInfo } = GameConnect.useContainer();
  const { clearAuthToken } = Auth.useContainer();
  const { useRedirectToRiotLogin } = MainLayoutState.useContainer();
  const { data: userProfile, isLoading: isLoadingUserProfile } = useGetUserProfile();
  const { mutate: updateProfile, isLoading: isUpdatingProfile, isError: isErrorUpdatingProfile } = useUpdateUserProfile();
  const { mutate: disconnectFromAccount } = useDisconnectFromAccount();
  const { mutate: redirectToRiot } = useRedirectToRiotLogin();

  const { values, errors, touched, handleChange, handleSubmit } = useFormik<ProfileFormValues>({
    enableReinitialize: true,
    initialValues: userProfile
      ? { teamName: userProfile.teamName, username: userProfile.username, email: userProfile.email, password: '' }
      : { teamName: '', username: '', email: '', password: '' },

    validationSchema: profileSchema,

    onSubmit: (values) => {
      const userProfileData: UserProfileRequest = { username: values.username, email: values.email };
      if (values.password) userProfileData.password = values.password;
      updateProfile(userProfileData, {
        onSuccess: () => {
          onClose();
        },
      });
    },
  });

  const handleLogout = () => {
    clearAuthToken();
    navigate(Routes.LOGIN);
  };

  const handleDisconnectFromAccount = () => {
    disconnectFromAccount(undefined, {
      onSuccess: () => {
        refetchConnectInfo();
        onClose();
      },
    });
  };

  let content = (
    <>
      <IconWrapper>
        <CloseIcon cursor="pointer" fill={colors.transparentLight50} width={16} height={16} onClick={onClose} />
      </IconWrapper>

      <ProfileContent>
        <Typography textTransform="capitalize" textAlign="center" mt={0.4} mb={3.2} variant="h5" color={colors.white}>
          Profile
        </Typography>

        <Typography textTransform="capitalize" mb={2.4} variant="h7" color={colors.white}>
          Account settings
        </Typography>

        <StyledInput
          name="teamName"
          label="Team Name"
          color="secondary"
          value={values.teamName}
          error={Boolean(touched.teamName && errors.teamName)}
          helperText={touched.teamName && errors.teamName}
          onChange={handleChange}
          placeholder="Team Name"
          fullWidth
          autoComplete="off"
          focused={Boolean(values.teamName)}
          disabled
        />

        <StyledInput
          name="username"
          label="Username"
          color="secondary"
          value={values.username}
          error={Boolean(touched.username && errors.username)}
          helperText={touched.username && errors.username}
          onChange={handleChange}
          placeholder="Username"
          fullWidth
          autoComplete="off"
          focused={Boolean(values.username)}
        />

        <StyledInput
          name="email"
          label="Email"
          color="secondary"
          value={values.email}
          error={Boolean(touched.email && errors.email)}
          helperText={touched.email && errors.email}
          onChange={handleChange}
          placeholder="Email"
          fullWidth
          autoComplete="off"
          focused={Boolean(values.email)}
        />

        <StyledInput
          name="password"
          label="Password"
          color="secondary"
          value={values.password}
          error={Boolean(touched.password && errors.password)}
          helperText={touched.password && errors.password}
          onChange={handleChange}
          placeholder="Password"
          type="password"
          fullWidth
          autoComplete="off"
          focused={Boolean(values.password)}
        />

        {isErrorUpdatingProfile && (
          <Typography variant="subtitle3" color={colors.accentRed}>
            Something went wrong, try later
          </Typography>
        )}

        <FullNameWrapper>
          <Typography variant="h7" textTransform="capitalize" color={colors.white}>
            Display full name
          </Typography>

          <Switch />
        </FullNameWrapper>

        <Typography mt={0.8} variant="body6" color={colors.transparentLight50}>
          Display your full name along with your username.
        </Typography>

        <ConnectionWrapper>
          <ConnectionItem>
            <ConnectionType>
              <RiotIcon width={32} height={32} fill={colors.transparentLight50} />

              <ConnectionTexts>
                <Typography variant="body3" textTransform="capitalize" color={colors.white}>
                  Riot games
                </Typography>

                <Typography variant="subtitle2" color={colors.transparentLight50}>
                  {isConnected ? `Connected as: ${userProfile?.lolUser?.summonerName}` : 'Connect to participate in events'}
                </Typography>

                {isMobile && (
                  <>
                    {isConnected ? (
                      <ConnectionButton variant="outlined" size="extraSmallOutlined" onClick={handleDisconnectFromAccount}>
                        Disconnect
                      </ConnectionButton>
                    ) : (
                      <ConnectionButton variant="contained" size="extraSmall" onClick={() => redirectToRiot()}>
                        Connect
                      </ConnectionButton>
                    )}
                  </>
                )}
              </ConnectionTexts>
            </ConnectionType>

            {!isMobile && (
              <>
                {isConnected ? (
                  <ConnectionButton variant="outlined" size="extraSmallOutlined" onClick={handleDisconnectFromAccount}>
                    Disconnect
                  </ConnectionButton>
                ) : (
                  <ConnectionButton variant="contained" size="extraSmall" onClick={() => redirectToRiot()}>
                    Connect
                  </ConnectionButton>
                )}
              </>
            )}
          </ConnectionItem>
          <ConnectionItem>
            <ConnectionType>
              <CircleDiscordIcon width={32} height={32} fill={colors.transparentLight50} />

              <ConnectionTexts>
                <Typography variant="body3" textTransform="capitalize" color={colors.white}>
                  Discord
                </Typography>

                <Typography variant="subtitle2" color={colors.transparentLight50}>
                  Connect to join the community
                </Typography>

                {isMobile && (
                  <ConnectionButton variant="contained" size="extraSmall" onClick={redirectToDiscord}>
                    Connect
                  </ConnectionButton>
                )}
              </ConnectionTexts>
            </ConnectionType>

            {!isMobile && (
              <ConnectionButton variant="contained" size="extraSmall" onClick={redirectToDiscord}>
                Connect
              </ConnectionButton>
            )}
          </ConnectionItem>
        </ConnectionWrapper>

        <ButtonsWrapper>
          <ConfirmButton type="submit" variant="contained" size="medium" fullWidth disabled={isUpdatingProfile}>
            Confirm Changes
          </ConfirmButton>

          <Button variant="outlined" size="medium" fullWidth onClick={handleLogout}>
            Log Out
          </Button>
        </ButtonsWrapper>
      </ProfileContent>
    </>
  );

  if (isLoadingUserProfile) {
    content = (
      <LoaderContainer>
        <CircularProgress />
      </LoaderContainer>
    );
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <ProfileContentWrapper onSubmit={handleSubmit}>{content}</ProfileContentWrapper>
    </Dialog>
  );
};
