import React from 'react';
import { Table as MaterialTable, TableProps as MaterialTableProps } from '@mui/material';

type TableProps = {
  children: React.ReactNode;
} & MaterialTableProps;

export const Table = ({ children, ...rest }: TableProps) => {
  return <MaterialTable {...rest}>{children}</MaterialTable>;
};
