import { EventDetails } from '../../../molecules/eventDetails';
import {
  EventLogo,
  LolLogo,
  StepperControlsWrapper,
  StyledChip,
  CommunityEventDate,
  CommunityEventDetailsContainer,
  CommunityEventDetailsContent,
  CommunityEventDetailsProgress,
  CommunityEventDetailsStepper,
  CommunityEventDetailsWrapper,
  CommunityEventDetailTitle,
  CommunityEventTitle,
} from './CommunityEventsControlStyled';
import { breakpoints, colors } from '../../../theme';
import { MobileStepper } from '../../../components/mobileStepper';
import { StepperControls } from '../../../molecules/stepperControls';
import { Skeleton } from '../../../components/skeleton';
import { eventDateFormatter, shortEventDateFormatter } from '../../../utils/date-formatters';
import { useMemo } from 'react';
import { getEventProgressLabel, getEventProgressValue } from '../../event/helpers';
import { useNavigate } from 'react-router-dom';
import Routes from '../../../constants/routes';
import { EventLinearProgress } from '../../../molecules/linearProgress';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { CommunityState } from '../CommunityState';

export const CommunityEventsControl = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const navigate = useNavigate();
  const { isLoadingEvents, currentEvent, current, maxSteps, previous, next } = CommunityState.useContainer();

  const progressTotalValue = useMemo(
    () => getEventProgressValue(currentEvent?.startDate ?? '', currentEvent?.endDate ?? ''),
    [currentEvent?.startDate, currentEvent?.endDate]
  );
  const progressLabel = useMemo(
    () => getEventProgressLabel(currentEvent?.startDate ?? '', currentEvent?.endDate ?? ''),
    [currentEvent?.startDate, currentEvent?.endDate]
  );

  const isLoading = isLoadingEvents;

  const handleGoBack = () => {
    navigate(Routes.EVENTS);
  };

  return (
    <CommunityEventDetailsWrapper>
      <StepperControlsWrapper>
        <StepperControls label="Current events" onPrevious={previous} onNext={next} />
      </StepperControlsWrapper>

      <EventDetails>
        <CommunityEventDetailsContainer onClick={handleGoBack}>
          <EventLogo>{isLoading ? <Skeleton /> : <LolLogo fill={colors.primary} width={51} height={51} />}</EventLogo>

          <CommunityEventDetailsContent>
            <CommunityEventDetailTitle>
              {isLoading ? (
                <Skeleton width="50%" />
              ) : (
                <>
                  <CommunityEventTitle variant="body1" color={colors.black} textTransform="capitalize">
                    {currentEvent?.title}
                  </CommunityEventTitle>

                  <CommunityEventDate ml={0.4} variant="body6" color={colors.black}>
                    {isMobile
                      ? `${shortEventDateFormatter(currentEvent?.startDate)} • ${shortEventDateFormatter(currentEvent?.endDate)}`
                      : `• ${eventDateFormatter(currentEvent?.startDate)} • ${eventDateFormatter(currentEvent?.endDate)}`}
                  </CommunityEventDate>
                </>
              )}
            </CommunityEventDetailTitle>

            <CommunityEventDetailsProgress>
              {isLoading ? (
                <Skeleton width="70%" />
              ) : (
                <>
                  <StyledChip label="Members only" variant="filled" size="small" color="secondary" />
                  <StyledChip label="Bi-Weekly" variant="filled" size="small" color="secondary" disabled />

                  <EventLinearProgress variant="determinate" label={progressLabel} value={progressTotalValue} />
                </>
              )}
            </CommunityEventDetailsProgress>
          </CommunityEventDetailsContent>
        </CommunityEventDetailsContainer>

        <CommunityEventDetailsStepper>
          <MobileStepper variant="dots" activeStep={current} position="static" steps={maxSteps} nextButton={null} backButton={null} />
        </CommunityEventDetailsStepper>
      </EventDetails>
    </CommunityEventDetailsWrapper>
  );
};
