import React from 'react';
import { Typography as MaterialTypography, TypographyProps as MaterialTypographyProps } from '@mui/material';

interface TypographyProps extends MaterialTypographyProps {
  children: React.ReactNode;
}

export const Typography = ({ children, ...rest }: TypographyProps) => {
  return <MaterialTypography {...rest}>{children}</MaterialTypography>;
};
