import { Dialog } from '../../components/dialog';
import { DialogCloseIcon, DialogContent } from '../dialog';
import { ReactComponent as CloseIcon } from '../../assets/icons/x-icon.svg';
import { breakpoints, colors } from '../../theme';
import { QuestsProps } from './types';
import {
  ChallengeIcon,
  ChallengeName,
  ChallengePoint,
  ChallengePointAndIcon,
  ChallengePoints,
  ChallengesContentWrapper,
  ChallengesHeadline,
  ChallengesItem,
  ChallengesItemWrapper,
  ChallengesProgress,
} from './QuestsStyled';
import { ReactComponent as MapIcon } from '../../assets/icons/map-icon.svg';
import { Typography } from '../../components/typography';
import { Divider } from '../../components/divider';
import { ReactComponent as CountIcon } from '../../assets/icons/coin-icon.svg';
import { Button } from '../../components/button';
import { QuestIcon } from './QuestIcon';
import { Quest } from '../../services/questService/types';
import { useClaimQuestReward } from '../../queries/quest/useClaimQuestReward';
import { MainLayoutState } from '../layout/mainLayout/MainLayoutState';
import { useMediaQuery } from '../../hooks/useMediaQuery';

export const Quests = ({ isOpen, onClose }: QuestsProps) => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { quests, refetchUserPoints, refetchQuests } = MainLayoutState.useContainer();
  const { mutate: claimUserPoints } = useClaimQuestReward();

  const handleCollectReward = (quest: Quest) => {
    claimUserPoints(
      { questName: quest?.name || '' },
      {
        onSuccess: () => {
          refetchUserPoints();
          refetchQuests();
        },
      }
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <ChallengesContentWrapper>
        <DialogCloseIcon>
          <CloseIcon cursor="pointer" fill={colors.transparentLight50} width={16} height={16} onClick={onClose} />
        </DialogCloseIcon>

        <DialogContent>
          <ChallengesHeadline>
            <MapIcon fill={colors.primary} width={64} height={64} />
            <Typography mt={0.4} mb={3.2} variant="h5" color={colors.white}>
              Quests
            </Typography>
            <Divider />
          </ChallengesHeadline>

          <Typography mt={3.2} variant="body4" color={colors.transparentLight50}>
            Complete quests to earn gold, use gold to level up your avatar and gain an edge over your competition.
          </Typography>

          <ChallengesItemWrapper>
            {quests?.map((quest: Quest, index: number) => {
              const progressValue = (quest.progress / quest.goal) * 100;

              return (
                <ChallengesItem key={index}>
                  <ChallengeIcon>
                    <QuestIcon icon={quest.icon} fill={colors.primary} width={34} height={34} />
                  </ChallengeIcon>
                  <ChallengeName>
                    <Typography mb={0.8} variant={isMobile ? 'subtitle1' : 'body3'} color={colors.white}>
                      {quest.description}
                    </Typography>

                    <ChallengesProgress variant="determinate" value={progressValue} />
                  </ChallengeName>
                  <ChallengePoints>
                    <ChallengePointAndIcon>
                      <ChallengePoint mr={0.4} variant="body3" color={colors.primary}>
                        +{quest.points}
                      </ChallengePoint>
                      <CountIcon width={13} height={13} fill={colors.primary} />
                    </ChallengePointAndIcon>

                    {quest.completed ? (
                      quest.claimed ? (
                        <Button disabled variant="outlined" color="primary" size="tiny">
                          Claimed
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" size="tiny" onClick={() => handleCollectReward(quest)}>
                          Claim
                        </Button>
                      )
                    ) : (
                      <Typography textAlign="right" variant="body5" color={colors.transparentLight50}>
                        {quest.progress}/{quest.goal}
                      </Typography>
                    )}
                  </ChallengePoints>
                </ChallengesItem>
              );
            })}
          </ChallengesItemWrapper>

          <Button variant="contained" color="primary" size="medium" fullWidth onClick={onClose}>
            Got It!
          </Button>
        </DialogContent>
      </ChallengesContentWrapper>
    </Dialog>
  );
};
