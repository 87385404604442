import {
  AvatarImage,
  AvatarImageWrapper,
  NemesisRankText,
  NoConnectionRankContainer,
  PlayerRankColumn,
  PlayerRankTeam,
  PlayerRankWrapper,
  RankAvatarWrapper,
  RankContainer,
  RankContainerWrapperMobile,
  StatsContainer,
} from './PlayerRankStyled';
import { Typography } from '../../components/typography';
import { breakpoints, colors } from '../../theme';
import { ReactComponent as RiotIcon } from '../../assets/icons/riot-icon.svg';
import { Button } from '../../components/button';
import { GameConnect } from '../../context/gameConnect';
import { MainLayoutState } from '../layout/mainLayout/MainLayoutState';
import { EventState } from '../../pages/event/EventState';
import { PlayerStats } from './playerStats';
import { Skeleton } from '../../components/skeleton';
import { useMediaQuery } from '../../hooks/useMediaQuery';

export const PlayerRank = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { isConnectFinished, isLoadingConnectInfo } = GameConnect.useContainer();
  const { useRedirectToRiotLogin } = MainLayoutState.useContainer();
  const { eventUser, isLoadingEventUser, handleGotoTeamPage } = EventState.useContainer();
  const { mutate: redirectToRiot } = useRedirectToRiotLogin();

  const isLoading = isLoadingConnectInfo || isLoadingEventUser;

  let rankContent: JSX.Element | null = (
    <Typography variant="body6" mt={1.6} color={colors.transparentLight50} align="center">
      Connect your Riot ID to start ranking up!
    </Typography>
  );

  let pointsContent: JSX.Element | null = (
    <Button
      variant="contained"
      color="error"
      size="medium"
      startIcon={<RiotIcon width={16} height={16} />}
      onClick={() => redirectToRiot()}
    >
      Connect Riot ID
    </Button>
  );

  if (isConnectFinished) {
    rankContent = (
      <Typography variant="h1" color={colors.primary}>
        {eventUser?.currentRank ?? '-'}
      </Typography>
    );

    pointsContent = <PlayerStats kills={eventUser?.kills ?? '-'} assists={eventUser?.assists ?? '-'} points={eventUser?.points ?? '-'} />;
  }

  let content: JSX.Element | null = (
    <>
      <RankContainer>
        <NemesisRankText variant="body3">Nemesis Rank</NemesisRankText>

        {rankContent}
      </RankContainer>

      <PlayerRankColumn>
        <RankAvatarWrapper>
          {eventUser && <AvatarImageWrapper>{<AvatarImage src={eventUser.userAvatar} alt="Avatar" />}</AvatarImageWrapper>}

          <Typography mt={0.8} variant="h5" color={colors.primary}>
            {eventUser?.username}
          </Typography>

          <PlayerRankTeam
            mt={0.4}
            variant="body6"
            color={colors.transparentLight50}
            onClick={() => handleGotoTeamPage(Number(eventUser?.teamId))}
          >
            {eventUser?.teamName}
          </PlayerRankTeam>
        </RankAvatarWrapper>
      </PlayerRankColumn>

      <StatsContainer>{pointsContent}</StatsContainer>
    </>
  );

  if (isMobile) {
    content = (
      <>
        <PlayerRankColumn>
          <RankAvatarWrapper>
            {eventUser && <AvatarImageWrapper>{<AvatarImage src={eventUser.userAvatar} alt="Avatar" />}</AvatarImageWrapper>}

            <Typography mt={0.8} variant="h5" color={colors.primary}>
              {eventUser?.username}
            </Typography>

            <PlayerRankTeam
              mt={0.4}
              variant="body6"
              color={colors.transparentLight50}
              onClick={() => handleGotoTeamPage(Number(eventUser?.teamId))}
            >
              {eventUser?.teamName}
            </PlayerRankTeam>
          </RankAvatarWrapper>
        </PlayerRankColumn>

        <RankContainerWrapperMobile>
          {isConnectFinished ? (
            <>
              <RankContainer>
                <NemesisRankText variant="body3">Nemesis Rank</NemesisRankText>

                {rankContent}
              </RankContainer>
              <StatsContainer>{pointsContent}</StatsContainer>
            </>
          ) : (
            <NoConnectionRankContainer>
              {pointsContent}
              {rankContent}
            </NoConnectionRankContainer>
          )}
        </RankContainerWrapperMobile>
      </>
    );
  }

  return <PlayerRankWrapper>{isLoading ? <Skeleton height={150} /> : content}</PlayerRankWrapper>;
};
