import { useQuery } from 'react-query';
import * as eventService from '../../services/eventService';
import QueryKeys from '../../constants/queryKeys';

interface GetEventParams {
  communityId: number;
}

export const useGetEventsByCommunityId = ({ communityId }: GetEventParams) => {
  return useQuery({
    queryKey: QueryKeys.Events,
    queryFn: () => eventService.fetchEventsByCommunityId(communityId),
  });
};
