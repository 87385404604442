import httpClient from '../../configs/axios';
import { Event, EventLeaderboard, EventLeaderboardResponse, EventParticipants, EventResponse, EventUser, EventUserResponse } from './types';

export const fetchEvents = (): Promise<Event[]> => {
  return httpClient.get('/events').then((response: EventResponse[]) => {
    return response.map((item) => ({
      id: item.id,
      title: item.title,
      startDate: item.start_date,
      endDate: item.end_date,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      deletedAt: item.deletedAt,
    }));
  });
};

export const fetchEventsByCommunityId = (communityId: number): Promise<Event[]> => {
  return httpClient.get(`/events/byCommunity/${communityId}`).then((response: EventResponse[]) => {
    return response.map((item) => ({
      id: item.id,
      title: item.title,
      startDate: item.start_date,
      endDate: item.end_date,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      deletedAt: item.deletedAt,
    }));
  });
};

export const fetchEventParticipants = (eventId: number): Promise<EventParticipants[]> => {
  return httpClient.get(`/events/${eventId}/participants`);
};

export const fetchEventLeaderboard = (eventId: number): Promise<EventLeaderboard[]> => {
  return httpClient.get(`/events/${eventId}/leaderboard`).then((response: EventLeaderboardResponse[]) => {
    return response.map((item) => ({
      teamId: item.team_id,
      kills: item.kills,
      assists: item.assists,
      points: item.points ?? 0,
      currentRank: item.current_rank ?? 0,
      previousRank: item.previous_rank ?? 0,
      teamAvatar: item.team_avatar,
      teamName: item.team_name,
      totalMembers: item.total_members,
      isMyTeam: item.isMyTeam,
    }));
  });
};

export const fetchEventUser = (eventId: number): Promise<EventUser> => {
  return httpClient.get(`/events/${eventId}/user`).then((response: EventUserResponse) => ({
    userId: response.user_id,
    username: response.username,
    userAvatar: response.user_avatar,
    teamName: response.team_name,
    teamId: response.team_id,
    kills: response.kills,
    assists: response.assists,
    points: response.points,
    currentRank: response.current_rank,
  }));
};
