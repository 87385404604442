import { Dialog } from '../../components/dialog';
import { AccountContent, AccountContentElements, ButtonsWrapper, ConnectButton, GameInfoWrapper, LogoImage } from './AccountConnectStyled';
import { Typography } from '../../components/typography';
import { colors } from '../../theme';
import { ReactComponent as CloseIcon } from '../../assets/icons/x-icon.svg';
import { Button } from '../../components/button';
import { ReactComponent as RiotIcon } from '../../assets/icons/riot-icon.svg';
import { AccountConnectProps } from './types';
import { CloseIconWrapper } from '../../pages/event/EventStyled';
import { MainLayoutState } from '../layout/mainLayout/MainLayoutState';

export const AccountConnect = ({ isOpen, onClose, eventId }: AccountConnectProps) => {
  const { handleOpenQuestModal, useRedirectToRiotLogin } = MainLayoutState.useContainer();
  const { mutate: redirectToRiot } = useRedirectToRiotLogin();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <AccountContent>
        <CloseIconWrapper>
          <CloseIcon cursor="pointer" fill={colors.transparentLight50} width={16} height={16} onClick={onClose} />
        </CloseIconWrapper>

        <AccountContentElements>
          <Typography textTransform="capitalize" textAlign="center" mt={0.4} mb={3.2} variant="h5" color={colors.white}>
            Connect your account
          </Typography>

          <GameInfoWrapper>
            <LogoImage />
            <Typography width={240} ml={2.4} variant="body4" color={colors.transparentLight50}>
              Enter your Summoner's name and start ranking up!
            </Typography>
          </GameInfoWrapper>

          <ButtonsWrapper>
            <ConnectButton
              variant="contained"
              size="medium"
              startIcon={<RiotIcon width={16} height={16} fill={colors.black} />}
              fullWidth
              onClick={() => redirectToRiot()}
            >
              Connect Your Account
            </ConnectButton>

            <Button variant="outlined" size="medium" fullWidth onClick={handleOpenQuestModal}>
              I'll do this later
            </Button>
          </ButtonsWrapper>
        </AccountContentElements>
      </AccountContent>
    </Dialog>
  );
};
