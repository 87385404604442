import styled from '@emotion/styled';
import { breakpoints, mediaQuery } from '../../theme';

export const TeamAssignedMessageContentWrapper = styled.div`
  padding: 2.4rem 2.4rem 4rem 2.4rem;
  max-width: 424px;

  ${mediaQuery(breakpoints.mobile)} {
    max-width: 336px;
  }
`;

export const TeamAssignedMessageLoaderWrapper = styled.div`
  width: 424px;
`;

export const TeamAssignedMessageContent = styled.div`
  padding: 0 2.4rem;

  ${mediaQuery(breakpoints.mobile)} {
    padding: 0 1.2rem;
  }
`;

export const YourTeamWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3.2rem;
`;

export const TeamImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 12px;
  margin-right: 1.6rem;
`;
