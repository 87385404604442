import { useQuery } from 'react-query';
import QueryKeys from '../../constants/queryKeys';
import * as eventService from '../../services/eventService';

export interface GetEventLeaderboard {
  eventId: number;
  enabled?: boolean;
}

export const useGetEventLeaderboard = ({ eventId, enabled = true }: GetEventLeaderboard) => {
  return useQuery({
    queryKey: [QueryKeys.EventLeaderboard, eventId],
    queryFn: () => eventService.fetchEventLeaderboard(eventId),
    enabled,
  });
};
