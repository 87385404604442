import React from 'react';
import { LinearProgressProps as MaterialLinearProgressProps } from '@mui/material';
import { LinearProgressWrapper, MaterialLinearProgress, ProgressLabel } from './LinearProgressStyled';
import { colors } from '../../theme';

interface LinearProgressProps extends MaterialLinearProgressProps {
  label?: React.ReactNode | string;
}

export const LinearProgress = ({ label, ...rest }: LinearProgressProps) => {
  return (
    <LinearProgressWrapper>
      <MaterialLinearProgress {...rest} />
      {label && (
        <ProgressLabel variant="subtitle3" color={colors.primary}>
          {label}
        </ProgressLabel>
      )}
    </LinearProgressWrapper>
  );
};
