import styled from '@emotion/styled';
import { colors } from '../../theme';

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4rem;
  position: relative;
  z-index: 1;
`;

export const FooterText = styled.span`
  color: ${colors.transparentLight50};
  font-size: 1rem;
  margin-right: 1rem;
  font-weight: 400;
`;

export const ContactUs = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
