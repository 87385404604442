import { useOutlet } from 'react-router-dom';
import { DarkOverlay, NemessisLogo } from '../LayoutStyled';
import { Container } from '../../../components/container';
import { Footer } from '../../../components/footer';
import { BaseLayoutWrapper, Content, LogoWrapper } from './BaseLayoutStyled';

export const BaseLayout = () => {
  const content = useOutlet();

  return (
    <BaseLayoutWrapper>
      <DarkOverlay />

      <Container>
        <LogoWrapper>
          <NemessisLogo />
        </LogoWrapper>
      </Container>

      <Content>
        <Container>{content}</Container>
      </Content>

      <Container>
        <Footer />
      </Container>
    </BaseLayoutWrapper>
  );
};
