import styled from '@emotion/styled';
import { colors } from '../../theme';
import { IconButtonProps } from './types';

export const IconButtonWrapper = styled.div<IconButtonProps>`
  background: ${colors.primary};
  color: ${colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  cursor: pointer;
`;
