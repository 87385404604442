import styled from '@emotion/styled';
import { breakpoints, colors, mediaQuery } from '../../theme';
import { ReactComponent as InfoIcon } from '../../assets/icons/info-icon.svg';
import { Typography } from '../../components/typography';

const BoardEmptyStateWrapper = styled.div`
  background: ${colors.transparentLight05};
  border-radius: 12px;
  padding: 2.4rem 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaQuery(breakpoints.mobile)} {
    padding: 2.4rem;
  }
`;

export const Label = styled(Typography)`
  ${mediaQuery(breakpoints.mobile)} {
    text-align: left;
  }
`;

interface BoardEmptyStateProps {
  label: string;
}

export const BoardEmptyState = ({ label }: BoardEmptyStateProps) => {
  return (
    <BoardEmptyStateWrapper>
      <InfoIcon width={30} height={30} fill={colors.transparentLight25} />

      <Label ml={1.6} variant="body1" color={colors.transparentLight25} textAlign="center">
        {label}
      </Label>
    </BoardEmptyStateWrapper>
  );
};
