import { useQuery } from 'react-query';
import QueryKeys from '../../constants/queryKeys';
import * as teamService from '../../services/teamService';

interface GetTeamPlayerParams {
  eventId: number;
  teamId: number;
  enabled?: boolean;
}

export const useGetTeamPlayers = ({ eventId, teamId, enabled = true }: GetTeamPlayerParams) => {
  return useQuery({
    queryKey: [QueryKeys.PlayerLeaderboard, eventId, teamId],
    queryFn: () => teamService.fetchTeamPlayers({ eventId, teamId }),
    enabled,
  });
};
