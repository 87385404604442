import { Snackbar } from '../../components/snackbar';
import { ToastContent, ToastIconWrapper, ToastMessageMobile, ToastMessageWrapper } from './ToastMessageStyled';
import { ToastMessageProps } from './types';
import { Typography } from '../../components/typography';
import { ReactComponent as CheckIcon } from '../../assets/icons/check-icon.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info-icon.svg';
import { ReactComponent as CircleInfoIcon } from '../../assets/icons/circle-info-icon.svg';
import { breakpoints, colors } from '../../theme';
import { useMediaQuery } from '../../hooks/useMediaQuery';

export const ToastMessage = ({ isOpen, anchorOrigin, autoHideDuration, onClose, title, subTitle, variant }: ToastMessageProps) => {
  const isMobile = useMediaQuery(breakpoints.mobile);

  let iconContent = <CheckIcon width={42} height={42} fill={colors.black} />;

  if (variant === 'secondary') {
    iconContent = <InfoIcon width={42} height={42} fill={colors.primary} />;
  }
  if (variant === 'error') {
    iconContent = <CircleInfoIcon width={42} height={42} fill={colors.accentRed} />;
  }

  return (
    <Snackbar open={isOpen} anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} onClose={onClose}>
      <ToastMessageWrapper variant={variant}>
        {isMobile ? (
          <ToastMessageMobile>
            <ToastIconWrapper>{iconContent}</ToastIconWrapper>
            <Typography ml={2.4} variant="h7" textTransform="capitalize">
              {title}
            </Typography>
          </ToastMessageMobile>
        ) : (
          <>
            <ToastIconWrapper>{iconContent}</ToastIconWrapper>
            <ToastContent>
              <Typography mb={0.4} variant="h7" textTransform="capitalize">
                {title}
              </Typography>
              <Typography variant="body4">{subTitle}</Typography>
            </ToastContent>
          </>
        )}
        {isMobile && <Typography variant="body4">{subTitle}</Typography>}
      </ToastMessageWrapper>
    </Snackbar>
  );
};
