import { Route, Routes as Switch } from 'react-router-dom';
import Routes from './constants/routes';
import { SignUp } from './pages/signup';
import { Login } from './pages/login';
import { AuthLayout } from './molecules/layout/authLayout';
import { PrivateRoute } from './molecules/privateRoute';
import { PublicRoute } from './molecules/publicRoute';
import { EventContainer } from './pages/event';
import { MainLayoutContainer } from './molecules/layout/mainLayout';
import { Landing } from './pages/landing';
import { TermsOfService } from './pages/termsOfService';
import { BaseLayout } from './molecules/layout/baseLayout';
import { LandingLayout } from './molecules/layout/landingLayout';
import { PrivacyPolicy } from './pages/privacyPolicy';
import { TeamContainer } from './pages/team';
import { useScrollToTop } from './hooks/useScrollToTop';
import { ResetPassword } from './pages/resetPassword';
import { UpdatePassword } from './pages/updatePassword';
import { Home } from './pages/home';
import { CommunityContainer } from './pages/community';

const App = () => {
  useScrollToTop();

  return (
    <Switch>
      <Route element={<LandingLayout />}>
        <Route path={Routes.ROOT} element={<Landing />} />
      </Route>
      <Route element={<PublicRoute />}>
        <Route element={<AuthLayout />}>
          <Route path={Routes.LOGIN} element={<Login />} />
          <Route path={Routes.CREATOR_SIGNUP} element={<SignUp />} />
          <Route path={Routes.INVITATION_SIGNUP} element={<SignUp />} />
          <Route path={Routes.RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={Routes.UPDATE_PASSWORD} element={<UpdatePassword />} />
        </Route>
      </Route>

      <Route element={<PrivateRoute />}>
        <Route element={<MainLayoutContainer />}>
          <Route path={Routes.EVENTS} element={<EventContainer />} />
          <Route path={Routes.HOME} element={<Home />} />
          <Route path={Routes.TEAM} element={<TeamContainer />} />
          <Route path={Routes.COMMUNITY} element={<CommunityContainer />} />
        </Route>
      </Route>

      <Route element={<BaseLayout />}>
        <Route path={Routes.TERMS_OF_SERVICE} element={<TermsOfService />} />
        <Route path={Routes.PRIVACY_POLICY} element={<PrivacyPolicy />} />
      </Route>
    </Switch>
  );
};

export default App;
