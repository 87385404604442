import styled from '@emotion/styled';
import backgroundImage from '../../../assets/images/bg_signin.png';
import { breakpoints, colors, mediaQuery } from '../../../theme';
import { Typography } from '../../../components/typography';

export const AuthLayoutWrapper = styled.div`
  background: url(${backgroundImage}) no-repeat center top;
  width: 100%;
  height: 100%;
  position: relative;
  background-size: cover;

  ${mediaQuery(breakpoints.mobile)} {
    background-size: unset;
  }
`;

export const LogoWrapper = styled.div`
  padding: 7.5rem 0;
  position: relative;
  z-index: 1;

  ${mediaQuery(breakpoints.mobile)} {
    display: flex;
    justify-content: center;
    padding: 6.4rem 0;
  }
`;

export const AuthContentArea = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  margin-bottom: 10rem;

  ${mediaQuery(breakpoints.mobile)} {
    flex-direction: column;
  }
`;

export const ContentOverlay = styled.div`
  position: absolute;
  background: ${colors.transparentDark50};
  backdrop-filter: blur(10px);
  width: 100%;
  height: 75%;
  top: 25%;

  ${mediaQuery(breakpoints.mobile)} {
    top: 15%;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  padding: 7.5rem 7.5rem 7.5rem 0;
  justify-content: space-between;
  width: 100%;

  ${mediaQuery(breakpoints.mobile)} {
    align-items: center;
    flex-direction: column;
    padding: 7.5rem 0 7.5rem 0;
  }
`;

export const TaglineWrapper = styled.div``;

export const Tagline = styled.div`
  margin-bottom: 3rem;

  ${mediaQuery(breakpoints.mobile)} {
    width: 280px;
  }
`;

export const TaglineDescription = styled.div`
  font-size: 1.8rem;
  width: 35rem;
  color: ${colors.transparentLight50};
  font-weight: 450;

  ${mediaQuery(breakpoints.mobile)} {
    width: 100%;
    margin-bottom: 5.6rem;
  }
`;

export const GamesSideBar = styled.div`
  background: ${colors.primary};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const GameLogoWrapper = styled.div`
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;

  &:not(:first-of-type) {
    opacity: 0.5;
  }

  ${mediaQuery(breakpoints.mobile)} {
    margin-bottom: 0;
  }
`;

export const ComingSoon = styled.div`
  color: ${colors.transparentDark50};
  font-size: 1.2rem;
  text-align: center;
  font-weight: 450;
`;

export const BannerTailWrapper = styled.div`
  ${mediaQuery(breakpoints.mobile)} {
    position: relative;
    left: -11px;
  }
`;

export const MainContentItem = styled.div`
  width: 100%;
`;

export const SideBarContentItem = styled.div`
  width: 160px;
`;

export const LoginLinkWrapper = styled(Typography)`
  margin-top: 1.6rem;
  color: ${colors.transparentLight50};
`;

export const GamesSidebarMobileWrapper = styled.div`
  background: ${colors.primary};
  padding: 2.2rem 1.6rem;
  width: 100%;
  flex-shrink: 0;
`;

export const GamesSidebarMobileContainer = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  left: -24px;
`;

export const GamesSidebarMobile = styled.div`
  display: flex;
  justify-content: space-between;
`;
