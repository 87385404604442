import styled from '@emotion/styled';
import { colors } from '../../theme';
import { ReactComponent as HexagonBg } from '../../assets/svg/hexagon-bg.svg';

export const PlayerCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const PlayerCardWrapper = styled.div<{ selected: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${({ selected }) => (selected ? colors.primary : 'none')};
  border-radius: 22px;
  padding: 1.6rem 3.2rem;
`;

export const IsYouTag = styled.div`
  background: ${colors.black};
  border: 2px solid ${colors.primary};
  padding: 0.2rem 0.8rem 0.1rem;
  border-radius: 4px;
  position: absolute;
  top: -10px;
  z-index: 2;
`;

export const AvatarWrapper = styled.div`
  position: relative;
`;

export const Avatar = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
`;

export const HexagonDarkBackground = styled(HexagonBg)`
  position: absolute;
  left: -2px;
  top: 4px;
`;

export const PlayerRank = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 0;
  background: ${colors.black};
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 2px solid ${colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
`;
