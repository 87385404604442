import { TeamAssignedMessageProps } from './types';
import { Dialog } from '../../components/dialog';
import {
  TeamAssignedMessageContent,
  TeamAssignedMessageContentWrapper,
  TeamAssignedMessageLoaderWrapper,
  TeamImage,
  YourTeamWrapper,
} from './TeamAssignedMessageStyled';
import { colors } from '../../theme';
import { IconWrapper } from '../profile/ProfileStyled';
import { ReactComponent as CloseIcon } from '../../assets/icons/x-icon.svg';
import { Typography } from '../../components/typography';
import { Button } from '../../components/button';
import { EventState } from '../../pages/event/EventState';
import { LoaderContainer } from '../loaderContainer';
import { CircularProgress } from '../../components/circularProgress';

export const TeamAssignedMessage = ({ isOpen, onClose }: TeamAssignedMessageProps) => {
  const { participants, isLoadingParticipants } = EventState.useContainer();
  const yourTeam = participants?.find((item) => item.isMyTeam);

  let content = (
    <>
      <IconWrapper>
        <CloseIcon cursor="pointer" fill={colors.transparentLight50} width={16} height={16} onClick={onClose} />
      </IconWrapper>

      <TeamAssignedMessageContent>
        <Typography textTransform="capitalize" textAlign="center" mt={0.4} mb={3.2} variant="h5" color={colors.white}>
          Your team
        </Typography>

        <Typography variant="body4" mb={3.2} color={colors.transparentLight50}>
          You have been randomly assigned to the following team.
        </Typography>

        <YourTeamWrapper>
          <TeamImage src={yourTeam?.teamAvatar} alt="team-image" />

          <div>
            <Typography variant="h6" color={colors.white}>
              {yourTeam?.teamName}
            </Typography>
            <Typography variant="body2" color={colors.transparentLight50}>
              {`${yourTeam?.teamUsersNum} players`}
            </Typography>
          </div>
        </YourTeamWrapper>

        <Button variant="contained" size="medium" fullWidth onClick={onClose}>
          Let's go
        </Button>
      </TeamAssignedMessageContent>
    </>
  );

  if (!Boolean(yourTeam) && isLoadingParticipants) {
    content = (
      <TeamAssignedMessageLoaderWrapper>
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      </TeamAssignedMessageLoaderWrapper>
    );
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <TeamAssignedMessageContentWrapper>{content}</TeamAssignedMessageContentWrapper>
    </Dialog>
  );
};
