import { CommunityCardWrapper, CommunityContent, CommunityImage, FeaturedImage } from './CommunityCardStyled';
import { Typography } from '../../components/typography';
import { colors } from '../../theme';
import { CommunityCardProps } from './types';

export const CommunityCard = ({ community, onClick }: CommunityCardProps) => {
  const defaultBanner = 'https://picsum.photos/300/300?random=1';
  const defaultAvatar = 'https://picsum.photos/300/300?random=2';

  return (
    <CommunityCardWrapper onClick={onClick}>
      <FeaturedImage src={community.banner_path || defaultBanner} alt="community-banner" />
      <CommunityImage
        src={`${process.env.REACT_APP_APP_RESOURCE_URL}/user-avatars/${community.avatar_path}` || defaultAvatar}
        alt="community-avatar"
      />

      <CommunityContent>
        <Typography mb={1} variant="body3" color={colors.white}>
          {community.name}
        </Typography>

        <Typography variant="body4" color={colors.transparentLight50}>
          {community.description}
        </Typography>
      </CommunityContent>
    </CommunityCardWrapper>
  );
};
