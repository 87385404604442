import React from 'react';
import { EventDetailsWrapper } from './EventDetailsStyled';
import { Container } from '../../components/container';

interface EventDetailsProps {
  children: React.ReactNode;
}

export const EventDetails = ({ children }: EventDetailsProps) => {
  return (
    <EventDetailsWrapper>
      <Container>{children}</Container>
    </EventDetailsWrapper>
  );
};
