import styled from '@emotion/styled';
import { Typography } from '../../components/typography';
import { colors } from '../../theme';

const TermsOfServiceWrapper = styled.div`
  color: ${colors.white};
  padding-top: 8rem;
`;

export const TermsOfService = () => {
  return (
    <TermsOfServiceWrapper>
      <Typography variant="h4" mb={6.4}>
        Terms of Service
      </Typography>
      <Typography variant="h6" mb={1.6}>
        Introduction
      </Typography>

      <Typography variant="body6" mb={1.6}>
        The Terms and Conditions (“Terms”) describe how Nemesis (“Company,” “we,” and “our”) regulates your use of this website
        https://nemesis.zone (the “Website”). Please read the following information carefully to understand our practices regarding your use
        of the website. The Company may change the Terms at any time. The Company may inform you of the changes to the Terms using the
        available means of communication. The Company recommends you to check the website frequently to see the actual version of the Terms
        and their previous versions.
      </Typography>

      <Typography variant="body6" mb={6.4}>
        If you represent a legal entity, you certify that you are entitled by such a legal entity to conclude the Terms as the legal entity
        you represent.
      </Typography>

      <Typography variant="h6" mb={2.4}>
        1. Privacy Policy
      </Typography>

      <Typography variant="body6" mb={6.4}>
        Our Privacy Policy is available on a separate page. Our Privacy Policy explains to you how we process information about you. You
        shall understand that through your use of the website you acknowledge the processing of this information shall be undertaken in
        accordance with the Privacy Policy.
      </Typography>

      <Typography variant="h6" mb={2.4}>
        2. Your Account
      </Typography>

      <Typography variant="body6" mb={0.8}>
        When using the website, you shall be responsible for ensuring the confidentiality of your account, password and other credentials
        and for secure access to your device. You shall not assign your account to anyone. The Company is not responsible for unauthorized
        access to your account that results from misappropriation or theft of your account. The Company may refuse or cancel service,
        terminate your account, and remove or edit content.
      </Typography>
      <Typography variant="body6" mb={6.4}>
        The Company does not knowingly collect personal data from persons under the age of 16 (sixteen). If you are under 16 (sixteen) years
        old, you may not use the website and may not enter into the Terms under any circumstances.
      </Typography>

      <Typography variant="h6" mb={2.4}>
        3. Services
      </Typography>

      <Typography variant="body6" mb={0.8}>
        The website allows you to use Services available on the website. You shall not use the services for the illegal aims.
      </Typography>

      <Typography variant="body6" mb={0.8}>
        We may, at our sole discretion, set fees for using the website for you. All prices are published separately on relevant pages on the
        website. We may, at our sole discretion, at any time change any fees.
      </Typography>

      <Typography variant="body6" mb={6.4}>
        We may use certified payment systems, which also may have their commissions. Such commissions may be implied on you when you choose
        a particular payment system. Detailed information about commissions of such payment systems may be found on their websites.
      </Typography>

      <Typography variant="h6" mb={2.4}>
        4. Third Party Services
      </Typography>

      <Typography variant="body6" mb={0.8}>
        The website may include links to other websites, applications, and platforms (hereinafter the "Linked Sites").
      </Typography>

      <Typography variant="body6" mb={6.4}>
        The Company does not control the Linked Sites, and shall not be responsible for the content and other materials of the Linked Sites.
        The Company makes these links available to you for providing the functionality or services on the website.
      </Typography>

      <Typography variant="h6" mb={2.4}>
        5. Prohibited Uses and Intellectual Property
      </Typography>

      <Typography variant="body6" mb={0.8}>
        The Company grants you a non-transferable, non-exclusive, revocable license to access and use the website from one device in
        accordance with the Terms. You shall not use the website for unlawful or prohibited purpose. You may not use the website in a way
        that may disable, damage, or interfere in the website.
      </Typography>

      <Typography variant="body6" mb={0.8}>
        All content present on the website includes text, code, graphics, logos, images, compilation, software used on the website
        (hereinafter and hereinbefore the "Content"). The Content is the property of the Company or its contractors and protected by
        intellectual property laws that protect such rights. You agree to use all copyright and other proprietary notices or restrictions
        contained in the Content and you are prohibited from changing the Content.
      </Typography>

      <Typography variant="body6" mb={6.4}>
        You may not publish, transmit, modify, reverse engineer, participate in the transfer, or create and sell derivative works, or in any
        way use any of the Content. Your enjoyment of the website shall not entitle you to make any illegal and disallowed use of the
        Content, and in particular you shall not change proprietary rights or notices in the Content. You shall use the Content only for
        your personal and non-commercial use. The Company does not grant you any licenses to the intellectual property of the Company.
      </Typography>
    </TermsOfServiceWrapper>
  );
};
