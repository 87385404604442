import { EventBoardsWrapper } from '../eventLeaderboard/EventLeaderboardStyled';
import { colors } from '../../../theme';
import { Typography } from '../../../components/typography';
import { Container } from '../../../components/container';
import { ParticipantsTable } from './ParticipantsTable';

export const EventParticipants = () => {
  return (
    <EventBoardsWrapper>
      <Container>
        <Typography variant="h6" mt={6.4} mb={2.4} color={colors.white}>
          Participants
        </Typography>
        <ParticipantsTable />
      </Container>
    </EventBoardsWrapper>
  );
};
