import styled from '@emotion/styled';
import { ToastMessageProps } from './types';
import { breakpoints, colors, mediaQuery } from '../../theme';

export const ToastMessageWrapper = styled.div<ToastMessageProps>`
  padding: 3.2rem;
  width: 1040px;
  border-radius: 22px;
  background: ${(props) => {
    if (props.variant === 'primary') return colors.primary;

    return colors.primary;
  }};
  display: flex;
  align-items: center;

  ${mediaQuery(breakpoints.mobile)} {
    padding: 2.4rem;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ToastIconWrapper = styled.div``;

export const ToastContent = styled.div`
  margin-left: 2.4rem;
`;

export const ToastMessageMobile = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
`;
