import styled from '@emotion/styled';
import defaultHeaderImage from '../../../assets/images/bg-main-layout.png';
import { ReactComponent as NemessisLogo } from '../../../assets/svg/nemesis_logo.svg';

import { IconButton } from '../../../components/iconButton';
import { breakpoints, colors, mediaQuery } from '../../../theme';
import { Badge } from '../../../components/badge';

export const MainLayoutWrapper = styled.div<{ headerImagePath: string }>`
  background: url(${({ headerImagePath }) => {
      if (headerImagePath) return headerImagePath;
      return defaultHeaderImage;
    }})
    no-repeat center top;
  width: 100%;
  height: 720px;
  position: relative;
  background-size: cover;
`;

export const ContentWrapper = styled.div`
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: white;
  padding: 1.6rem;

  ${mediaQuery(breakpoints.mobile)} {
    align-items: center;
  }
`;

export const HeaderColumn = styled.div`
  width: 33.33%;
`;

export const LogoWrapper = styled(HeaderColumn)`
  display: flex;
  justify-content: center;
`;

export const Logo = styled(NemessisLogo)`
  cursor: pointer;
  width: 172px;
  height: 48px;
`;

export const HeaderButtons = styled(HeaderColumn)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ProfileButton = styled(IconButton)`
  margin-left: 1.6rem;
  min-width: 34px;
  min-height: 34px;
`;

export const NoConnectionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${mediaQuery(breakpoints.mobile)} {
    justify-content: center;
    flex-direction: row-reverse;
  }
`;

export const UserPoints = styled.div`
  background: ${colors.transparentDark50};
  backdrop-filter: blur(10px);
  border-radius: 43px;
  padding: 1.2rem 1.6rem;
  display: flex;
  justify-content: space-between;

  ${mediaQuery(breakpoints.mobile)} {
    justify-content: space-around;
  }
`;

export const EmptyUserPoints = styled(UserPoints)`
  width: 231px;
  height: 41px;
`;

export const NoConnectionUserPoints = styled(UserPoints)`
  margin-left: 1.6rem;
  padding: 1.2rem 2.6rem;

  ${mediaQuery(breakpoints.mobile)} {
    margin-left: 0;
    margin-right: 1.6rem;
  }
`;

export const PointWrapper = styled.div`
  margin-right: 34px;
  display: flex;
  color: ${colors.primary};

  &:last-of-type {
    margin-right: 0;
  }
`;

export const PointsIconBadge = styled(Badge)`
  & .MuiBadge-badge {
    top: 4px;
  }
`;
