import { useQuery } from 'react-query';
import * as teamService from '../../services/teamService';
import QueryKeys from '../../constants/queryKeys';

interface GetEventTeamDetailsParams {
  eventId: number;
  teamId: number;
  enabled?: boolean;
}

export const useGetEventTeamDetails = ({ eventId, teamId, enabled = true }: GetEventTeamDetailsParams) => {
  return useQuery({
    queryKey: [QueryKeys.Events, eventId, QueryKeys.Teams, teamId],
    queryFn: () => teamService.fetchEventTeamDetails({ teamId, eventId }),
    enabled,
  });
};
