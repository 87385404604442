import { useQuery } from 'react-query';
import * as communityService from '../../services/communityService';
import QueryKeys from '../../constants/queryKeys';
import { Community } from '../../services/communityService/types';

interface GetCommunityByCodeParams {
  invitationCode: string;
  enabled?: boolean;
  onError?: (error: any) => void;
}

export const useGetCommunityByCode = ({ invitationCode, enabled = true, onError }: GetCommunityByCodeParams) => {
  return useQuery<Community, any>({
    queryKey: [QueryKeys.Community, invitationCode],
    queryFn: () => communityService.getCommunityByInvitationCode(invitationCode),
    enabled: enabled,
    onError,
  });
};
