import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { colors } from '../../theme';

export const RouterLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  font-size: 1.2rem;
  font-weight: 450;
`;
