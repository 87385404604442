import { useQuery } from 'react-query';
import * as eventService from '../../services/eventService';
import QueryKeys from '../../constants/queryKeys';

export interface GetEventParticipantsParams {
  eventId: number;
  enabled?: boolean;
}

export const useGetEventParticipants = ({ eventId, enabled = true }: GetEventParticipantsParams) => {
  return useQuery({
    queryKey: [QueryKeys.EventParticipants, eventId],
    queryFn: () => eventService.fetchEventParticipants(eventId),
    enabled,
  });
};
