import { Typography } from '../../components/typography';
import { colors } from '../../theme';
import { Divider } from '../../components/divider';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down-icon.svg';
import styled from '@emotion/styled';

const DividerContent = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

interface ViewDividerProps {
  label: string;
  onClick?: () => void;
}

export const ViewDivider = ({ label, onClick }: ViewDividerProps) => {
  return (
    <Divider>
      <DividerContent onClick={onClick}>
        <Typography mr={0.8} variant="subtitle3" textTransform="uppercase" color={colors.transparentLight25}>
          {label}
        </Typography>

        <ArrowDownIcon width={10} height={10} fill={colors.transparentLight25} />
      </DividerContent>
    </Divider>
  );
};
