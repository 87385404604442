import styled from '@emotion/styled';
import { Button } from '../../components/button';
import { breakpoints, mediaQuery } from '../../theme';

export const ResetPasswordWrapper = styled.form`
  width: 250px;

  ${mediaQuery(breakpoints.mobile)} {
    width: 100%;
  }
`;

export const ResetButton = styled(Button)`
  margin-top: 2.4rem;
`;
