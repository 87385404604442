import { LeaderboardWrapper } from '../../../molecules/leaderboard';
import { Container } from '../../../components/container';
import { Typography } from '../../../components/typography';
import { colors } from '../../../theme';
import { TeamLeaderboardTable } from './TeamLeaderboardTable';

export const TeamLeaderboard = () => {
  return (
    <LeaderboardWrapper>
      <Container>
        <Typography variant="h6" mb={2} color={colors.white}>
          Leaderboard
        </Typography>
      </Container>

      <TeamLeaderboardTable />
    </LeaderboardWrapper>
  );
};
