import { useFormik } from 'formik';
import { signupSchema } from '../../validation/authSchemas';
import { useGetCommunityByCode } from '../../queries/community/useGetCommunityByCode';
import { useParams } from 'react-router-dom';
import { useCreateCommunityUser } from '../../queries/user/useCreateCommunityUser';
import { useCreateCommunityCreatorUser } from '../../queries/user/useCreateCommunityCreatorUser';
import { SignUpFromValues } from './types';
import { useErrorMessage } from '../../hooks/useErrorMessage';
import { GameConnect } from '../../context/gameConnect';

export const useSignUpForm = () => {
  const { message: errorMessage, handleExtractMessage } = useErrorMessage();
  const { invitationCode, creatorCode } = useParams();
  const { mutate: createCommunityUser } = useCreateCommunityUser();
  const { mutate: createCommunityCreatorUser } = useCreateCommunityCreatorUser();
  const { data: community } = useGetCommunityByCode({
    invitationCode: invitationCode ?? '',
    enabled: Boolean(invitationCode),
    onError: handleExtractMessage,
  });

  const { setIsYourTeamMessageOpen } = GameConnect.useContainer();

  const signupForm = useFormik<SignUpFromValues>({
    enableReinitialize: true,

    initialValues: { communityName: community?.name ?? '', username: '', email: '', password: '' },

    validationSchema: signupSchema,

    onSubmit: (values) => {
      if (invitationCode) {
        createCommunityUser(
          {
            username: values.username,
            email: values.email,
            password: values.password,
            invitationCode,
          },
          {
            onSuccess: () => {
              setIsYourTeamMessageOpen(true);
            },
          }
        );
      }

      if (creatorCode) {
        createCommunityCreatorUser(
          {
            username: values.username,
            email: values.email,
            password: values.password,
            communityName: values.communityName,
            creatorCode: creatorCode,
          },
          {
            onSuccess: () => {
              setIsYourTeamMessageOpen(true);
            },
            onError: handleExtractMessage,
          }
        );
      }
    },
  });

  return {
    invitationCode,
    community,
    errorMessage,
    ...signupForm,
  };
};
