import { CommunitiesWrapper, HomeWrapper } from './HomeStyled';
import { Container } from '../../components/container';
import { CircularProgress } from '../../components/circularProgress';
import { LoaderContainer } from '../../molecules/loaderContainer';
import { Typography } from '../../components/typography';
import { colors } from '../../theme';
import { CommunityCard } from '../../molecules/communityCard';
import { useGetCommunities } from '../../queries/community/useGetCommunities';
import { generatePath, useNavigate } from 'react-router-dom';
import Routes from '../../constants/routes';

export const Home = () => {
  const navigate = useNavigate();
  const { data: communities, isLoading: isLoadingCommunity } = useGetCommunities({});

  const handleClickCommunity = (communityId: number) => {
    navigate(generatePath(Routes.COMMUNITY, { communityId: communityId.toString() }));
  };

  if (isLoadingCommunity)
    return (
      <Container>
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      </Container>
    );

  return (
    <HomeWrapper>
      <Container>
        <Typography mb={2} variant="h6" color={colors.white}>
          Featured communities
        </Typography>

        <CommunitiesWrapper>
          {communities?.map((community) => (
            <CommunityCard community={community} key={community.id} onClick={() => handleClickCommunity(community.id)} />
          ))}
        </CommunitiesWrapper>
      </Container>
    </HomeWrapper>
  );
};
