import { useOutlet } from 'react-router-dom';
import {
  AuthContentArea,
  AuthLayoutWrapper,
  BannerTailWrapper,
  ComingSoon,
  ContentOverlay,
  ContentWrapper,
  GameLogoWrapper,
  GamesSideBar,
  GamesSidebarMobile,
  GamesSidebarMobileContainer,
  GamesSidebarMobileWrapper,
  LogoWrapper,
  MainContentItem,
  SideBarContentItem,
  Tagline,
  TaglineDescription,
  TaglineWrapper,
} from './AuthLayoutStyled';
import { ReactComponent as PlayLogo } from '../../../assets/svg/text_play.svg';
import { ReactComponent as RankLogo } from '../../../assets/svg/text_rank.svg';
import { ReactComponent as WinLogo } from '../../../assets/svg/text_win.svg';
import { ReactComponent as BannerTail } from '../../../assets/svg/banner_tail.svg';
import { ReactComponent as BottomBannerTail } from '../../../assets/svg/bottom_tail.svg';
import { ReactComponent as GameLogoLol } from '../../../assets/svg/game_logo_lol.svg';
import { ReactComponent as GameLogoWarzone } from '../../../assets/svg/game_logo_warzone.svg';
import { ReactComponent as GameLogoApex } from '../../../assets/svg/game_logo_apex.svg';
import { ReactComponent as GameLogoValvorant } from '../../../assets/svg/game_logo_valorant.svg';
import { ReactComponent as GameLogoRocketLeague } from '../../../assets/svg/game_logo_rocket_league.svg';
import { Footer } from '../../../components/footer';
import { Container } from '../../../components/container';
import { DarkOverlay, NemessisLogo } from '../LayoutStyled';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { breakpoints, colors } from '../../../theme';
import { Typography } from '../../../components/typography';

import { Link } from '../../../components/link';
import Routes from '../../../constants/routes';

export const AuthLayout = () => {
  const content = useOutlet();
  const isMobile = useMediaQuery(breakpoints.mobile);

  return (
    <AuthLayoutWrapper>
      <DarkOverlay />
      <ContentOverlay />

      <Container>
        <AuthContentArea>
          <MainContentItem>
            <Link to={Routes.ROOT}>
              <LogoWrapper>
                <NemessisLogo />
              </LogoWrapper>
            </Link>

            <ContentWrapper>
              <TaglineWrapper>
                <Tagline>
                  <PlayLogo />
                </Tagline>

                <Tagline>
                  <RankLogo />
                </Tagline>

                <Tagline>
                  <WinLogo />
                </Tagline>

                <TaglineDescription>
                  Play League of Legends, track your stats and rank up in the leaderboard to win spectacular cash prizes.
                </TaglineDescription>
              </TaglineWrapper>

              {content}
            </ContentWrapper>
          </MainContentItem>

          {isMobile ? (
            <GamesSidebarMobileContainer>
              <GamesSidebarMobileWrapper>
                <GamesSidebarMobile>
                  <GameLogoWrapper>
                    <GameLogoLol width={30} height={30} />
                  </GameLogoWrapper>
                  <GameLogoWrapper>
                    <GameLogoWarzone width={30} height={30} />
                  </GameLogoWrapper>
                  <GameLogoWrapper>
                    <GameLogoApex width={30} height={30} />
                  </GameLogoWrapper>
                  <GameLogoWrapper>
                    <GameLogoValvorant width={30} height={30} />
                  </GameLogoWrapper>
                  <GameLogoWrapper>
                    <GameLogoRocketLeague width={30} height={30} />
                  </GameLogoWrapper>
                </GamesSidebarMobile>

                <Typography mt={1.6} variant="subtitle2" color={colors.transparentDark50} textAlign="center">
                  More game coming soon
                </Typography>
              </GamesSidebarMobileWrapper>
              <BannerTailWrapper>
                <BottomBannerTail height={104} />
              </BannerTailWrapper>
            </GamesSidebarMobileContainer>
          ) : (
            <SideBarContentItem>
              <GamesSideBar>
                <GameLogoWrapper>
                  <GameLogoLol />
                </GameLogoWrapper>
                <GameLogoWrapper>
                  <GameLogoWarzone />
                </GameLogoWrapper>
                <GameLogoWrapper>
                  <GameLogoApex />
                </GameLogoWrapper>
                <GameLogoWrapper>
                  <GameLogoValvorant />
                </GameLogoWrapper>
                <GameLogoWrapper>
                  <GameLogoRocketLeague />
                </GameLogoWrapper>

                <ComingSoon>
                  <div>More games </div>
                  <div>coming soon</div>
                </ComingSoon>
              </GamesSideBar>
              <BannerTailWrapper>
                <BannerTail />
              </BannerTailWrapper>
            </SideBarContentItem>
          )}
        </AuthContentArea>

        <Footer />
      </Container>
    </AuthLayoutWrapper>
  );
};
