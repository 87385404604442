import styled from '@emotion/styled';
import { Skeleton } from '../../../components/skeleton';
import { breakpoints, colors, mediaQuery } from '../../../theme';

export const TeamInfoWrapper = styled.div`
  margin-top: 20rem;
  margin-bottom: 4.8rem;
`;

export const TeamInfoContentWrapper = styled.div`
  display: flex;
  align-items: center;

  ${mediaQuery(breakpoints.mobile)} {
    width: 100%;
  }
`;

export const TeamImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 12px;

  ${mediaQuery(breakpoints.mobile)} {
    width: 64px;
    height: 64px;
  }
`;

export const TeamImageSkeleton = styled(Skeleton)`
  width: 120px;
  height: 120px;
  border-radius: 12px;
  background: ${colors.transparentDark50};
`;

export const TeamInfoContent = styled.div`
  margin-left: 1.6rem;
`;

export const TeamName = styled.div`
  margin-bottom: 1.6rem;

  ${mediaQuery(breakpoints.mobile)} {
    margin-bottom: 0;
  }
`;

export const TeamSkeleton = styled(Skeleton)`
  background: ${colors.transparentDark50};
  border-radius: 4px;
`;

export const TeamStats = styled.div`
  display: flex;

  ${mediaQuery(breakpoints.mobile)} {
    margin-top: 1.6rem;
    justify-content: space-between;
  }
`;

export const TeamStatItem = styled.div`
  margin-right: 6.4rem;

  ${mediaQuery(breakpoints.mobile)} {
    margin-right: 3.2rem;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;
