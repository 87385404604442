import { StepperControlsWrapper } from './StepperControlsStyled';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left-icon.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right-icon.svg';
import { Typography } from '../../components/typography';
import { StepperControlsProps } from './types';

export const StepperControls = ({ label, onPrevious, onNext }: StepperControlsProps) => {
  return (
    <StepperControlsWrapper>
      <ArrowLeft width={14} height={14} cursor="pointer" onClick={onPrevious} />
      <Typography mx={1.3} variant="body5" textTransform="uppercase">
        {label}
      </Typography>
      <ArrowRight width={14} height={14} cursor="pointer" onClick={onNext} />
    </StepperControlsWrapper>
  );
};
