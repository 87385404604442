import styled from '@emotion/styled';
import { breakpoints, colors, mediaQuery } from '../../../theme';
import { Chip } from '../../../components/chip';
import { ReactComponent as Logo } from '../../../assets/svg/game_logo_lol.svg';
import { Typography } from '../../../components/typography';

export const CommunityEventDetailsWrapper = styled.div``;

export const StepperControlsWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  top: 16px;
`;

export const CommunityEventDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${mediaQuery(breakpoints.mobile)} {
    margin-bottom: 1.6rem;
  }
`;

export const EventLogo = styled.div`
  background: ${colors.black};
  width: 9.6rem;
  height: 9.6rem;
  display: flex;
  border-radius: 19px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  ${mediaQuery(breakpoints.mobile)} {
    border-radius: 12px;
    width: 64px;
    height: 64px;
  }
`;

export const LolLogo = styled(Logo)`
  ${mediaQuery(breakpoints.mobile)} {
    width: 38px;
    height: 38px;
  }
`;

export const CommunityEventDetailsContent = styled.div`
  margin-left: 2.4rem;
  width: 100%;

  ${mediaQuery(breakpoints.mobile)} {
    margin-left: 1.2rem;
  }
`;

export const CommunityEventDetailTitle = styled.div`
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;

  ${mediaQuery(breakpoints.mobile)} {
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
  }
`;

export const CommunityEventTitle = styled(Typography)`
  ${mediaQuery(breakpoints.mobile)} {
    width: 100%;
  }
`;

export const CommunityEventDate = styled(Typography)`
  ${mediaQuery(breakpoints.mobile)} {
    margin-left: 0;
    margin-top: 0.5rem;
  }
`;

export const CommunityEventDetailsProgress = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.4rem;

  ${mediaQuery(breakpoints.mobile)} {
    margin-bottom: 0;
  }
`;

export const StyledChip = styled(Chip)`
  margin-right: 0.8rem;
`;

export const CommunityEventDetailsStepper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  top: 1.6rem;
`;
