import { useQuery } from 'react-query';
import QueryKeys from '../../constants/queryKeys';
import * as userService from '../../services/userService';

interface GetUserPointsParams {
  enabled?: boolean;
}

export const useGetUserPoints = ({ enabled = true }: GetUserPointsParams) => {
  return useQuery({
    queryKey: QueryKeys.UserPoints,
    queryFn: () => userService.fetchUserPoints(),
    enabled,
  });
};
