import { TeamInfo } from './teamInfo';
import { TeamState } from './TeamState';
import { TeamEventDetails } from './teamEventDetails';
import { TeamLeaderboard } from './teamLeaderboard';
import { TopDailyPerformers } from './topDailyPerformers';
import { LoaderContainer } from '../../molecules/loaderContainer';
import { CircularProgress } from '../../components/circularProgress';
import { Container } from '../../components/container';
import { AccountConnect } from '../../molecules/accountConnect';
import { MainLayoutState } from '../../molecules/layout/mainLayout/MainLayoutState';
import { ToastMessage } from '../../molecules/toastMessage';
import { GameConnect } from '../../context/gameConnect';

const Team = () => {
  const { isConnectedToastOpen, setIsConnectedToastOpen } = GameConnect.useContainer();
  const { isConnectModalOpen, handleConnectModalClose } = MainLayoutState.useContainer();
  const { isLoadingTeamInfo, isLoadingPlayers, events } = TeamState.useContainer();
  const isLoading = isLoadingTeamInfo || isLoadingPlayers;

  const event = events?.[0];

  return (
    <>
      <TeamInfo />
      <TeamEventDetails />

      {isLoading ? (
        <Container>
          <LoaderContainer>
            <CircularProgress />
          </LoaderContainer>
        </Container>
      ) : (
        <>
          <TopDailyPerformers />
          <TeamLeaderboard />
        </>
      )}

      <AccountConnect isOpen={isConnectModalOpen} onClose={() => handleConnectModalClose()} eventId={event?.id} />
      <ToastMessage
        isOpen={isConnectedToastOpen}
        onClose={() => setIsConnectedToastOpen(false)}
        title="Game connection successful"
        subTitle="Your League of Legends game connection has been successful. Start playing to rank up!"
        variant="primary"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
      />
    </>
  );
};

export const TeamContainer = () => {
  return (
    <TeamState.Provider>
      <Team />
    </TeamState.Provider>
  );
};
