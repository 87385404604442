import { EventDetails } from '../../../molecules/eventDetails';
import {
  EventLogo,
  LolLogo,
  StepperControlsWrapper,
  StyledChip,
  StyledDivider,
  TeamEventDate,
  TeamEventDetailsContainer,
  TeamEventDetailsContent,
  TeamEventDetailsProgress,
  TeamEventDetailsStats,
  TeamEventDetailsStepper,
  TeamEventDetailStatItem,
  TeamEventDetailsWrapper,
  TeamEventDetailTitle,
  TeamEventTitle,
} from './TeamEventDetailsStyled';
import { breakpoints, colors } from '../../../theme';
import { Typography } from '../../../components/typography';
import { MobileStepper } from '../../../components/mobileStepper';
import { StepperControls } from '../../../molecules/stepperControls';
import { useStep } from '../../../hooks/useStep';
import { TeamState } from '../TeamState';
import { Skeleton } from '../../../components/skeleton';
import { eventDateFormatter, shortEventDateFormatter } from '../../../utils/date-formatters';
import { useMemo } from 'react';
import { getEventProgressLabel, getEventProgressValue } from '../../event/helpers';
import { useNavigate } from 'react-router-dom';
import Routes from '../../../constants/routes';
import { EventLinearProgress } from '../../../molecules/linearProgress';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

export const TeamEventDetails = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const navigate = useNavigate();
  const { teamInfo, events, isLoadingTeamInfo, isLoadingEvents } = TeamState.useContainer();
  const { current, maxSteps, previous, next } = useStep({ start: 0, maxSteps: events?.length ?? 1 });

  const teamStats = teamInfo?.teamStats;
  const currentEvent = events?.[current];

  const progressTotalValue = useMemo(
    () => getEventProgressValue(currentEvent?.startDate ?? '', currentEvent?.endDate ?? ''),
    [currentEvent?.startDate, currentEvent?.endDate]
  );
  const progressLabel = useMemo(
    () => getEventProgressLabel(currentEvent?.startDate ?? '', currentEvent?.endDate ?? ''),
    [currentEvent?.startDate, currentEvent?.endDate]
  );

  const isLoading = isLoadingEvents || isLoadingTeamInfo;

  const handleGoBack = () => {
    navigate(Routes.EVENTS);
  };

  const teamStatsContent = (
    <TeamEventDetailsStats>
      {isLoading ? (
        <Skeleton height={30} width="80%" />
      ) : (
        <>
          <TeamEventDetailStatItem>
            <Typography variant="subtitle3" color={colors.black} textTransform="uppercase">
              Team rank
            </Typography>
            <Typography variant="h7" color={colors.black}>
              {teamStats?.currentRank ?? '-'}
            </Typography>
          </TeamEventDetailStatItem>
          <TeamEventDetailStatItem>
            <Typography variant="subtitle3" color={colors.black} textTransform="uppercase">
              Kills
            </Typography>
            <Typography variant="h7" color={colors.black}>
              {teamStats?.kills ?? '-'}
            </Typography>
          </TeamEventDetailStatItem>
          <TeamEventDetailStatItem>
            <Typography variant="subtitle3" color={colors.black} textTransform="uppercase">
              Assists
            </Typography>
            <Typography variant="h7" color={colors.black}>
              {teamStats?.assists ?? '-'}
            </Typography>
          </TeamEventDetailStatItem>
          <TeamEventDetailStatItem>
            <Typography variant="subtitle3" color={colors.black} textTransform="uppercase">
              Points
            </Typography>
            <Typography variant="h7" color={colors.black}>
              {teamStats?.points ?? '-'}
            </Typography>
          </TeamEventDetailStatItem>
        </>
      )}
    </TeamEventDetailsStats>
  );

  return (
    <TeamEventDetailsWrapper>
      <StepperControlsWrapper>
        <StepperControls label="Current events" onPrevious={previous} onNext={next} />
      </StepperControlsWrapper>

      <EventDetails>
        <TeamEventDetailsContainer onClick={handleGoBack}>
          <EventLogo>{isLoading ? <Skeleton /> : <LolLogo fill={colors.primary} width={51} height={51} />}</EventLogo>

          <TeamEventDetailsContent>
            <TeamEventDetailTitle>
              {isLoading ? (
                <Skeleton width="50%" />
              ) : (
                <>
                  <TeamEventTitle variant="body1" color={colors.black} textTransform="capitalize">
                    {currentEvent?.title}
                  </TeamEventTitle>

                  <TeamEventDate ml={0.4} variant="body6" color={colors.black}>
                    {isMobile
                      ? `${shortEventDateFormatter(currentEvent?.startDate)} • ${shortEventDateFormatter(currentEvent?.endDate)}`
                      : `• ${eventDateFormatter(currentEvent?.startDate)} • ${eventDateFormatter(currentEvent?.endDate)}`}
                  </TeamEventDate>
                </>
              )}
            </TeamEventDetailTitle>

            <TeamEventDetailsProgress>
              {isLoading ? (
                <Skeleton width="70%" />
              ) : (
                <>
                  <StyledChip label="Members only" variant="filled" size="small" color="secondary" />
                  <StyledChip label="Bi-Weekly" variant="filled" size="small" color="secondary" disabled />

                  <EventLinearProgress variant="determinate" label={progressLabel} value={progressTotalValue} />
                </>
              )}
            </TeamEventDetailsProgress>

            {!isMobile && teamStatsContent}
          </TeamEventDetailsContent>
        </TeamEventDetailsContainer>

        {isMobile && (
          <>
            <StyledDivider />
            {teamStatsContent}
          </>
        )}

        <TeamEventDetailsStepper>
          <MobileStepper variant="dots" activeStep={current} position="static" steps={maxSteps} nextButton={null} backButton={null} />
        </TeamEventDetailsStepper>
      </EventDetails>
    </TeamEventDetailsWrapper>
  );
};
