import React from 'react';
import { TableCell as MaterialTableCell, TableCellProps as MaterialTableCellProps } from '@mui/material';

interface TableCellProps extends MaterialTableCellProps {
  children: React.ReactNode;
}

export const TableCell = ({ children, ...rest }: TableCellProps) => {
  return <MaterialTableCell {...rest}>{children}</MaterialTableCell>;
};
