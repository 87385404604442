export const rules = [
  { text: 'Join your Community via the unique link' },
  { text: 'Create an account on Nemesis.zone' },
  { text: 'Connect your League of Legends account via the Riot Login Button' },
  {
    text: 'Play any of the following League of Legends game modes (must be matchmade) to help your team win rewards:',
    subText: [{ text: 'Summoners Rift' }, { text: 'ARAM' }, { text: 'URF' }],
  },
  { text: 'Check the leaderboards as your team climbs the rankings, earning points for playing League of Legends' },
  { text: 'Top teams will earn special prizes' },
];
