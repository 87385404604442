import { createContainer } from 'unstated-next';
import { useToggle } from '../../../hooks/useToggle';
import { GameConnect } from '../../../context/gameConnect';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserPoints } from '../../../queries/user/useGetUserPoints';
import { useGetEventTeamDetails } from '../../../queries/team/useGetEventTeamDetails';
import { useGetQuests } from '../../../queries/quest/useGetQuests';
import { useRedirectToRiotLogin } from '../../../queries/connect/useRedirectToRiotLogin';

const useMainLayoutState = () => {
  const { eventId, teamId } = useParams();
  const { isConnected, isYourTeamMessageOpen } = GameConnect.useContainer();
  const { isOpen: isConnectModalOpen, handleOpen: handleConnectModalOpen, handleClose: handleConnectModalClose } = useToggle(!isConnected);
  const { isOpen: isQuestModalOpen, handleOpen: handleOpenQuestModal, handleClose: handleCloseQuestModal } = useToggle();

  const { data: userPoints, isLoading: isLoadingUserPoints, refetch: refetchUserPoints } = useGetUserPoints({});
  const { data: quests, isLoading: isLoadingQuests, refetch: refetchQuests } = useGetQuests({});
  const { data: teamInfo, isLoading: isLoadingTeamInfo } = useGetEventTeamDetails({
    eventId: Number(eventId),
    teamId: Number(teamId),
    enabled: !!eventId && !!teamId,
  });

  useEffect(() => {
    if (!isConnected && !isYourTeamMessageOpen) handleConnectModalOpen();
  }, [isConnected, isYourTeamMessageOpen, handleConnectModalOpen]);

  return {
    isConnectModalOpen: isConnectModalOpen,
    isQuestModalOpen,
    handleOpenQuestModal,
    handleCloseQuestModal,
    handleConnectModalOpen: handleConnectModalOpen,
    handleConnectModalClose: handleConnectModalClose,
    userPoints,
    quests,
    teamInfo,
    isLoadingUserPoints,
    isLoadingQuests,
    isLoadingTeamInfo,
    useRedirectToRiotLogin,
    refetchUserPoints,
    refetchQuests,
  };
};

export const MainLayoutState = createContainer(useMainLayoutState);
