import styled from '@emotion/styled';
import { colors } from '../../theme';
import { LeaderboardMobileActiveState } from './types';
import { IsYouChip } from '../leaderboard';

export const LeaderboardMobileWrapper = styled.div<LeaderboardMobileActiveState>`
  position: relative;
  background: ${({ primaryActive, secondaryActive }) => {
    if (primaryActive) {
      return colors.primary;
    }

    if (secondaryActive) {
      return colors.transparentLight05;
    }

    return 'none';
  }};
  border-radius: 12px;
  cursor: pointer;
`;

export const LeaderboardMobileContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1.4rem 0;
`;

export const LeaderboardMobileInfo = styled.div<LeaderboardMobileActiveState>`
  width: 100%;
  padding-bottom: ${({ primaryActive, secondaryActive }) => {
    if (primaryActive || secondaryActive) return 0;
    return '1rem';
  }};
  border-bottom: ${({ primaryActive, secondaryActive }) => {
    if (primaryActive || secondaryActive) return 'none';
    return `1px solid ${colors.transparentLight05}`;
  }};
`;

export const LeaderboardMobileDetails = styled.div`
  display: flex;
  margin-bottom: 1.3rem;
`;

export const LeaderboardMobileTitle = styled.div`
  margin-left: 1.6rem;
`;

export const LeaderboardMobileStats = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeaderboardRankWrapper = styled.div`
  width: 24px;
`;

export const LeaderboardMobileIsYouChip = styled(IsYouChip)`
  top: -7px;
  left: 24px;
`;
