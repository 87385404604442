import { useMemo, useState } from 'react';

interface StepParams {
  start: number;
  maxSteps: number;
}

export const useStep = ({ start, maxSteps }: StepParams) => {
  const [step, setStep] = useState<number>(start);

  const previous = () => setStep((currentStep) => currentStep - 1);
  const next = () => setStep((currentStep) => currentStep + 1);

  const current = useMemo(() => {
    return step < 0 ? maxSteps * Math.ceil(Math.abs(step) / maxSteps) + step : step % maxSteps;
  }, [step, maxSteps]);

  return {
    current,
    maxSteps,
    previous,
    next,
  };
};
