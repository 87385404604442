import { CommunityInformation } from './communityInformation/CommunityInformation';

import { CommunityState } from './CommunityState';
import { EventLeaderboard } from '../event/eventLeaderboard';
import { CommunityEventsControl } from './communityEventsControl/CommunityEventsControl';

const Community = () => {
  const { community, isLoadingCommunity } = CommunityState.useContainer();

  if (!community || isLoadingCommunity) return <></>;

  return (
    <>
      <CommunityInformation />
      <CommunityEventsControl />

      <EventLeaderboard />
    </>
  );
};

export const CommunityContainer = () => {
  return (
    <CommunityState.Provider>
      <Community />
    </CommunityState.Provider>
  );
};
