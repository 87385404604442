import styled from '@emotion/styled';
import { Typography } from '../typography';
import { LinearProgress } from '@mui/material';
import { colors } from '../../theme';

export const MaterialLinearProgress = styled(LinearProgress)`
  & .MuiLinearProgress-bar {
    background: ${({ value }) => (value === 100 ? colors.accentRed : '')};
  }
`;

export const LinearProgressWrapper = styled.div`
  width: 100%;
  position: relative;
  border-radius: 4px;
`;

export const ProgressLabel = styled(Typography)`
  position: absolute;
  top: 2px;
  left: 8px;
`;
