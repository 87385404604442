import styled from '@emotion/styled';
import { Button } from '../../../components/button';

export const CommunityWrapper = styled.div``;

export const CommunityDetailsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 20rem;
`;
export const CommunityInfoWrapper = styled.div`
  display: flex;
`;
export const CommunityActionsWrapper = styled.div`
  display: flex;
`;
export const CommunityImage = styled.img`
  border-radius: 24px;
  width: 120px;
  height: 120px;
`;
export const CommunityInfo = styled.div`
  margin-left: 1.6rem;
`;
export const CommunityStats = styled.div`
  margin-top: 0.6rem;
  display: flex;
`;

export const CommunityStatItem = styled.div`
  margin-right: 4.8rem;
`;

export const CommunityAdmin = styled.div`
  display: flex;
  align-items: center;
`;

export const CommunityAdminImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 10px;
`;

export const CommunityDescription = styled.div`
  margin-top: 3.2rem;
  margin-bottom: 6.4rem;
`;

export const StyledButton = styled(Button)`
  margin-right: 1.6rem;
`;

export const UrlWrapper = styled.div`
  margin-top: 1.6rem;
  max-width: 30%;
  align-items: center;
  justify-content: space-between;
`;

export const CommunitySocialWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
`;

export const CommunitySocialItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.6rem;
`;
