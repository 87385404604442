import { useQuery } from 'react-query';
import * as communityService from '../../services/communityService';
import QueryKeys from '../../constants/queryKeys';

interface GetCommunityParams {
  eventId: number;
  enabled?: boolean;
}

export const useGetCommunityByEventId = ({ eventId, enabled = true }: GetCommunityParams) => {
  return useQuery({
    queryKey: QueryKeys.Community,
    queryFn: () => communityService.getCommunityByEventId(eventId),
    enabled,
  });
};
