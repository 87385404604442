import httpClient from '../../configs/axios';
import { EventTeam, EventTeamRequest, EventTeamResponse, Team, TeamPlayer, TeamPlayerResponse, TeamResponse } from './types';
import { mapDailyPerformerResponse, mapTeamLeaderboardResponse, mapTeamResponse } from './helpers';

export const fetchTeams = (): Promise<Team[]> => {
  return httpClient.get('/teams').then((response: TeamResponse[]) => {
    return response.map(mapTeamResponse);
  });
};

export const fetchTeam = (teamId: number): Promise<Team> => {
  return httpClient.get(`/teams/${teamId}`).then((response: TeamResponse) => {
    return mapTeamResponse(response);
  });
};

export const fetchEventTeamDetails = (data: EventTeamRequest): Promise<EventTeam> => {
  return httpClient.get(`/events/${data.eventId}/team/${data.teamId}`).then((response: EventTeamResponse) => {
    return {
      team: mapTeamResponse(response.team),
      teamStats: mapTeamLeaderboardResponse(response.team_leaderboard),
      dailyPerformers: response.daily_performer.map(mapDailyPerformerResponse),
    };
  });
};

export const fetchTeamPlayers = (data: EventTeamRequest): Promise<TeamPlayer[]> => {
  return httpClient.get(`/leaderboard/event/${data.eventId}/team/${data.teamId}`).then((response: TeamPlayerResponse[]) => {
    return response.map((item) => {
      return {
        userId: item.user_id,
        teamId: item.team_id,
        eventId: item.event_id,
        leaderboardId: item.leaderboard_id,
        username: item.username,
        playingAs: item.playing_as,
        avatar: item.avatar_path,
        currentRank: item.current_rank,
        previousRank: item.previous_rank,
        kills: item.kills,
        assists: item.assists,
        points: item.points,
        isMe: item.isMe,
      };
    });
  });
};
