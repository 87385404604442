import { ResetButton, ResetPasswordWrapper } from './ResetPasswordStyled';
import { Typography } from '../../components/typography';
import { colors } from '../../theme';
import { Input } from '../../components/input';
import { Link } from '../../components/link';
import Routes from '../../constants/routes';
import { Button } from '../../components/button';
import { useFormik } from 'formik';
import { ResetPasswordFormValues } from './types';
import { useResetPassword } from '../../queries/user/useResetPassword';
import { useErrorMessage } from '../../hooks/useErrorMessage';
import { capitalize } from 'lodash';
import { resetPasswordSchema } from '../../validation/userSchemas';

export const ResetPassword = () => {
  const { mutate: resetPassword, isSuccess } = useResetPassword();
  const { message: errorMessage, setMessage: setErrorMessage } = useErrorMessage();

  const { values, errors, touched, handleChange, handleSubmit } = useFormik<ResetPasswordFormValues>({
    initialValues: {
      email: '',
    },

    validationSchema: resetPasswordSchema,

    onSubmit: () => {
      resetPassword(values.email, {
        onError: (error: any) => {
          setErrorMessage(error?.response?.data?.message);
        },
      });
    },
  });

  let content: JSX.Element | null = (
    <>
      <Input
        name="email"
        label="Email"
        color="secondary"
        value={values.email}
        error={Boolean(touched.email && errors.email)}
        helperText={touched.email && errors.email}
        onChange={handleChange}
        placeholder="Email"
        fullWidth
        autoComplete="off"
        focused={Boolean(values.email)}
      />

      {errorMessage && (
        <Typography ml={1.4} mt={0.3} variant="subtitle3" color={colors.accentRed}>
          {capitalize(errorMessage)}
        </Typography>
      )}

      <ResetButton type="submit" variant="contained" size="medium" fullWidth>
        Reset Password
      </ResetButton>
    </>
  );

  if (isSuccess) {
    content = (
      <>
        <Typography mb={2.4} variant="body4" color={colors.transparentLight50}>
          A link to reset your password has been sent to your email.
        </Typography>

        <Link to={Routes.LOGIN}>
          <Button variant="contained" size="medium">
            Back to Log In
          </Button>
        </Link>
      </>
    );
  }

  return (
    <ResetPasswordWrapper onSubmit={handleSubmit}>
      <Typography mb={2.4} variant="h6" color={colors.white} textTransform="capitalize">
        Reset password
      </Typography>

      {content}

      <Typography mt={1.6} variant="subtitle2" color={colors.transparentLight50}>
        Have an account? <Link to={Routes.LOGIN}>Login</Link>
      </Typography>
    </ResetPasswordWrapper>
  );
};
