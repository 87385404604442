import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { Chip } from '../../components/chip';
import { TableCell, TableRow } from '../../components/table';
import { colors } from '../../theme';

export const LeaderboardWrapper = styled.div`
  margin-top: 6.4rem;
  margin-bottom: 7rem;
`;

export const Avatar = styled.img`
  border-radius: 8px;
  width: 32px;
  height: 32px;
`;

export const ArrowWrapper = styled.span`
  margin-right: 0.9rem;
`;

export const MoveIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NumberTableCell = styled(TableCell)`
  width: 50px;
`;

export const Description = styled.div`
  margin-left: 1.6rem;
`;

export const InfoTableCell = styled(TableCell)`
  width: 40%;
`;

export const PointsText = styled(Typography)`
  color: ${colors.white};
  font-size: 1.4rem;
  font-weight: 550;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IsYouChip = styled(Chip)`
  position: absolute;
  left: 0;
  top: -25px;
  max-width: unset;
`;
export const IsYouWrapper = styled.div`
  position: relative;
`;

export const TeamMemberAvatar = styled(Avatar)`
  border-radius: 50%;
  margin-left: -1rem;
  width: 24px;
  height: 24px;
`;

export const MoreMembers = styled.div`
  border-radius: 7px;
  background: ${colors.transparentDark50};
  backdrop-filter: blur(10px);
  color: ${colors.white};
  font-size: 1rem;
  font-weight: 550;
  padding: 0 0.4rem;
  margin-left: -1rem;
`;

export const TeamMembersWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledTableRow = styled(TableRow)`
  cursor: pointer;
`;
