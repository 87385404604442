import styled from '@emotion/styled';
import { Typography } from '../typography';
import { breakpoints, colors, mediaQuery } from '../../theme';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy-icon.svg';
import { useState } from 'react';
import { Tooltip } from '../tooltip';

const CopyToClipboardWrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.transparentLight10};
  margin-top: 1rem;
  padding: 0.8rem 1.6rem;
  backdrop-filter: blur(10px);
  border-radius: 6px;
  justify-content: space-between;

  ${mediaQuery(breakpoints.mobile)} {
    width: 100%;
    justify-content: space-between;
    margin-top: 1.6rem;
  }
`;

const CopyIconWrapper = styled(CopyIcon)`
  width: 16px;
  height: 16px;
  fill: ${colors.transparentLight50};
  cursor: pointer;
`;

interface CopyToClipboardProps {
  copyText: string;
}

export const CopyToClipboard = ({ copyText }: CopyToClipboardProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyText = () => {
    navigator.clipboard.writeText(copyText).then(() => {
      setIsCopied(true);
    });
  };

  return (
    <CopyToClipboardWrapper>
      <Typography noWrap variant="body4" color={colors.transparentLight50} mr={1.6}>
        {copyText}
      </Typography>

      <Tooltip open={isCopied} title="Copied" onClose={() => setIsCopied(false)} arrow>
        <CopyIconWrapper onClick={handleCopyText} />
      </Tooltip>
    </CopyToClipboardWrapper>
  );
};
