import styled from '@emotion/styled';
import { colors } from '../../theme';

export const CommunityCardWrapper = styled.div`
  background: ${colors.communityCard};
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
`;

export const FeaturedImage = styled.img`
  height: 200px;
  width: 100%;
  object-fit: cover;
`;

export const CommunityImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 12px;
  margin-top: -3rem;
  margin-left: 1.6rem;
  border: 4px solid ${colors.communityCard};
  background: black;
`;

export const CommunityContent = styled.div`
  padding: 1.6rem;
`;
