import { createTheme } from '@mui/material';
import { colors } from './colors';
import { breakpoints } from './breakpoints';

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.accentOrange,
    },
    error: {
      main: colors.accentRed,
    },
    info: {
      main: colors.accentBlue,
    },
    success: {
      main: colors.accentGreen,
    },
  },
  breakpoints: {
    values: {
      mobile: breakpoints.mobile,
      laptop: breakpoints.laptop,
      desktop: breakpoints.desktop,
    },
  },
  typography: {
    fontFamily: 'Neue Haas Grotesk Display Pro',
    h1: {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      fontSize: '9.6rem',
      fontWeight: 950,
      letterSpacing: '0.02em',
      display: 'block',
      lineHeight: '9.6rem',
    },
    h2: {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      fontSize: '7rem',
      fontWeight: 950,
      letterSpacing: '0.02em',
      display: 'block',
      lineHeight: '7rem',
    },
    h3: {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      fontSize: '4.4rem',
      fontWeight: 750,
      letterSpacing: '0.02em',
      display: 'block',
      lineHeight: '5.3rem',
    },
    h4: {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      fontSize: '3.2rem',
      fontWeight: 750,
      letterSpacing: '0.02em',
      display: 'block',
      lineHeight: '3.8rem',
    },
    h5: {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      fontSize: '2.8rem',
      fontWeight: 750,
      letterSpacing: '0.02em',
      display: 'block',
      lineHeight: '3.4rem',
    },
    h6: {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      fontSize: '2.4rem',
      fontWeight: 750,
      letterSpacing: '0.02em',
      display: 'block',
      lineHeight: '2.9rem',
    },
    h7: {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      fontSize: '1.8rem',
      fontWeight: 750,
      letterSpacing: '0.02em',
      display: 'block',
      lineHeight: '2.2rem',
    },
    body1: {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      fontSize: '1.8rem',
      fontWeight: 550,
      letterSpacing: '0.02em',
      display: 'block',
      lineHeight: '2.1rem',
    },
    body2: {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      fontSize: '1.8rem',
      fontWeight: 450,
      letterSpacing: '0.02em',
      display: 'block',
      lineHeight: '2.1rem',
    },
    body3: {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      fontSize: '1.6rem',
      fontWeight: 550,
      letterSpacing: '0.02em',
      display: 'block',
      lineHeight: '1.9rem',
    },
    body4: {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      fontSize: '1.6rem',
      fontWeight: 450,
      letterSpacing: '0.02em',
      display: 'block',
      lineHeight: '1.8rem',
    },
    body5: {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      fontSize: '1.4rem',
      fontWeight: 550,
      letterSpacing: '0.02em',
      display: 'block',
      lineHeight: '1.7rem',
    },
    body6: {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      fontSize: '1.4rem',
      fontWeight: 450,
      letterSpacing: '0.02em',
      display: 'block',
      lineHeight: '1.6rem',
    },
    subtitle1: {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      fontSize: '1.2rem',
      fontWeight: 550,
      letterSpacing: '0.04em',
      display: 'block',
      lineHeight: '1.4rem',
    },
    subtitle2: {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      fontSize: '1.2rem',
      fontWeight: 450,
      letterSpacing: '0.04em',
      display: 'block',
      lineHeight: '1.4rem',
    },
    subtitle3: {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      fontSize: '1rem',
      fontWeight: 550,
      letterSpacing: '0.04em',
      display: 'block',
      lineHeight: '1.2rem',
    },
    subtitle4: {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      fontSize: '1rem',
      fontWeight: 450,
      letterSpacing: '0.04em',
      display: 'block',
      lineHeight: '1.1rem',
    },
  },
  spacing: 10,
  components: {
    MuiTypography: {
      styleOverrides: {
        paragraph: { marginBottom: 0 },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'unset',
          borderRadius: '24px',
          boxShadow: 'none',

          '&:hover': {
            boxShadow: 'none',
          },
        },

        contained: {
          background: colors.primary,
          color: colors.black,

          ':hover': {
            background: colors.primary,
          },

          ':disabled': {
            background: colors.primary,
            color: colors.transparentDark50,
            opacity: 0.25,
          },
        },

        containedError: {
          background: colors.accentRed,
          color: colors.white,
          boxShadow: 'none',

          ':hover': {
            background: colors.accentRed,
            boxShadow: 'none',
          },

          ':disabled': {
            background: colors.accentRed,
            color: colors.transparentDark50,
            opacity: 0.25,
          },
        },

        outlined: {
          border: `2px solid ${colors.primary}`,
          color: colors.primary,

          ':hover': {
            border: `2px solid ${colors.primary}`,
          },

          ':disabled': {
            border: `2px solid ${colors.primary}`,
            color: colors.primary,
            opacity: 0.25,
          },
        },

        sizeLarge: {
          padding: '1.5rem 3.2rem 1.4rem',
          fontSize: '1.6rem',
          fontWeight: 750,
          letterSpacing: '0.02em',
          lineHeight: '1.9rem',
        },

        sizeMedium: {
          padding: '1.2rem 3.2rem 1.1rem',
          fontSize: '1.4rem',
          fontWeight: 750,
          letterSpacing: '0.02em',
          lineHeight: '1.7rem',
        },

        sizeSmall: {
          padding: '0.9rem 3.2rem',
          fontSize: '1.2rem',
          fontWeight: 750,
          letterSpacing: '0.02em',
          lineHeight: '1.4rem',
        },
      },
      variants: [
        {
          props: { size: 'extraSmallOutlined' },
          style: {
            padding: '0.4rem 1.6rem',
            fontSize: '1rem',
            fontWeight: 750,
            letterSpacing: '0.06em',
            lineHeight: '1.2rem',
          },
        },
        {
          props: { size: 'extraSmall' },
          style: {
            padding: '0.6rem 1.6rem',
            fontSize: '1rem',
            fontWeight: 750,
            letterSpacing: '0.06em',
            lineHeight: '1.2rem',
          },
        },
        {
          props: { size: 'tiny' },
          style: {
            padding: '0.2rem 0.8rem 0.1rem',
            fontSize: '1rem',
            fontWeight: 750,
            letterSpacing: '0.06em',
            lineHeight: '1.2rem',
          },
        },
      ],
    },

    MuiInputLabel: {
      styleOverrides: {
        root: ({ ownerState }: any) => {
          const color = ownerState?.formControl?.color;

          return {
            color: colors.transparentLight50,
            fontSize: '1.4rem',
            fontWeight: 450,
            lineHeight: '1.6rem',
            letterSpacing: '0.02em',
            top: '-4px',
            left: '4px',

            '&.Mui-focused': {
              background: color === 'primary' ? colors.primary : colors.transparentLight25,
              padding: '0.4rem 1rem 0.4rem',
              borderRadius: 4,
              fontSize: '1.4rem',
              fontWeight: 550,
              lineHeight: '1.2rem',
              letterSpacing: '0.04em',
              color: color === 'primary' ? colors.black : colors.transparentLight50,
              backdropFilter: 'blur(10px)',
              top: '2px',

              '&.Mui-error': {
                background: colors.accentRed,
                color: colors.black,
              },
            },

            '&.Mui-disabled': {
              background: color === 'primary' ? colors.primary : colors.transparentLight25,
              padding: '0.4rem 1rem 0.4rem',
              borderRadius: 4,
              fontSize: '1.4rem',
              fontWeight: 550,
              lineHeight: '1.2rem',
              letterSpacing: '0.04em',
              color: color === 'primary' ? colors.black : colors.transparentLight50,
              backdropFilter: 'blur(10px)',
              top: '2px',
            },
          };
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          fontWeight: 450,
          lineHeight: '1.6rem',
          letterSpacing: '0.02em',
          color: colors.primary,
          border: `2px solid ${colors.primary}`,
          outline: 'none',
          height: '40px',
          borderRadius: 24,

          '&.Mui-error': {
            border: `2px solid ${colors.accentRed}`,
          },
        },

        colorSecondary: {
          color: colors.white,
          border: `2px solid ${colors.transparentLight50}`,

          '&.Mui-focused': {
            border: `2px solid ${colors.transparentLight50}`,
            padding: '0.8rem 0 0.4rem 0',
          },

          '&.Mui-error': {
            border: `2px solid ${colors.accentRed}`,
          },

          '& .Mui-disabled': {
            color: colors.white,
            padding: '0.8rem 1.6rem 0.4rem 1.6rem',
          },
        },

        notchedOutline: {
          border: 'none',
        },
        input: {
          padding: '0.8rem 1.6rem 0.8rem 1.6rem',
          textFillColor: 'unset !important',
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 450,
          letterSpacing: '0.04em',
          lineHeight: '1.1rem',
          '::first-letter': {
            textTransform: 'uppercase',
          },
        },
      },
    },

    MuiLinearProgress: {
      styleOverrides: {
        root: {
          padding: '0.75rem',
          borderRadius: '4px',
          background: colors.transparentDark15,
          width: '100%',
        },

        bar: {
          background: colors.black,
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: { height: 'auto' },
        filled: { backdropFilter: 'blur(10px)' },
        sizeSmall: {
          padding: '0.2rem 0.8rem 0.1rem',
          borderRadius: '4px',
          fontWeight: 550,
          fontSize: '1rem',
          lineHeight: '1.2rem',
          letterSpacing: '0.04em',
        },
        sizeMedium: {
          padding: '0.8rem 1.2rem',
          borderRadius: '10px',
          fontWeight: 550,
          fontSize: '1.2rem',
          lineHeight: '1.4rem',
          letterSpacing: '0.04em',
        },
        colorPrimary: {
          background: colors.primary,
          color: colors.black,
        },
        colorSecondary: {
          background: colors.black,
          color: colors.primary,
        },
        label: {
          padding: 0,
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            color: colors.transparentLight25,
            fontWeight: 550,
            fontSize: '1rem',
            lineHeight: '1.2rem',
            letterSpacing: '0.04em',
            textTransform: 'uppercase',
            borderBottom: `1px solid ${colors.transparentLight15}`,
            paddingBottom: '1rem',
          },
        },
      },
    },

    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            color: colors.transparentLight50,
            fontWeight: 450,
            fontSize: '1.4rem',
            lineHeight: '1.6rem',
            letterSpacing: '0.02em',
            borderBottom: `1px solid ${colors.transparentLight05}`,
          },

          '& td': {
            padding: '1.1rem',
          },
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.transparentLight10,

          ':before': {
            borderTop: `thin solid ${colors.transparentLight10}`,
          },

          ':after': {
            borderTop: `thin solid ${colors.transparentLight10}`,
          },
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          background: colors.transparentDark50,
          borderRadius: '22px',
          border: `2px solid ${colors.primary}`,

          '&:before': {
            content: '""',
            position: 'absolute',
            backdropFilter: 'blur(10px)',
            width: '100%',
            height: '100%',
            zIndex: -1,
          },
        },
      },
    },

    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 32,
          height: 18,
          padding: 0,
          display: 'flex',
          '& .MuiSwitch-switchBase': {
            padding: 3,
            '&.Mui-checked': {
              transform: 'translateX(14px)',
              color: colors.black,

              '& .MuiSwitch-thumb': {
                color: colors.black,
              },

              '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: colors.primary,
              },
            },
          },
          '& .MuiSwitch-thumb': {
            color: colors.transparentLight25,
            width: 12,
            height: 12,
            borderRadius: 6,
            transition: {
              duration: 200,
            },
          },
          '& .MuiSwitch-track': {
            border: `1px solid ${colors.transparentLight25}`,
            borderRadius: 18 / 2,
            opacity: 1,
            backgroundColor: colors.black,
            boxSizing: 'border-box',
          },
        },
      },
    },

    MuiMobileStepper: {
      styleOverrides: {
        root: {
          background: 'none',
          padding: 0,
        },

        dot: {
          background: colors.transparentDark15,
          margin: '0 0.4rem',
          width: 4,
          height: 4,
        },

        dotActive: {
          background: colors.black,
        },
      },
    },

    MuiSkeleton: {
      styleOverrides: {
        root: {
          transform: 'unset',
        },
      },
    },
  },
});
