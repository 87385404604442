import { ReactComponent as RiotIcon } from '../../assets/icons/riot-icon.svg';
import { ReactComponent as CountIcon } from '../../assets/icons/coin-icon.svg';
import { ReactComponent as CircleDiscordIcon } from '../../assets/icons/circle-discord-icon.svg';
import { ReactComponent as CircleLolIcon } from '../../assets/icons/circle-lol-icon.svg';
import { ReactComponent as AssistsIcon } from '../../assets/icons/assists-icon.svg';
import { ReactComponent as KillIcon } from '../../assets/icons/kill-icon.svg';
import { QuestIconProps } from './types';

export const QuestIcon = ({ icon, ...restProps }: QuestIconProps) => {
  switch (icon) {
    case 'riot-icon':
      return <RiotIcon {...restProps} />;
    case 'circle-discord-icon':
      return <CircleDiscordIcon {...restProps} />;
    case 'circle-lol-icon':
      return <CircleLolIcon {...restProps} />;
    case 'assists-icon':
      return <AssistsIcon {...restProps} />;
    case 'kill-icon':
      return <KillIcon {...restProps} />;
    default:
      return <CountIcon {...restProps} />;
  }
};
