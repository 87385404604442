import styled from '@emotion/styled';
import { colors } from '../../theme';
import { Typography } from '../../components/typography';

const PrivacyPolicyWrapper = styled.div`
  color: ${colors.white};
  padding-top: 8rem;
`;

export const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyWrapper>
      <Typography variant="h4" mb={6.4}>
        Privacy Policy
      </Typography>

      <Typography variant="h6" mb={1.6}>
        Introduction
      </Typography>

      <Typography variant="body6" mb={6.4}>
        This privacy policy (“Policy”) describes how Nemesis (“Company,” “we,” and “our”) processes, collects, uses and shares personal data
        when using this website https://rivals.gg (the “Website”). Please read the following information carefully to understand our
        practices regarding your personal data and how we will process data.
      </Typography>

      <Typography variant="h6" mb={2.4}>
        1. Purposes of Processing
      </Typography>

      <Typography variant="body5" mb={0.8}>
        What is personal data?
      </Typography>
      <Typography variant="body6" mb={2.4}>
        We collect information about you in a range of forms, including personal data. As used in this Policy, “personal data” is as defined
        in the General Data Protection Regulation, this includes any information which, either alone or in combination with other
        information we process about you, identifies you as an individual, including, for example, your name, postal address, email address
        and telephone number.
      </Typography>

      <Typography variant="body5" mb={0.8}>
        Why do we need your personal data?
      </Typography>
      <Typography variant="body6" mb={6.4}>
        We will only process your personal data in accordance with applicable data protection and privacy laws. We need certain personal
        data in order to provide you with access to the website. If you registered with us, you will have been asked to agree to provide
        this information in order to access our services, purchase our products, or view our content. This consent provides us with the
        legal basis we require under applicable law to process your data. You maintain the right to withdraw such consent at any time. If
        you do not agree to our use of your personal data in line with this Policy, please do not use our website.
      </Typography>

      <Typography variant="h6" mb={3.2}>
        2. Collecting Your Personal Data
      </Typography>

      <Typography variant="body6" mb={3.2}>
        We will only process your personal data in accordance with applicable data protection and privacy laws. We need certain personal
        data in order to provide you with access to the website. If you registered with us, you will have been asked to agree to provide
        this information in order to access our services, purchase our products, or view our content. This consent provides us with the
        legal basis we require under applicable law to process your data. You maintain the right to withdraw such consent at any time. If
        you do not agree to our use of your personal data in line with this Policy, please do not use our website.
      </Typography>

      <Typography variant="body5" mb={0.8}>
        Information You Give Us.
      </Typography>

      <Typography variant="body5" mb={0.8}>
        Includes:
      </Typography>

      <Typography variant="body6" mb={0.8} ml={2}>
        &#x2022; The personal data you provide when you register to use our website, including your name, postal address, email address,
        telephone number, username, password and demographic information (such as your gender);
      </Typography>

      <Typography variant="body6" mb={0.8} ml={2}>
        &#x2022; The personal data that may be contained in any video, comment or other submission you upload or post to the website;
      </Typography>
      <Typography variant="body6" mb={0.8} ml={2}>
        &#x2022; The personal data you provide in connection with our rewards program and other promotions we run on the website
      </Typography>
      <Typography variant="body6" mb={0.8} ml={2}>
        &#x2022; The personal data you provide when you report a problem with our website or when we provide you with customer support;
      </Typography>
      <Typography variant="body6" mb={0.8} ml={2}>
        &#x2022; The personal data you provide when you make a purchase through our website; and
      </Typography>
      <Typography variant="body6" mb={0.8} ml={2}>
        &#x2022; The personal data you provide when you correspond with us by phone, email or otherwise.
      </Typography>

      <Typography variant="body5" mt={3.2} mb={0.8}>
        Information from Social Networking Websites.
      </Typography>

      <Typography variant="body6" mb={3.2}>
        Our website includes interfaces that allow you to connect with social networking sites (each a “SNS”). If you connect to a SNS
        through our website, you authorize us to access, use and store the information that you agreed the SNS could provide to us based on
        your settings on that SNS. We will access, use and store that information in accordance with this Policy. You can revoke our access
        to the information you provide in this way at any time by amending the appropriate settings from within your account settings on the
        applicable SNS.
      </Typography>

      <Typography variant="body5" mb={0.8}>
        Information Automatically Collected.
      </Typography>

      <Typography variant="body6" mb={3.2}>
        We automatically log information about you and your computer or mobile device when you access our website. For example, when
        visiting our website, we log your computer or mobile device operating system name and version, manufacturer and model, browser type,
        browser language, screen resolution, the website you visited before browsing to our website, pages you viewed, how long you spent on
        a page, access times and information about your use of and actions on our website. We collect this information about you using
        cookies.
      </Typography>

      <Typography variant="body5" mb={0.8}>
        Automated Decision Making and Profiling.
      </Typography>

      <Typography variant="body6" mb={6.4}>
        We do not use your personal data for the purposes of automated decision-making. However, we may do so in order to fulfill
        obligations imposed by law, in which case we will inform you of any such processing and provide you with an opportunity to object.
      </Typography>

      <Typography variant="h6" mb={3.2}>
        3. Cookies
      </Typography>

      <Typography variant="body5" mb={0.8}>
        What are cookies?
      </Typography>

      <Typography variant="body6" mb={0.8}>
        We may collect information using “cookies.” Cookies are small data files stored on the hard drive of your computer or mobile device
        by a website. We may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on
        your computer or mobile device until you delete them) to provide you with a more personal and interactive experience on our website.
      </Typography>

      <Typography variant="body6" mb={3.2}>
        We use two broad categories of cookies: (1) first party cookies, served directly by us to your computer or mobile device, which are
        used only by us to recognize your computer or mobile device when it revisits our website; and (2) third party cookies, which are
        served by service providers on our website, and can be used by such service providers to recognize your computer or mobile device
        when it visits other websites.
      </Typography>

      <Typography variant="body5" mb={1.6}>
        Cookies we use
      </Typography>

      <Typography variant="body6" mb={1.6}>
        Our website uses the following types of cookies for the purposes set out below:
      </Typography>

      <Typography variant="body5" mb={0.8} ml={3.2}>
        Essential Cookies
      </Typography>

      <Typography variant="body6" mb={1.6} ml={3.2}>
        These cookies are essential to provide you with services available through our website and to enable you to use some of its
        features. For example, they allow you to log in to secure areas of our website and help the content of the pages you request load
        quickly. Without these cookies, the services that you have asked for cannot be provided, and we only use these cookies to provide
        you with those services.
      </Typography>

      <Typography variant="body5" mb={0.8} ml={3.2}>
        Functionality Cookies
      </Typography>

      <Typography variant="body6" mb={0.8} ml={3.2}>
        These cookies are used to collect information about traffic to our website and how users use our website. The information gathered
        does not identify any individual visitor. It includes the number of visitors to our website, the websites that referred them to our
        website, the pages they visited on our website, what time of day they visited our website, whether they have visited our website
        before, and other similar information. We use this information to help operate our website more efficiently, to gather broad
        demographic information and to monitor the level of activity on our website
      </Typography>
      <Typography variant="body6" mb={0.8} ml={3.2}>
        We use Google Analytics for this purpose. Google Analytics uses its own cookies. It is only used to improve how our website works.
        You can find out more information about Google Analytics cookies here:
        https://developers.google.com/analytics/resources/concepts/gaConceptsCookies
      </Typography>
      <Typography variant="body6" mb={0.8} ml={3.2}>
        You can find out more about how Google protects your data here: https://policies.google.com/privacy.
      </Typography>
      <Typography variant="body6" mb={1.6} ml={3.2}>
        You can prevent the use of Google Analytics relating to your use of our website by downloading and installing the browser plugin
        available via this link: http://tools.google.com/dlpage/gaoptout?hl=en-GB
      </Typography>

      <Typography variant="body5" mb={0.8} ml={3.2}>
        Targeted and advertising cookies
      </Typography>

      <Typography variant="body6" mb={0.8} ml={3.2}>
        These cookies track your browsing habits to enable us to show advertising which is more likely to be of interest to you. These
        cookies use information about your browsing history to group you with other users who have similar interests. Based on that
        information, and with our permission, third party advertisers can place cookies to enable them to show adverts which we think will
        be relevant to your interests while you are on third party websites.
      </Typography>
      <Typography variant="body6" mb={0.8} ml={3.2}>
        You can disable cookies which remember your browsing habits and target advertising at you by visiting
        http://www.youronlinechoices.com/uk/your-ad-choices
      </Typography>
      <Typography variant="body6" mb={1.6} ml={3.2}>
        If you choose to remove targeted or advertising cookies, you will still see adverts but they may not be relevant to you. Even if you
        do choose to remove cookies by the companies listed at the above link, not all companies that serve online behavioral advertising
        are included in this list, and so you may still receive some cookies and tailored adverts from companies that are not listed.
      </Typography>

      <Typography variant="body5" mb={0.8} ml={3.2}>
        Social Media Cookies
      </Typography>

      <Typography variant="body6" mb={1.6} ml={3.2}>
        These cookies are used when you share information using a social media sharing button or “like” button on our website or you link
        your account or engage with our content on or through a social networking website such as Facebook, Twitter or Google+. The social
        network will record that you have done this.
      </Typography>

      <Typography variant="body5" mb={1.6}>
        Disabling cookies
      </Typography>

      <Typography variant="body6" mb={6.4}>
        You can typically remove or reject cookies via your browser settings. In order to do this, follow the instructions provided by your
        browser (usually located within the “settings,” “help” “tools” or “edit” facility). Many browsers are set to accept cookies until
        you change your settings. If you do not accept our cookies, you may experience some inconvenience in your use of our website. For
        example, we may not be able to recognize your computer or mobile device and you may need to log in every time you visit our website.
      </Typography>
    </PrivacyPolicyWrapper>
  );
};
