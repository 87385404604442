import styled from '@emotion/styled';
import { Button } from '../../components/button';
import { Input } from '../../components/input';
import { breakpoints, mediaQuery } from '../../theme';

export const UpdatePasswordWrapper = styled.form`
  width: 250px;
  ${mediaQuery(breakpoints.mobile)} {
    width: 100%;
  }
`;

export const ResetButton = styled(Button)`
  margin-top: 2.4rem;
`;

export const StyledInput = styled(Input)`
  margin-bottom: 1.6rem;
`;
