import { useState } from 'react';
import { extractErrorData, extractErrorMessage } from '../utils/helpers';

export const useErrorMessage = () => {
  const [message, setMessage] = useState<string | null>(null);

  const handleExtractMessage = (value: any) => {
    setMessage(extractErrorMessage(value));
  };

  const handleExtractData = (value: any) => {
    setMessage(extractErrorData(value));
  };

  return {
    message,
    setMessage,
    handleExtractMessage,
    handleExtractData,
  };
};
