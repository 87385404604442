import { ReactComponent as DiscordIcon } from '../../assets/svg/contact_us.svg';
import { redirectToDiscord } from '../../utils/helpers';
import { ContactUs, FooterText, FooterWrapper } from './FooterStyled';

export const Footer = () => {
  return (
    <FooterWrapper>
      <FooterText>&copy; 2023 Nemesis.zone</FooterText>

      <ContactUs onClick={redirectToDiscord}>
        <FooterText>Contact Us</FooterText>
        <DiscordIcon width={24} height={24} />
      </ContactUs>
    </FooterWrapper>
  );
};
