import { useFormik } from 'formik';
import { loginSchema } from '../../validation/authSchemas';
import { useLoginUser } from '../../queries/auth/useLoginUser';
import { useErrorMessage } from '../../hooks/useErrorMessage';

type FormValues = {
  email: string;
  password: string;
};

export const useLoginForm = () => {
  const { message: errorMessage, setMessage } = useErrorMessage();
  const { mutate: login } = useLoginUser();

  const loginForm = useFormik<FormValues>({
    initialValues: {
      email: '',
      password: '',
    },

    validationSchema: loginSchema,

    onSubmit: (values) => {
      login(values, {
        onError: () => {
          setMessage('Your email or password is incorrect');
        },
      });
    },
  });

  return {
    errorMessage,
    ...loginForm,
  };
};
