enum QueryKeys {
  Community = 'community',
  Events = 'events',
  EventParticipants = 'event-participants',
  EventLeaderboard = 'event-leaderboard',
  EventUser = 'event-user',
  Teams = 'teams',
  PlayerLeaderboard = 'player-leaderboard',
  ConnectInfo = 'connect-info',

  UserPoints = 'user-points',
  UserProfile = 'user-profile',
  Quests = 'quests',
}

export default QueryKeys;
