import React from 'react';
import { ContainerWrapper } from './ContainerStyled';

interface ContainerProps {
  children: React.ReactNode;
}

export const Container = ({ children, ...rest }: ContainerProps) => {
  return <ContainerWrapper {...rest}>{children}</ContainerWrapper>;
};
