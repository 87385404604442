import httpClient from '../../configs/axios';
import { ConnectAccountData, LolUser } from './types';

export const getConnectInfo = (): Promise<LolUser> => {
  return httpClient.get('/lol/connect/info').then((response) => {
    return {
      id: response.id,
      userId: response.user_id,
      puuid: response.puuid,
      summonerName: response.summoner_name,
    };
  });
};

export const redirectToRiotLogin = () => {
  return httpClient.get('/game/connect/riot');
};

export const connectToAccount = (data: ConnectAccountData) => {
  return httpClient.post('/lol/connect', { summoner_name: data.summonerName });
};

export const disconnectFromAccount = () => {
  return httpClient.delete('/lol/disconnect');
};
