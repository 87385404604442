import { generatePath, useNavigate, useOutlet } from 'react-router-dom';
import { DarkOverlay } from '../LayoutStyled';
import {
  ContentWrapper,
  EmptyUserPoints,
  Header,
  HeaderButtons,
  HeaderColumn,
  Logo,
  LogoWrapper,
  MainLayoutWrapper,
  NoConnectionUserPoints,
  NoConnectionWrapper,
  PointsIconBadge,
  PointWrapper,
  ProfileButton,
  UserPoints,
} from './MainLayoutStyled';
import { Footer } from '../../../components/footer';
import { Container } from '../../../components/container';
import { ReactComponent as ChestIcon } from '../../../assets/icons/chest-icon.svg';
import { ReactComponent as DoubleSwordIcon } from '../../../assets/icons/double-sword-icon.svg';
import { ReactComponent as CountIcon } from '../../../assets/icons/coin-icon.svg';
import { ReactComponent as RiotIcon } from '../../../assets/icons/riot-icon.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/user-icon.svg';
import SolidBlack from '../../../assets/svg/solid-black.svg';
import defaultHeaderImage from '../../../assets/images/bg-main-layout.png';
import { Typography } from '../../../components/typography';
import { breakpoints, colors } from '../../../theme';
import { Button } from '../../../components/button';
import { GameConnect } from '../../../context/gameConnect';
import { MainLayoutState } from './MainLayoutState';
import { useToggle } from '../../../hooks/useToggle';
import { Profile } from '../../profile';
import { Quests } from '../../quests';
import { GameRewards } from '../../gameRewards';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { some } from 'lodash';
import Routes from '../../../constants/routes';

const MainLayout = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const navigate = useNavigate();
  const content = useOutlet();
  const { isConnectFinished, isLoadingConnectInfo } = GameConnect.useContainer();
  const {
    userPoints,
    quests,
    teamInfo,
    isLoadingUserPoints,
    isLoadingTeamInfo,
    isQuestModalOpen,
    handleOpenQuestModal,
    handleCloseQuestModal,
    useRedirectToRiotLogin,
  } = MainLayoutState.useContainer();
  const { isOpen: isProfileModalOpen, handleOpen: handleOpenProfileModal, handleClose: handleCloseProfileModal } = useToggle();
  const { isOpen: isGameRewardsModalOpen, handleOpen: handleOpenGameRewardsModal, handleClose: handleCloseGameRewardsModal } = useToggle();
  const { mutate: redirectToRiot } = useRedirectToRiotLogin();

  const isLoading = isLoadingConnectInfo || isLoadingUserPoints || isLoadingTeamInfo;
  const headerImagePath = isLoadingTeamInfo ? SolidBlack : teamInfo?.team?.headerImagePath || defaultHeaderImage;
  const isRewardReceived = Boolean(userPoints && userPoints?.unclaimed.completedGames > 0);
  const isQuestClaimable = some(quests, { completed: true, claimed: false });

  const openRewardsModal = () => {
    handleOpenGameRewardsModal();
  };

  let headerContent: JSX.Element | null = (
    <NoConnectionWrapper>
      <Button
        variant="contained"
        color="error"
        size="small"
        startIcon={<RiotIcon width={16} height={16} />}
        onClick={() => redirectToRiot()}
      >
        Connect Riot ID
      </Button>

      <NoConnectionUserPoints>
        <PointWrapper onClick={handleOpenQuestModal}>
          <DoubleSwordIcon cursor="pointer" width={16} height={16} fill={colors.primary} />
        </PointWrapper>
        <PointWrapper>
          <CountIcon width={16} height={16} fill={colors.primary} />
          <Typography variant="body5" ml={0.8}>
            {userPoints?.claimed.goldRewards}
          </Typography>
        </PointWrapper>
      </NoConnectionUserPoints>
    </NoConnectionWrapper>
  );

  if (isConnectFinished) {
    headerContent = (
      <UserPoints>
        <PointWrapper onClick={openRewardsModal}>
          <PointsIconBadge color="error" variant="dot" invisible={!isRewardReceived}>
            <ChestIcon cursor="pointer" width={16} height={16} fill={colors.primary} />
          </PointsIconBadge>
        </PointWrapper>
        <PointWrapper onClick={handleOpenQuestModal}>
          <PointsIconBadge color="error" variant="dot" invisible={!isQuestClaimable}>
            <DoubleSwordIcon cursor="pointer" width={16} height={16} fill={colors.primary} />
          </PointsIconBadge>
        </PointWrapper>
        <PointWrapper>
          <CountIcon width={16} height={16} fill={colors.primary} />
          <Typography variant="body5" ml={0.8}>
            {userPoints?.claimed.goldRewards}
          </Typography>
        </PointWrapper>
      </UserPoints>
    );
  }

  if (isLoading) {
    headerContent = <EmptyUserPoints />;
  }

  return (
    <MainLayoutWrapper headerImagePath={headerImagePath}>
      <DarkOverlay />

      <ContentWrapper>
        <Header>
          <HeaderColumn />

          <LogoWrapper>
            <Logo onClick={() => navigate(generatePath(Routes.HOME))} />
          </LogoWrapper>

          <HeaderButtons>
            {!isMobile && headerContent}

            <ProfileButton width={34} height={34} onClick={handleOpenProfileModal}>
              <UserIcon width={18} height={18} />
            </ProfileButton>
          </HeaderButtons>
        </Header>

        {isMobile && <Container>{headerContent}</Container>}

        {content}

        <Container>
          <Footer />
        </Container>
      </ContentWrapper>

      {isProfileModalOpen && <Profile isOpen={isProfileModalOpen} onClose={handleCloseProfileModal} />}
      <Quests isOpen={isQuestModalOpen} onClose={handleCloseQuestModal} />
      <GameRewards isOpen={isGameRewardsModalOpen} onClose={handleCloseGameRewardsModal} />
    </MainLayoutWrapper>
  );
};

export const MainLayoutContainer = () => {
  return (
    <MainLayoutState.Provider>
      <MainLayout />
    </MainLayoutState.Provider>
  );
};
