import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import './index.scss';
import { QueryClientProvider } from 'react-query';
import queryClient from './configs/queryClient';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Auth } from './context/auth';
import { GameConnect } from './context/gameConnect';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Auth.Provider>
          <GameConnect.Provider>
            <App />
          </GameConnect.Provider>
        </Auth.Provider>
      </ThemeProvider>
    </BrowserRouter>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
