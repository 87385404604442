import React from 'react';
import { TableBody as MaterialTableBody, TableBodyProps as MaterialTableBodyProps } from '@mui/material';

type TableBodyProps = {
  children: React.ReactNode;
} & MaterialTableBodyProps;

export const TableBody = ({ children, ...rest }: TableBodyProps) => {
  return <MaterialTableBody {...rest}>{children}</MaterialTableBody>;
};
