import { createContainer } from 'unstated-next';
import { useGetCommunityByEventId } from '../../queries/community/useGetCommunityByEventId';
import { generatePath, useNavigate } from 'react-router-dom';
import Routes from '../../constants/routes';
import { useGetEvents } from '../../queries/event/useGetEvents';
import { getEventProgressLabel, getEventProgressValue, hasEventFinished, hasEventStarted } from './helpers';
import { useMemo } from 'react';
import { useGetEventParticipants } from '../../queries/event/useGetEventParticipants';
import { useGetEventLeaderboard } from '../../queries/event/useGetEventLeaderboard';
import { useGetEventUser } from '../../queries/event/useGetEventUser';

const useEventState = () => {
  const { data: events, isLoading: isLoadingEvent } = useGetEvents();
  const event = events?.[0];

  const { data: community, isLoading: isLoadingCommunity } = useGetCommunityByEventId({ eventId: event?.id ?? 0 });

  const shareLink = useMemo(
    () =>
      `${process.env.REACT_APP_URL}${generatePath(Routes.INVITATION_SIGNUP, {
        invitationCode: community?.invitationCode ?? '',
      })}`,
    [community?.invitationCode]
  );

  const progressTotalValue = useMemo(
    () => getEventProgressValue(event?.startDate ?? '', event?.endDate ?? ''),
    [event?.startDate, event?.endDate]
  );
  const progressLabel = useMemo(
    () => getEventProgressLabel(event?.startDate ?? '', event?.endDate ?? ''),
    [event?.startDate, event?.endDate]
  );

  const isEventStarted = useMemo(() => hasEventStarted(event?.startDate ?? ''), [event?.startDate]);
  const isEventFinished = useMemo(() => hasEventFinished(event?.endDate ?? ''), [event?.endDate]);

  const { data: participants, isLoading: isLoadingParticipants } = useGetEventParticipants({
    eventId: event?.id ?? 0,
    enabled: Boolean(event?.id),
  });

  const { data: leaderboardTeams, isLoading: isLoadingLeaderboardTeams } = useGetEventLeaderboard({
    eventId: event?.id ?? 0,
    enabled: isEventStarted,
  });

  const { data: eventUser, isLoading: isLoadingEventUser } = useGetEventUser({ eventId: event?.id ?? 0, enabled: Boolean(event?.id) });

  const navigate = useNavigate();
  const handleGotoTeamPage = (teamId: number) => {
    navigate(generatePath(Routes.TEAM, { eventId: String(event?.id), teamId: String(teamId) }));
  };

  return {
    shareLink,
    progressTotalValue,
    progressLabel,
    event,
    isEventStarted,
    isEventFinished,
    participants,
    leaderboardTeams,
    eventUser,
    isLoadingParticipants,
    isLoadingLeaderboardTeams,
    isLoadingEventUser,
    isLoadingCommunity,
    isLoadingEvent,
    handleGotoTeamPage,
  };
};

export const EventState = createContainer(useEventState);
