import styled from '@emotion/styled';
import { breakpoints, colors, mediaQuery } from '../../../theme';
import { NemessisLogo } from '../LayoutStyled';
import Box from '@mui/material/Box';

export const LandingLayoutWrapper = styled.div`
  background: ${colors.gray};
  width: 100%;
  height: 720px;
  position: relative;
  background-size: cover;
`;

export const Content = styled.div`
  position: relative;
  z-index: 2;
`;

export const LogoWrapper = styled.div`
  padding: 3rem 6rem;
  position: relative;
  z-index: 2;
`;

export const Logo = styled(NemessisLogo)`
  width: 172px;
  height: 48px;
`;

export const MenuBar = styled(Box)`
  display: flex;
  flex-grow: 1;

  ${mediaQuery(breakpoints.mobile)} {
    display: none;
  }
`;
