import { useMutation } from 'react-query';
import { useLoginUser } from '../auth/useLoginUser';
import * as userService from '../../services/userService';

export const useCreateCommunityUser = () => {
  const { mutate: login } = useLoginUser();

  return useMutation(userService.createCommunityUser, {
    onSuccess: (data, { email, password }) => {
      login({ email, password });
    },
  });
};
