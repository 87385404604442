import { Navigate, useOutlet } from 'react-router-dom';
import Routes from '../../constants/routes';
import { Auth } from '../../context/auth';

export const PrivateRoute = () => {
  const content = useOutlet();
  const { isTokenExpired } = Auth.useContainer();

  if (isTokenExpired) {
    return <Navigate to={Routes.LOGIN} />;
  }

  return <>{content}</>;
};
