import { Table, TableBody, TableCell } from '../../../components/table';
import { Typography } from '../../../components/typography';
import { colors } from '../../../theme';
import { ViewDivider } from '../../../molecules/viewDivider';
import { EventState } from '../EventState';
import { BoardEmptyState } from '../../../molecules/boardEmptyState';
import { MAX_ITEMS_IN_PARTICIPANTS_TABLE, MAX_MEMBERS_IN_TABLE_ITEM } from '../helpers';
import { orderBy, take } from 'lodash';
import { useToggle } from '../../../hooks/useToggle';
import { useMemo } from 'react';
import {
  Avatar,
  Description,
  InfoWrapper,
  IsYouChip,
  IsYouWrapper,
  MoreMembers,
  StyledTableRow,
  TeamMemberAvatar,
  TeamMembersWrapper,
} from '../../../molecules/leaderboard';

export const ParticipantsTable = () => {
  const { participants, isLoadingParticipants, handleGotoTeamPage } = EventState.useContainer();
  const { isOpen: isViewAllOpen, handleOpen: handleOpenViewAll } = useToggle();

  const initialParticipants = useMemo(() => {
    const orderedParticipants = orderBy(participants, (item) => item.isMyTeam, ['asc']);
    return isViewAllOpen ? orderedParticipants : take(orderedParticipants, MAX_ITEMS_IN_PARTICIPANTS_TABLE);
  }, [isViewAllOpen, participants]);

  const isDividerAppear = useMemo(
    () => participants && participants?.length > MAX_ITEMS_IN_PARTICIPANTS_TABLE && !isLoadingParticipants && !isViewAllOpen,
    [participants, isViewAllOpen, isLoadingParticipants]
  );

  if (!Boolean(participants) || participants?.length === 0) {
    return <BoardEmptyState label="Teams and Player will show up here once they join" />;
  }

  return (
    <>
      <Table>
        <TableBody>
          {initialParticipants?.map((item) => (
            <StyledTableRow
              key={item.teamId}
              // selected={item.isWinner}
              style={{ cursor: 'pointer' }}
              selectedsecond={String(item.isMyTeam)}
              onClick={() => handleGotoTeamPage(item.teamId)}
            >
              <TableCell align="left">
                <IsYouWrapper>
                  {item.isMyTeam && <IsYouChip label="Your Team" variant="filled" size="small" color="primary" style={{ top: -19 }} />}
                  <InfoWrapper>
                    <Avatar src={item.teamAvatar} alt="Avatar" />

                    <Description>
                      <Typography variant="body3" color={colors.white} align="left">
                        {item.teamName}
                      </Typography>

                      <Typography variant="subtitle2" color={colors.transparentLight50} align="left">
                        {`${item.teamUsersNum} players`}
                      </Typography>
                    </Description>
                  </InfoWrapper>
                </IsYouWrapper>
              </TableCell>

              <TableCell align="right">
                <TeamMembersWrapper>
                  {item.users.map((user) => (
                    <TeamMemberAvatar key={user.id} alt={user.name} src={user.avatar} />
                  ))}

                  {item.users.length > MAX_MEMBERS_IN_TABLE_ITEM && (
                    <MoreMembers>{`+${item.teamUsersNum - MAX_MEMBERS_IN_TABLE_ITEM}`}</MoreMembers>
                  )}
                </TeamMembersWrapper>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>

      {isDividerAppear && <ViewDivider label="View All" onClick={handleOpenViewAll} />}
    </>
  );
};
