import styled from '@emotion/styled';
import { breakpoints, colors, mediaQuery } from '../../theme';
import { Button } from '../../components/button';
import { Chip } from '../../components/chip';
import { ReactComponent as Logo } from '../../assets/svg/game_logo_lol.svg';

export const PlayerRankWrapper = styled.div`
  max-width: 688px;
  margin: 20rem auto 0;

  ${mediaQuery(breakpoints.mobile)} {
    margin: 15rem auto 0;
  }
`;

export const EventDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${mediaQuery(breakpoints.mobile)} {
    flex-direction: column;
  }
`;

export const EventDetailLogo = styled.div`
  background: ${colors.black};
  width: 8.8rem;
  height: 8.8rem;
  display: flex;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  ${mediaQuery(breakpoints.mobile)} {
    width: 6.4rem;
    height: 6.4rem;
  }
`;

export const LolLogo = styled(Logo)`
  ${mediaQuery(breakpoints.mobile)} {
    width: 38px;
    height: 38px;
  }
`;

export const EventContent = styled.div`
  margin-left: 3.2rem;
  width: 100%;

  ${mediaQuery(breakpoints.mobile)} {
    margin-left: 0;
    margin-top: 1.6rem;
  }
`;

export const EventProgress = styled.div`
  margin-top: 1.4rem;
  display: flex;
  align-items: center;
`;

export const StyledChip = styled(Chip)`
  margin-right: 0.8rem;
`;

export const EventPrice = styled.div`
  background: ${colors.black};
  border-radius: 20px;
  padding: 1.2rem;
  margin-left: 3.2rem;

  ${mediaQuery(breakpoints.mobile)} {
    margin-left: 0;
    margin-top: 2.6rem;
  }
`;

export const EventPriceTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.2rem;
`;

export const EventPriceButtons = styled.div`
  display: flex;

  ${mediaQuery(breakpoints.mobile)} {
    justify-content: center;
  }
`;

export const EventPriceButton = styled(Button)`
  margin-right: 0.8rem;

  &:last-of-type {
    margin-right: 0;
  }

  ${mediaQuery(breakpoints.mobile)} {
    width: 100%;
  }
`;

export const ShareEventWrapper = styled.div`
  margin-top: 7rem;
  ${mediaQuery(breakpoints.mobile)} {
    margin-top: 5rem;
  }
`;

export const ShareEventLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mediaQuery(breakpoints.mobile)} {
    flex-wrap: wrap;
  }
`;

export const CloseIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const EventDetailTitleMobile = styled.div`
  display: flex;
  align-items: center;
`;
