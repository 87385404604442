import React from 'react';
import { TableRow as MaterialTableRow, TableRowProps as MaterialTableRowProps } from '@mui/material';
import styled from '@emotion/styled';
import { colors } from '../../theme';

type TableRowProps = {
  children: React.ReactNode;
  selectedsecond?: string;
} & MaterialTableRowProps;

const StyledTableRow = styled(MaterialTableRow)<TableRowProps>`
  background: ${(props) => (props.selectedsecond === 'true' ? colors.transparentLight05 : '')};

  & td {
    transform: scale(1);
  }

  &:last-of-type {
    & td {
      border-bottom: none;
    }
  }

  & td:first-of-type {
    position: relative;
    
    &:after {
    ...${(props) =>
      props.selectedsecond === 'true'
        ? {
            display: 'block',
            position: 'absolute',
            top: '-1px',
            left: '-10px',
            content: "''",
            width: '10px',
            height: 'calc(100% + 2px)',
            background: colors.transparentLight05,
            borderRadius: '12px 0 0 12px',
          }
        : {}},
    }
  }

  & td:last-of-type {
    position: relative;

    &:after {
      ...${(props) =>
        props.selectedsecond === 'true'
          ? {
              display: 'block',
              position: 'absolute',
              top: '-1px',
              right: '-10px',
              content: "''",
              width: '10px',
              height: 'calc(100% + 2px)',
              background: colors.transparentLight05,
              borderRadius: '0 12px 12px 0',
            }
          : {}},
    }
  }

  &.Mui-selected {
    background: ${colors.primary};

    & td,
    p,
    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    svg {
      color: ${colors.black};
      fill: ${colors.black};
    }

    & td:first-of-type {
      position: relative;
      
      &:after {
        display: block;
        position: absolute;
        top:0;
        left:-10px;
        content: '';
        width:10px;
        height: 100%;
        background: ${colors.primary};
        border-radius: 12px 0 0 12px;
      }
    }

    & td:last-of-type {
      position: relative;

      &:after {
        display: block;
        position: absolute;
        top:0;
        right:-10px;
        content: '';
        width:10px;
        height: 100%;
        background: ${colors.primary};
        border-radius: 0 12px 12px 0;
      }
    }
  }

  &.Mui-selected:hover {
    background: ${colors.primary} !important;
  }
`;

export const TableRow = ({ children, ...rest }: TableRowProps) => {
  return <StyledTableRow {...rest}>{children}</StyledTableRow>;
};
