import { useMutation } from 'react-query';
import * as connectService from '../../services/connectService';

export const useRedirectToRiotLogin = () => {
  return useMutation(connectService.redirectToRiotLogin, {
    onSuccess: ({ redirectRiot }) => {
      window.open(redirectRiot);
    },
  });
};
