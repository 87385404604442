import axios from 'axios';
import { StorageKeys } from '../constants/storageKeys';

const defaultOptions = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem(StorageKeys.JWT_TOKEN)}`,
      'Access-Control-Allow-Origin': '*',
    },
  },
};

const httpClient = axios.create(defaultOptions);

httpClient.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const setAxiosToken = (token: string | null) => {
  if (token) {
    httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete httpClient.defaults.headers.common['Authorization'];
  }
};

export default httpClient;
