import { Typography } from '../../../components/typography';
import { colors } from '../../../theme';
import { Container } from '../../../components/container';
import { EventBoardsWrapper } from './EventLeaderboardStyled';
import { EventLeaderboardTable } from './EventLeaderboardTable';

export const EventLeaderboard = () => {
  return (
    <EventBoardsWrapper>
      <Container>
        <Typography variant="h6" mt={6.4} mb={2.4} color={colors.white}>
          Leaderboard
        </Typography>
      </Container>

      <EventLeaderboardTable />
    </EventBoardsWrapper>
  );
};
