import { InputWrapper, LoginTitle, LoginWrapper } from './LoginStyled';
import { Input } from '../../components/input';
import { Button } from '../../components/button';
import { useLoginForm } from './useLoginForm';
import { Typography } from '../../components/typography';
import { colors } from '../../theme';
import Routes from '../../constants/routes';
import { Link } from '../../components/link';

export const Login = () => {
  const { values, touched, errors, errorMessage, handleChange, handleSubmit } = useLoginForm();

  return (
    <LoginWrapper onSubmit={handleSubmit}>
      <LoginTitle variant="h6">Log in</LoginTitle>

      <Input
        name="email"
        label="Email"
        color="secondary"
        value={values.email}
        error={Boolean(touched.email && errors.email)}
        helperText={touched.email && errors.email}
        onChange={handleChange}
        placeholder="Email"
        fullWidth
        autoComplete="off"
        focused={Boolean(values.email)}
      />
      <InputWrapper>
        <Input
          name="password"
          label="Password"
          color="secondary"
          value={values.password}
          error={Boolean(touched.password && errors.password)}
          helperText={touched.password && errors.password}
          onChange={handleChange}
          placeholder="Password"
          type="password"
          fullWidth
          focused={Boolean(values.password)}
        />
      </InputWrapper>

      {errorMessage && (
        <Typography mt={-1} mb={1.6} variant="subtitle3" color={colors.accentRed}>
          {errorMessage}
        </Typography>
      )}

      <Button type="submit" variant="contained" size="medium" fullWidth>
        Log in
      </Button>

      <Typography mt={1.6} variant="subtitle2" color={colors.transparentLight50}>
        Trouble signing in? <Link to={Routes.RESET_PASSWORD}>Reset Password</Link>
      </Typography>
    </LoginWrapper>
  );
};
