import React from 'react';
import { Button as MaterialButton, ButtonProps as MaterialButtonProps } from '@mui/material';

interface ButtonProps extends MaterialButtonProps {
  children?: React.ReactNode;
}

export const Button = ({ children, ...rest }: ButtonProps) => {
  return <MaterialButton {...rest}>{children}</MaterialButton>;
};
