import styled from '@emotion/styled';
import { Input } from '../../components/input';
import { Button } from '../../components/button';
import { breakpoints, mediaQuery } from '../../theme';

export const ProfileContentWrapper = styled.form`
  padding: 2.4rem 2.4rem 4rem 2.4rem;
  width: 512px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  ${mediaQuery(breakpoints.mobile)} {
    max-width: 512px;
    width: 100%;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ProfileContent = styled.div`
  padding: 0 2.4rem;

  ${mediaQuery(breakpoints.mobile)} {
    padding: 0 0.8rem;
  }
`;

export const StyledInput = styled(Input)`
  margin-bottom: 1.6rem;
`;

export const FullNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.6rem;
`;

export const ConnectionWrapper = styled.div`
  margin-top: 3.4rem;
`;

export const ConnectionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.6rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const ConnectionType = styled.div`
  display: flex;

  ${mediaQuery(breakpoints.mobile)} {
    width: 100%;
  }
`;

export const ConnectionTexts = styled.div`
  margin-left: 1.6rem;

  ${mediaQuery(breakpoints.mobile)} {
    width: 100%;
  }
`;

export const ButtonsWrapper = styled.div`
  margin-top: 3.2rem;
`;

export const ConfirmButton = styled(Button)`
  margin-bottom: 1.6rem;
`;

export const ConnectionButton = styled(Button)`
  ${mediaQuery(breakpoints.mobile)} {
    width: 70%;
    margin-top: 0.8rem;
  }
`;
