import { ShareEventLinkWrapper, ShareEventWrapper } from '../EventStyled';
import { Container } from '../../../components/container';
import { Typography } from '../../../components/typography';
import { colors } from '../../../theme';
import { CopyToClipboard } from '../../../components/copyToClipboard';
import { EventState } from '../EventState';

export const ShareEventLink = () => {
  const { shareLink } = EventState.useContainer();

  return (
    <ShareEventWrapper>
      <Container>
        <Typography variant="h6" color={colors.white} textTransform="capitalize" mb={1.3}>
          Share event link
        </Typography>

        <ShareEventLinkWrapper>
          <Typography variant="body6" color={colors.transparentLight50}>
            Share this link with your community so they can sign up and join your Nemesis event
          </Typography>

          <CopyToClipboard copyText={shareLink} />
        </ShareEventLinkWrapper>
      </Container>
    </ShareEventWrapper>
  );
};
