import styled from '@emotion/styled';
import { breakpoints, colors, mediaQuery } from '../../theme';
import { Typography } from '../../components/typography';
import avatarBg from '../../assets/images/avatar-bg.png';

export const PlayerRankWrapper = styled.div`
  background: ${colors.transparentDark50};
  border-radius: 22px 22px 0 0;
  padding: 3.2rem 3.2rem 2.4rem 3.2rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;

  &:before {
    content: '';
    position: absolute;
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 22px 22px 0 0;
    z-index: -1;

    ${mediaQuery(breakpoints.mobile)} {
      backdrop-filter: unset;
    }
  }

  ${mediaQuery(breakpoints.mobile)} {
    flex-direction: column;
    backdrop-filter: blur(10px);
  }
`;

export const PlayerRankColumn = styled.div`
  width: 100%;
`;

export const RankContainer = styled(PlayerRankColumn)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const NemesisRankText = styled(Typography)`
  color: ${colors.primary};
  text-transform: uppercase;
`;

export const RankAvatarWrapper = styled.div`
  margin-top: -130px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${mediaQuery(breakpoints.mobile)} {
    margin-bottom: 3rem;
    margin-top: -115px;
  }
`;

export const StatsContainer = styled(PlayerRankColumn)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StatWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.6rem;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const StatItem = styled.div`
  margin-left: 1.2rem;
  width: 20%;
`;

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const AvatarImageWrapper = styled.div`
  background: url(${avatarBg}) no-repeat;
  background-size: cover;
  width: 192px;
  height: 212px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaQuery(breakpoints.mobile)} {
    width: 150px;
    height: 165px;
  }
`;

export const PlayerRankTeam = styled(Typography)`
  cursor: pointer;
`;

export const RankContainerWrapperMobile = styled.div`
  display: flex;
`;

export const NoConnectionRankContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
