import { useOutlet } from 'react-router-dom';
import { DarkOverlay } from '../LayoutStyled';
import { Container } from '../../../components/container';
import { Footer } from '../../../components/footer';
import { Button } from '../../../components/button';

import { LandingLayoutWrapper, Content, LogoWrapper, Logo, MenuBar } from './LandingLayoutStyled';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Routes from '../../../constants/routes';
import { Link } from '../../../components/link';
import { Link as ScrollLink } from 'react-scroll';

export const LandingLayout = () => {
  const content = useOutlet();

  return (
    <LandingLayoutWrapper>
      <DarkOverlay />

      <AppBar sx={{ background: 'none', display: 'block' }} position="relative">
        <Container>
          <Toolbar disableGutters>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>

            <MenuBar>
              <ScrollLink to="who-are-we" smooth={true}>
                <Button sx={{ color: 'white' }}>Who are we</Button>
              </ScrollLink>
              <ScrollLink to="creator-showcase" smooth={true}>
                <Button sx={{ color: 'white' }}>Showcase</Button>
              </ScrollLink>
              <ScrollLink to="partners" smooth={true}>
                <Button sx={{ color: 'white' }}>Partners</Button>
              </ScrollLink>

              <Link to={Routes.LOGIN}>
                <Button sx={{ ml: '3rem' }} type="submit" variant="contained">
                  Log in
                </Button>
              </Link>
            </MenuBar>
          </Toolbar>
        </Container>
      </AppBar>

      <Content>{content}</Content>

      <Container>
        <Footer />
      </Container>
    </LandingLayoutWrapper>
  );
};
