import { useMutation } from 'react-query';
import * as authService from '../../services/authService';
import { Auth } from '../../context/auth';

export const useLoginUser = () => {
  const { setAuthToken } = Auth.useContainer();

  return useMutation(authService.loginUser, {
    onSuccess: ({ access_token }) => {
      setAuthToken(access_token);
    },
  });
};
