import { useQuery } from 'react-query';
import * as eventService from '../../services/eventService';
import QueryKeys from '../../constants/queryKeys';

export interface GetEventUserParams {
  eventId: number;
  enabled?: boolean;
}

export const useGetEventUser = ({ eventId, enabled = true }: GetEventUserParams) => {
  return useQuery({
    queryKey: [QueryKeys.EventUser, eventId],
    queryFn: () => eventService.fetchEventUser(eventId),
    enabled,
  });
};
