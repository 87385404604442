import { useQuery } from 'react-query';
import * as communityService from '../../services/communityService';
import QueryKeys from '../../constants/queryKeys';

interface GetCommunityParams {
  enabled?: boolean;
}

export const useGetCommunities = ({ enabled = true }: GetCommunityParams) => {
  return useQuery({
    queryKey: QueryKeys.Community,
    queryFn: communityService.getCommunities,
    enabled,
  });
};
