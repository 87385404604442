import { PlayerRank } from '../../molecules/playerRank';
import { PlayerRankWrapper } from './EventStyled';
import { EventLeaderboard } from './eventLeaderboard';
import { EventParticipants } from './eventParticipants';
import { EventDetail } from './eventDetail';
import { ShareEventLink } from './shareEventLink';
import { EventState } from './EventState';
import { CircularProgress } from '../../components/circularProgress';
import { Container } from '../../components/container';
import { LoaderContainer } from '../../molecules/loaderContainer';
import { AccountConnect } from '../../molecules/accountConnect';
import { MainLayoutState } from '../../molecules/layout/mainLayout/MainLayoutState';
import { ToastMessage } from '../../molecules/toastMessage';
import { GameConnect } from '../../context/gameConnect';
import { TeamAssignedMessage } from '../../molecules/teamAssignedMessage';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Routes from '../../constants/routes';

const Event = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { isEventStarted, isLoadingParticipants, isLoadingLeaderboardTeams, isLoadingCommunity, event } = EventState.useContainer();
  const { isConnectModalOpen, handleConnectModalClose } = MainLayoutState.useContainer();
  const { isConnectedToastOpen, setIsConnectedToastOpen, isYourTeamMessageOpen, setIsYourTeamMessageOpen } = GameConnect.useContainer();

  const isLoading = isLoadingParticipants || isLoadingLeaderboardTeams || isLoadingCommunity;

  useEffect(() => {
    if (searchParams.get('riotConnect') === 'success') {
      setIsConnectedToastOpen(true);
      navigate(Routes.EVENTS);
    }
  }, [searchParams, setIsConnectedToastOpen, navigate]);

  return (
    <>
      <Container>
        <PlayerRankWrapper>
          <PlayerRank />
        </PlayerRankWrapper>
      </Container>

      <EventDetail />

      {isLoading ? (
        <Container>
          <LoaderContainer>
            <CircularProgress />
          </LoaderContainer>
        </Container>
      ) : (
        <>
          <ShareEventLink />
          {isEventStarted ? <EventLeaderboard /> : <EventParticipants />}
        </>
      )}

      <AccountConnect isOpen={isConnectModalOpen} onClose={() => handleConnectModalClose()} eventId={event?.id} />
      <TeamAssignedMessage isOpen={isYourTeamMessageOpen} onClose={() => setIsYourTeamMessageOpen(false)} />

      <ToastMessage
        isOpen={isConnectedToastOpen}
        onClose={() => setIsConnectedToastOpen(false)}
        title="Game connection successful"
        subTitle="Your League of Legends game connection has been successful. Start playing to rank up!"
        variant="primary"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
      />
    </>
  );
};

export const EventContainer = () => {
  return (
    <EventState.Provider>
      <Event />
    </EventState.Provider>
  );
};
