import { PerformersContainer, PerformersWrapper, TopDailyPerformersWrapper } from './TopDailyPerformersStyled';
import { Container } from '../../../components/container';
import { Typography } from '../../../components/typography';
import { colors } from '../../../theme';
import { PlayerCard } from '../../../molecules/playerCard';
import { TeamState } from '../TeamState';

export const TopDailyPerformers = () => {
  const { teamInfo } = TeamState.useContainer();
  const performers = teamInfo?.dailyPerformers;

  if (!Boolean(performers) || performers?.length === 0) return null;

  return (
    <TopDailyPerformersWrapper>
      <Container>
        <Typography variant="h6" color={colors.white} textTransform="capitalize">
          Top daily performers
        </Typography>

        <PerformersContainer>
          <PerformersWrapper>
            {performers?.map((item) => (
              <PlayerCard
                key={item.userId}
                selected={item.isMe}
                player={{
                  name: item.username,
                  avatar: item.userAvatar,
                  playingAs: item.playingAs,
                  rank: item.currentRank,
                  points: item.points,
                }}
              />
            ))}
          </PerformersWrapper>
        </PerformersContainer>
      </Container>
    </TopDailyPerformersWrapper>
  );
};
