import { useQuery } from 'react-query';
import QueryKeys from '../../constants/queryKeys';
import * as questService from '../../services/questService';

interface GetUserQuestsParams {
  enabled?: boolean;
}

export const useGetQuests = ({ enabled = true }: GetUserQuestsParams) => {
  return useQuery({
    queryKey: QueryKeys.Quests,
    queryFn: () => questService.fetchQuests(),
    enabled,
  });
};
