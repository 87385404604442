export const extractErrorMessage = (errorResponse: any) => {
  return errorResponse?.response?.data?.errorMessage;
};

export const extractErrorData = (errorResponse: any) => {
  return errorResponse?.response?.data;
};

export const getTeamImageUrl = (image: string) => {
  return `${process.env.REACT_APP_APP_RESOURCE_URL}/team-images/${image}`;
};

export const isEnvProduction = () => {
  return process.env.REACT_APP_ENV_PRODUCTION === 'true';
};

export const redirectToDiscord = () => {
  window.open('https://discord.gg/4NQPEMpuWr');
};
