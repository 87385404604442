import moment from 'moment';
import { isNaN } from 'lodash';

export const MAX_ITEMS_IN_TABLE = 4;
export const MAX_ITEMS_IN_PARTICIPANTS_TABLE = 10;
export const MAX_MEMBERS_IN_TABLE_ITEM = 6;

const STARTS_IN_DAYS_RANGE = 20;
const REMAINING_DAYS_RANGE = 80;
const TOTAL_RANGE = 100;

export const hasEventFinished = (endDate: string) => {
  return moment(endDate).isBefore(moment());
};

export const hasEventStarted = (startDate: string) => {
  return moment().isAfter(moment(startDate));
};

export const getEventProgressValue = (startDate: string, endDate: string) => {
  if (hasEventFinished(endDate)) {
    return TOTAL_RANGE;
  } else if (!hasEventStarted(startDate)) {
    return getStartsInDaysProgressValue(startDate);
  } else {
    return getRemainingDaysProgressValue(startDate, endDate);
  }
};

export const getEventProgressLabel = (startDate: string, endDate: string) => {
  let label = '';

  if (hasEventFinished(endDate)) label = 'Event Finished';
  else if (!hasEventStarted(startDate)) label = `Starts ${moment().to(moment(startDate))}`;
  else {
    label = `${moment().to(moment(endDate), true)} remaining`;
  }

  return label;
};

const getStartsInDaysProgressValue = (startDate: string) => {
  let startsInDays = moment(startDate).diff(moment(), 'days');
  startsInDays = startsInDays < 0 || isNaN(startsInDays) ? 0 : startsInDays;

  const totalDaysBeforeStart = moment(startDate).diff(moment().startOf('month'), 'days');
  let startsInDaysRatio = STARTS_IN_DAYS_RANGE - (startsInDays / totalDaysBeforeStart) * STARTS_IN_DAYS_RANGE;
  startsInDaysRatio = !isNaN(startsInDaysRatio) ? startsInDaysRatio : 0;

  return startsInDaysRatio;
};
const getRemainingDaysProgressValue = (startDate: string, endDate: string) => {
  let remainingDays = moment(endDate).diff(moment(), 'days');
  remainingDays = remainingDays < 0 ? 0 : remainingDays;

  const totalDaysRemaining = moment(endDate).diff(moment(startDate), 'days');
  let daysRemainingRatio = REMAINING_DAYS_RANGE - (remainingDays / totalDaysRemaining) * REMAINING_DAYS_RANGE;
  daysRemainingRatio = remainingDays < totalDaysRemaining && !isNaN(daysRemainingRatio) ? daysRemainingRatio : 0;

  return daysRemainingRatio + (STARTS_IN_DAYS_RANGE - 5);
};
