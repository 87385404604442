import styled from '@emotion/styled';
import { breakpoints, colors, mediaQuery } from '../../theme';

export const EventDetailsWrapper = styled.div`
  background: ${colors.primary};
  padding: 3.2rem 0;

  ${mediaQuery(breakpoints.mobile)} {
    padding: 2.4rem 0;
  }
`;
