import { colors } from '../../theme';
import { Typography } from '../../components/typography';
import { ReactComponent as ArrowUpIcon } from '../../assets/icons/arrow-up-icon.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down-icon.svg';
import { ArrowWrapper, MoveIconsWrapper } from '.';
import { LeaderboardRankProps } from './types';

export const LeaderboardRank = ({ currentRank = 0, previousRank = 0, active }: LeaderboardRankProps) => {
  const rankDifference = previousRank - currentRank;

  if (rankDifference === 0) {
    return (
      <MoveIconsWrapper>
        <Typography variant="body6" color={active ? colors.black : colors.transparentLight50}>
          -
        </Typography>
      </MoveIconsWrapper>
    );
  }

  return (
    <MoveIconsWrapper>
      <ArrowWrapper>
        {rankDifference > 0 ? (
          <ArrowUpIcon width={10} height={10} fill={active ? colors.black : colors.transparentLight50} />
        ) : (
          <ArrowDownIcon width={10} height={10} fill={active ? colors.black : colors.accentRed} />
        )}
      </ArrowWrapper>
      <Typography variant="body6" color={active ? colors.black : rankDifference > 0 ? colors.transparentLight50 : colors.accentRed}>
        {Math.abs(rankDifference)}
      </Typography>
    </MoveIconsWrapper>
  );
};
