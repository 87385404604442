import styled from '@emotion/styled';
import { breakpoints, mediaQuery } from '../../theme';

export const HomeWrapper = styled.div`
  margin-top: 12rem;
  margin-bottom: 3rem;
`;

export const CommunitiesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.6rem;

  ${mediaQuery(breakpoints.mobile)} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
