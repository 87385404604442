import styled from '@emotion/styled';
import { breakpoints, colors, mediaQuery } from '../../../../theme';

export const RulesContent = styled.div`
  padding: 2rem 2rem 4rem 2rem;
  max-width: 688px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  ${mediaQuery(breakpoints.mobile)} {
    max-width: 336px;
  }
`;

export const RulesContentElements = styled.div`
  padding: 0 2.4rem;

  ${mediaQuery(breakpoints.mobile)} {
    padding: 0 1.2rem;
  }
`;

export const RulesWrapper = styled.div`
  margin-bottom: 3.2rem;
`;

export const RuleWrapper = styled.div`
  margin-bottom: 1.6rem;
  display: flex;
  align-items: flex-start;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const SubRules = styled.div`
  margin-top: 0.8rem;
`;

export const DotWrapper = styled.div`
  margin-right: 1.6rem;
  color: ${colors.primary};
`;

export const ScoringWrapper = styled.div`
  margin-top: 3.2rem;
`;

export const ScoringRuleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  margin-left: 1.6rem;
`;

export const ScoringTitle = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 2.4rem;

  ${mediaQuery(breakpoints.mobile)} {
    flex-wrap: wrap;
  }
`;
