import styled from '@emotion/styled';
import { breakpoints, mediaQuery } from '../../../../theme';

export const PrizesContentWrapper = styled.div`
  padding: 2rem 2rem 4rem 2rem;
  width: 336px;

  ${mediaQuery(breakpoints.mobile)} {
    max-width: 100%;
  }
`;

export const PrizesContent = styled.div`
  padding: 0 2.4rem;
`;

export const PrizeItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;
