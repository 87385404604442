import Yup from '../configs/yup';

export const loginSchema = Yup.object({
  email: Yup.string().email().trim().required(),
  password: Yup.string().trim().required(),
});

export const signupSchema = Yup.object({
  communityName: Yup.string().trim().required('Community name is required field'),
  username: Yup.string().trim().required(),
  email: Yup.string().email().trim().required(),
  password: Yup.string().trim().required(),
});

export const splashSchema = Yup.object({
  email: Yup.string().email().trim().required(),
});
