import Yup from '../configs/yup';

export const profileSchema = Yup.object({
  teamName: Yup.string().trim().required('Team name is a required field'),
  username: Yup.string().trim().required(),
  email: Yup.string().email().trim().required(),
  password: Yup.string().trim(),
});

export const resetPasswordSchema = Yup.object({
  email: Yup.string().email().trim().required(),
});

export const updatePasswordSchema = Yup.object({
  password: Yup.string().trim().required(),
  rePassword: Yup.string()
    .trim()
    .required('Password is a required field')
    .oneOf([Yup.ref('password')], 'Your passwords do not match'),
});
