import {
  LeaderboardMobileContainer,
  LeaderboardMobileDetails,
  LeaderboardMobileInfo,
  LeaderboardMobileIsYouChip,
  LeaderboardMobileStats,
  LeaderboardMobileTitle,
  LeaderboardMobileWrapper,
  LeaderboardRankWrapper,
} from './LeaderboardMobileItemStyled';
import { Typography } from '../../components/typography';
import { colors } from '../../theme';
import { Avatar, LeaderboardRank } from '../leaderboard';
import { Container } from '../../components/container';
import { LeaderboardMobileItemProps } from './types';

export const LeaderboardMobileItem = ({ data, chipLabel, onClick }: LeaderboardMobileItemProps) => {
  const { rowNumber, primaryActive, secondaryActive, avatar, title, subtitle, previousRank, currentRank, kills, assists, points } = data;

  return (
    <LeaderboardMobileWrapper onClick={onClick} primaryActive={primaryActive} secondaryActive={secondaryActive}>
      {secondaryActive && chipLabel && <LeaderboardMobileIsYouChip label={chipLabel} variant="filled" size="small" color="primary" />}
      <Container>
        <LeaderboardMobileContainer>
          <Typography mr={3} variant="body1" color={primaryActive ? colors.black : colors.primary}>
            {rowNumber}
          </Typography>

          <LeaderboardMobileInfo primaryActive={primaryActive} secondaryActive={secondaryActive}>
            <LeaderboardMobileDetails>
              <Avatar src={avatar} alt="avatar-image" />
              <LeaderboardMobileTitle>
                <Typography variant="body3" color={primaryActive ? colors.black : colors.white}>
                  {title}
                </Typography>
                <Typography variant="subtitle2" color={primaryActive ? colors.black : colors.transparentLight50}>
                  {subtitle}
                </Typography>
              </LeaderboardMobileTitle>
            </LeaderboardMobileDetails>

            <LeaderboardMobileStats>
              <LeaderboardRankWrapper>
                <LeaderboardRank previousRank={previousRank} currentRank={currentRank} active={primaryActive} />
              </LeaderboardRankWrapper>

              <Typography variant="subtitle2" color={primaryActive ? colors.black : colors.transparentLight50}>
                K:{kills ?? '-'}
              </Typography>
              <Typography variant="subtitle2" color={primaryActive ? colors.black : colors.transparentLight50}>
                A:{assists ?? '-'}
              </Typography>
              <Typography variant="subtitle1" color={primaryActive ? colors.black : colors.white}>
                P:{points ?? '-'}
              </Typography>
            </LeaderboardMobileStats>
          </LeaderboardMobileInfo>
        </LeaderboardMobileContainer>
      </Container>
    </LeaderboardMobileWrapper>
  );
};
