import React from 'react';
import styled from '@emotion/styled';

interface LoaderWrapperProps {
  children: React.ReactNode;
  height?: number;
}

const LoaderWrapper = styled.div<LoaderWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.height ? props.height : 300)}px;
`;

export const LoaderContainer = ({ children, height }: LoaderWrapperProps) => {
  return (
    <LoaderWrapper height={height}>
      <>{children}</>
    </LoaderWrapper>
  );
};
