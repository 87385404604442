import httpClient from '../../configs/axios';
import { Quest, ClaimQuestRewardData } from './types';

export const fetchQuests = (): Promise<Quest[]> => {
  return httpClient.get('/quests');
};

export const claimQuestReward = (data: ClaimQuestRewardData) => {
  const { questName } = data;
  return httpClient.post(`/quests/claim/${questName}`);
};
