import { createContainer } from 'unstated-next';
import { useGetEventTeamDetails } from '../../queries/team/useGetEventTeamDetails';
import { useParams } from 'react-router-dom';
import { useGetEvents } from '../../queries/event/useGetEvents';
import { useGetTeamPlayers } from '../../queries/team/useGetTeamPlayers';

const useTeamState = () => {
  const { eventId, teamId } = useParams();
  const { data: teamInfo, isLoading: isLoadingTeamInfo } = useGetEventTeamDetails({ eventId: Number(eventId), teamId: Number(teamId) });
  const { data: events, isLoading: isLoadingEvents } = useGetEvents();
  const { data: players, isLoading: isLoadingPlayers } = useGetTeamPlayers({ eventId: Number(eventId), teamId: Number(teamId) });

  return {
    teamInfo,
    events,
    players,
    isLoadingTeamInfo,
    isLoadingEvents,
    isLoadingPlayers,
  };
};

export const TeamState = createContainer(useTeamState);
