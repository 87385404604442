enum Routes {
  ROOT = '/',
  HOME = '/home',
  LOGIN = '/login',
  CREATOR_SIGNUP = '/signup/creator/:creatorCode',
  INVITATION_SIGNUP = '/signup/invite/:invitationCode',
  EVENTS = '/events',
  TERMS_OF_SERVICE = '/terms-of-service',
  PRIVACY_POLICY = '/privacy-policy',
  TEAM = '/events/:eventId/teams/:teamId',
  RESET_PASSWORD = '/reset-password',
  UPDATE_PASSWORD = '/update-password/:code',
  COMMUNITY = '/community/:communityId',
}

export default Routes;
