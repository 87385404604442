import styled from '@emotion/styled';
import { breakpoints, mediaQuery } from '../../theme';
import { ReactComponent as Logo } from '../../assets/svg/nemesis_logo.svg';

export const DarkOverlay = styled.div`
  background: radial-gradient(65.13% 65.13% at 50% 34.87%, rgba(0, 0, 0, 0) 0%, #000000 100%);
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const NemessisLogo = styled(Logo)`
  ${mediaQuery(breakpoints.mobile)} {
    width: 200px;
  }
`;
