import styled from '@emotion/styled';
import { DialogContentWrapper } from '../dialog';
import { LinearProgress } from '../../components/linearProgress';
import { colors } from '../../theme';
import { Typography } from '../../components/typography';

export const ChallengesContentWrapper = styled(DialogContentWrapper)`
  max-width: 512px;
`;

export const ChallengesHeadline = styled.div`
  margin-top: 0.4rem;
  text-align: center;
`;

export const ChallengesItemWrapper = styled.div`
  margin-top: 3.2rem;
`;

export const ChallengesItem = styled.div`
  display: flex;
  margin-bottom: 3.2rem;
`;

export const ChallengeIcon = styled.div`
  margin-right: 0.8rem;
`;

export const ChallengeName = styled.div`
  width: 100%;
`;

export const ChallengesProgress = styled(LinearProgress)`
  padding: 0;
  background: ${colors.transparentLight05};

  & .MuiLinearProgress-bar {
    background: ${colors.primary};
  }
`;

export const ChallengePoints = styled.div`
  margin-left: 0.8rem;
`;

export const ChallengePointAndIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.4rem;
`;

export const ChallengePoint = styled(Typography)`
  line-height: 1;
`;
