import React from 'react';
import { Dialog } from '../../../../components/dialog';
import { colors } from '../../../../theme';
import { CloseIconWrapper } from '../../EventStyled';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/x-icon.svg';
import {
  DotWrapper,
  RulesContent,
  RulesContentElements,
  RulesWrapper,
  RuleWrapper,
  ScoringRuleWrapper,
  ScoringTitle,
  ScoringWrapper,
  SubRules,
} from './RulesStyled';
import { Typography } from '../../../../components/typography';
import { Divider } from '../../../../components/divider';
import { ReactComponent as KillIcon } from '../../../../assets/icons/kill-icon.svg';
import { ReactComponent as AssistsIcon } from '../../../../assets/icons/assists-icon.svg';
import { rules } from './helpers';
import { RulesProps } from './types';

export const Rules = ({ isOpen, onClose }: RulesProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <RulesContent>
        <CloseIconWrapper>
          <CloseIcon cursor="pointer" fill={colors.transparentLight50} width={16} height={16} onClick={onClose} />
        </CloseIconWrapper>

        <RulesContentElements>
          <Typography textTransform="capitalize" mb={3.2} color={colors.white} variant="h5" textAlign="center">
            Rules
          </Typography>

          <Typography mb={2.4} color={colors.primary} variant="h7">
            How to Play
          </Typography>

          <RulesWrapper>
            {rules.map((rule, ruleIndex) => {
              return (
                <RuleWrapper key={ruleIndex}>
                  <Typography mr={1.6} color={colors.primary} variant="body3">
                    {ruleIndex + 1}
                  </Typography>

                  <Typography color={colors.white} variant="body4">
                    {rule.text}

                    <SubRules>
                      {rule.subText &&
                        rule.subText.map((subRule, subRuleIndex) => {
                          return (
                            <Typography display="flex" key={subRuleIndex} color={colors.white} variant="body4">
                              <DotWrapper>&#x2022;</DotWrapper> {subRule.text}
                            </Typography>
                          );
                        })}
                    </SubRules>
                  </Typography>
                </RuleWrapper>
              );
            })}
          </RulesWrapper>

          <Divider />

          <ScoringWrapper>
            <ScoringTitle>
              <Typography mr={0.8} color={colors.primary} variant="h7">
                Scoring
              </Typography>
              <Typography color={colors.transparentLight50} variant="body6">
                Custom games will not count for scoring (games must be matchmade)
              </Typography>
            </ScoringTitle>

            <Typography mb={0.8} display="flex" color={colors.white} variant="body3">
              <DotWrapper>&#x2022;</DotWrapper> ARAM & URF Games
            </Typography>

            <ScoringRuleWrapper>
              <KillIcon fill={colors.primary} width={16} height={16} />
              <Typography ml={1.6} color={colors.white} variant="body4">
                Kills: 2 Points
              </Typography>
            </ScoringRuleWrapper>

            <ScoringRuleWrapper>
              <AssistsIcon fill={colors.primary} width={16} height={16} />
              <Typography ml={1.6} color={colors.white} variant="body4">
                Assists: 1 Point
              </Typography>
            </ScoringRuleWrapper>

            <Typography mt={1.6} mb={0.8} display="flex" color={colors.white} variant="body3">
              <DotWrapper>&#x2022;</DotWrapper> Summoner's Rift
            </Typography>

            <ScoringRuleWrapper>
              <KillIcon fill={colors.primary} width={16} height={16} />
              <Typography ml={1.6} color={colors.white} variant="body4">
                Kills: 2 Points
              </Typography>
            </ScoringRuleWrapper>

            <ScoringRuleWrapper>
              <AssistsIcon fill={colors.primary} width={16} height={16} />
              <Typography ml={1.6} color={colors.white} variant="body4">
                Assists: 1 Point
              </Typography>
            </ScoringRuleWrapper>
          </ScoringWrapper>
        </RulesContentElements>
      </RulesContent>
    </Dialog>
  );
};
