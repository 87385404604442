import { Dialog } from '../../components/dialog';
import { GameRewardsProps } from './types';
import { DialogCloseIcon, DialogContent } from '../dialog';
import { breakpoints, colors } from '../../theme';
import { ReactComponent as CloseIcon } from '../../assets/icons/x-icon.svg';
import { ReactComponent as ChestIcon } from '../../assets/icons/chest-icon.svg';
import {
  GameRewardsContentWrapper,
  GameRewardsHeadline,
  LogoWrapper,
  LoLLogoStyled,
  MobileRewardWrapper,
  Reward,
  RewardDetails,
  RewardItem,
  RewardPoint,
  RewardWrapper,
} from './GameRewardsStyled';
import { Typography } from '../../components/typography';
import { Divider } from '../../components/divider';
import { ReactComponent as CountIcon } from '../../assets/icons/coin-icon.svg';
import { ReactComponent as PointsIcon } from '../../assets/icons/points-icon.svg';
import { Button } from '../../components/button';
import { MainLayoutState } from '../layout/mainLayout/MainLayoutState';
import { useClaimUserPoints } from '../../queries/user/useClaimUserPoints';
import { useMediaQuery } from '../../hooks/useMediaQuery';

export const GameRewards = ({ isOpen, onClose }: GameRewardsProps) => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { userPoints, refetchUserPoints } = MainLayoutState.useContainer();
  const { mutate: claimUserPoints } = useClaimUserPoints();

  const handleCollectRewards = () => {
    claimUserPoints(undefined, {
      onSuccess: () => {
        onClose();
        refetchUserPoints();
      },
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <GameRewardsContentWrapper>
        <DialogCloseIcon>
          <CloseIcon cursor="pointer" fill={colors.transparentLight50} width={16} height={16} onClick={onClose} />
        </DialogCloseIcon>
        <DialogContent>
          <GameRewardsHeadline>
            <ChestIcon width={64} height={64} fill={colors.primary} />
            <Typography mt={1.8} mb={3.2} variant="h5" color={colors.white}>
              Game Rewards
            </Typography>
            <Divider />
          </GameRewardsHeadline>

          <Typography mt={3.2} variant="body4" color={colors.transparentLight50}>
            Receive rewards by completing games. Every game you play you get a chest that you open to claim your gold and point rewards.
          </Typography>

          {isMobile ? (
            <>
              <MobileRewardWrapper>
                <LogoWrapper>
                  <LoLLogoStyled fill={colors.black} width={51} height={51} />
                </LogoWrapper>

                <Typography ml={1.6} variant="body1" color={colors.white}>
                  {`Completed ${userPoints?.unclaimed.completedGames} games of League of Legends`}
                </Typography>
              </MobileRewardWrapper>

              <Divider />

              <Reward>
                <RewardItem>
                  <CountIcon width={36} height={36} fill={colors.primary} />
                  <RewardPoint>
                    <Typography variant="subtitle3" color={colors.transparentLight50} textTransform="uppercase">
                      Gold rewards
                    </Typography>
                    <Typography variant="h6" color={colors.primary}>
                      {`+${userPoints?.unclaimed.goldRewards}`}
                    </Typography>
                  </RewardPoint>
                </RewardItem>
                <RewardItem>
                  <PointsIcon width={36} height={36} fill={colors.primary} />
                  <RewardPoint>
                    <Typography variant="subtitle3" color={colors.transparentLight50} textTransform="uppercase">
                      Point rewards
                    </Typography>
                    <Typography variant="h6" color={colors.primary}>
                      {`+${userPoints?.unclaimed.gamePoints}`}
                    </Typography>
                  </RewardPoint>
                </RewardItem>
              </Reward>
            </>
          ) : (
            <RewardWrapper>
              <LogoWrapper>
                <LoLLogoStyled fill={colors.black} width={51} height={51} />
              </LogoWrapper>

              <RewardDetails>
                <Typography mb={0.8} variant="body1" color={colors.white}>
                  {`Completed ${userPoints?.unclaimed.completedGames} games of LoL`}
                </Typography>

                <Reward>
                  <RewardItem>
                    <CountIcon width={36} height={36} fill={colors.primary} />
                    <RewardPoint>
                      <Typography variant="subtitle3" color={colors.transparentLight50} textTransform="uppercase">
                        Gold rewards
                      </Typography>
                      <Typography variant="h6" color={colors.primary}>
                        {`+${userPoints?.unclaimed.goldRewards}`}
                      </Typography>
                    </RewardPoint>
                  </RewardItem>
                  <RewardItem>
                    <PointsIcon width={36} height={36} fill={colors.primary} />
                    <RewardPoint>
                      <Typography variant="subtitle3" color={colors.transparentLight50} textTransform="uppercase">
                        Point rewards
                      </Typography>
                      <Typography variant="h6" color={colors.primary}>
                        {`+${userPoints?.unclaimed.gamePoints}`}
                      </Typography>
                    </RewardPoint>
                  </RewardItem>
                </Reward>
              </RewardDetails>
            </RewardWrapper>
          )}

          <Button variant="contained" color="primary" size="medium" fullWidth onClick={handleCollectRewards}>
            Collect Rewards
          </Button>
        </DialogContent>
      </GameRewardsContentWrapper>
    </Dialog>
  );
};
