import React from 'react';
import { LinkProps as RouterLinkProps } from 'react-router-dom';
import { RouterLink } from './LinkStyled';

interface LinkProps extends RouterLinkProps {
  children: React.ReactNode;
}

export const Link = ({ children, ...rest }: LinkProps) => {
  return <RouterLink {...rest}>{children}</RouterLink>;
};
