import styled from '@emotion/styled';
import backgroundImage from '../../../assets/images/bg_signin.png';
import { breakpoints, colors, mediaQuery } from '../../../theme';

export const BaseLayoutWrapper = styled.div`
  background: url(${backgroundImage}) no-repeat center top;
  width: 100%;
  height: 720px;
  position: relative;
  background-size: cover;
`;

export const Content = styled.div`
  position: relative;
  z-index: 2;
  background: ${colors.transparentDark50};
  backdrop-filter: blur(10px);
`;

export const LogoWrapper = styled.div`
  padding: 7.5rem 0;
  position: relative;
  z-index: 2;

  ${mediaQuery(breakpoints.mobile)} {
    display: flex;
    justify-content: center;
    padding: 6.4rem 0;
  }
`;
