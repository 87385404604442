import { Typography } from '../../components/typography';
import { colors } from '../../theme';
import { Input } from '../../components/input';
import { Link } from '../../components/link';
import Routes from '../../constants/routes';
import { Button } from '../../components/button';
import { useFormik } from 'formik';
import { UpdatePasswordFormValues } from './types';
import { ResetButton, StyledInput, UpdatePasswordWrapper } from './UpdatePasswordStyled';
import { useParams } from 'react-router-dom';
import { updatePasswordSchema } from '../../validation/userSchemas';
import { useUpdatePassword } from '../../queries/user/useUpdatePassword';
import { useErrorMessage } from '../../hooks/useErrorMessage';
import { capitalize } from 'lodash';

export const UpdatePassword = () => {
  const { code } = useParams();
  const { mutate: updatePassword, isSuccess } = useUpdatePassword();
  const { message: errorMessage, setMessage } = useErrorMessage();

  const { values, errors, touched, handleChange, handleSubmit } = useFormik<UpdatePasswordFormValues>({
    initialValues: {
      password: '',
      rePassword: '',
    },

    validationSchema: updatePasswordSchema,

    onSubmit: (values) => {
      if (code) {
        updatePassword(
          { code: code, password: values.password },
          {
            onError: (error: any) => {
              setMessage(error?.response?.data.message);
            },
          }
        );
      }
    },
  });

  let content: JSX.Element | null = (
    <>
      <StyledInput
        name="password"
        label="New Password"
        color="secondary"
        value={values.password}
        error={Boolean(touched.password && errors.password)}
        helperText={touched.password && errors.password}
        onChange={handleChange}
        placeholder="New Password"
        fullWidth
        autoComplete="off"
        focused={Boolean(values.password)}
        type="password"
      />

      <Input
        name="rePassword"
        label="Retype Password"
        color="secondary"
        value={values.rePassword}
        error={Boolean(touched.rePassword && errors.rePassword)}
        helperText={touched.rePassword && errors.rePassword}
        onChange={handleChange}
        placeholder="Retype Password"
        fullWidth
        autoComplete="off"
        focused={Boolean(values.rePassword)}
        type="password"
      />

      {errorMessage && (
        <Typography ml={1.4} mt={0.3} variant="subtitle3" color={colors.accentRed}>
          {capitalize(errorMessage)}
        </Typography>
      )}

      <ResetButton type="submit" variant="contained" size="medium" fullWidth>
        Reset Password
      </ResetButton>
    </>
  );

  if (isSuccess) {
    content = (
      <>
        <Typography mb={2.4} variant="body4" color={colors.transparentLight50}>
          You password successfully has changed
        </Typography>

        <Link to={Routes.LOGIN}>
          <Button variant="contained" size="medium">
            Back to Log In
          </Button>
        </Link>
      </>
    );
  }

  return (
    <UpdatePasswordWrapper onSubmit={handleSubmit}>
      <Typography mb={2.4} variant="h6" color={colors.white} textTransform="capitalize">
        Reset password
      </Typography>

      {content}

      <Typography mt={1.6} variant="subtitle2" color={colors.transparentLight50}>
        Have an account? <Link to={Routes.LOGIN}>Login</Link>
      </Typography>
    </UpdatePasswordWrapper>
  );
};
