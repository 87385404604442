import styled from '@emotion/styled';
import { Collapse } from '@mui/material';
import { colors } from '../../theme';
import { ReactComponent as Twitch } from '../../assets/svg/twitch-icon.svg';
import landingBg from '../../assets/images/landing/landing_bg.png';

export const LandingWrapper = styled.div`
  background: ${colors.gray};
`;

export const TaglineWrapper = styled(Collapse)`
  max-width: 1072px;
  margin: 9rem auto;
`;

export const Tagline = styled.div`
  text-transform: uppercase;
  margin: 9rem 0;
`;

export const ToolsWrapper = styled.div`
  margin: 9rem auto;
`;

export const TaglineDescription = styled.div`
  margin-bottom: 3rem;
  font-size: 1.8rem;
  color: ${colors.white};
  font-weight: 450;
`;

export const LoginWrapper = styled.div`
  display: inline-flex;
`;

export const HeroSection = styled.div`
  background: url(${landingBg}) no-repeat center top;
  display: flex;
  min-height: 700px;
  margin: 0 auto;
  padding: 0 2.4rem;
`;

export const CreatorSection = styled.div`
  text-align: center;
  max-width: 1072px;
  margin: 0 auto 9rem;
  padding: 0 2.4rem;
`;

export const ToolsSection = styled.div`
  max-width: 1072px;
  margin: 0 auto 9rem;
  padding: 0 2.4rem;
`;

export const CreatorShowcase = styled.div`
  justify-content: space-between;
  display: flow-root;
`;

export const TrimSection = styled.div`
  background: ${colors.primary};
  min-height: 240px;
  padding: 1rem 0;
  margin: 3rem 0;
`;

export const TrimContent = styled.div`
  display: flex;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 2.4rem;
`;

export const StatsDescription = styled.div`
  max-width: 640px;
  margin: 3rem auto;
  padding: 0 2.4rem;
`;

export const StatsContent = styled.div`
  min-width: 300px;
  max-width: 400px;
  margin: 0 3rem;
  padding: 0 2.4rem;
`;

export const CreatorDescription = styled.div`
  text-align: center;
  max-width: 720px;
  margin: 0 auto;
  padding: 0 2.4rem;
`;

export const TwitchIcon = styled(Twitch)`
  width: 20px;
  height: 20px;
`;

export const LogosWrapper = styled.div`
  min-width: 500px;
  max-width: 1500px;
  margin: 0 auto;
`;

export const SocMedSection = styled.div`
  max-width: 1072px;
  margin: 0 auto 9rem;
  padding: 0 2.4rem;
`;

export const SocMedDescription = styled.div`
  margin: 9rem auto 0rem;
  display: flex;
`;

export const BrandLogo = styled.div`
  margin: auto;
`;

export const BrandsSection = styled.div`
  background: ${colors.white};
  padding: 1rem 0;
  margin: 3rem 0;
`;

export const BrandsContent = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 2.4rem;
`;

export const GrowContent = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 2.4rem;
`;

export const GrowDescription = styled.div`
  margin: 3rem 0;
`;
