import { Table, TableBody, TableCell, TableHead, TableRow } from '../../../components/table';
import { breakpoints, colors } from '../../../theme';
import { Typography } from '../../../components/typography';
import { ViewDivider } from '../../../molecules/viewDivider';
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrow-up-icon.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down-icon.svg';
import { BoardEmptyState } from '../../../molecules/boardEmptyState';
import { TeamState } from '../TeamState';
import { useMemo } from 'react';
import { useToggle } from '../../../hooks/useToggle';
import { take } from 'lodash';
import { MAX_ITEMS_IN_PLAYERS_TABLE } from '../helpers';
import {
  ArrowWrapper,
  Avatar,
  Description,
  InfoTableCell,
  InfoWrapper,
  IsYouChip,
  IsYouWrapper,
  MoveIconsWrapper,
  NumberTableCell,
  PointsText,
  LeaderboardRank,
} from '../../../molecules/leaderboard';
import { Container } from '../../../components/container';
import { LeaderboardMobileItem } from '../../../molecules/leaderboardMobileItem';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

export const TeamLeaderboardTable = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { players, isLoadingPlayers } = TeamState.useContainer();
  const { isOpen: isViewAllOpen, handleOpen: handleOpenViewAll } = useToggle();

  const initialPlayers = useMemo(() => {
    return isViewAllOpen ? players : take(players, MAX_ITEMS_IN_PLAYERS_TABLE);
  }, [isViewAllOpen, players]);

  const isDividerAppear = useMemo(
    () => players && players?.length > MAX_ITEMS_IN_PLAYERS_TABLE && !isLoadingPlayers && !isViewAllOpen,
    [players, isViewAllOpen, isLoadingPlayers]
  );

  if (!Boolean(players) || players?.length === 0) {
    return (
      <Container>
        <BoardEmptyState label="Players will show up here once they join" />
      </Container>
    );
  }

  if (isMobile) {
    return (
      <>
        {initialPlayers?.map((item, index) => (
          <LeaderboardMobileItem
            key={item.userId}
            data={{
              rowNumber: index + 1,
              primaryActive: false,
              secondaryActive: item.isMe,
              avatar: item.avatar,
              title: item.username,
              subtitle: `Playing as: ${item.playingAs}`,
              previousRank: item.previousRank,
              currentRank: item.currentRank,
              kills: item.kills,
              assists: item.assists,
              points: item.points,
            }}
            chipLabel="You"
          />
        ))}

        {isDividerAppear && <ViewDivider label="View All" onClick={handleOpenViewAll} />}
      </>
    );
  }

  return (
    <Container>
      <Table>
        <TableHead>
          <TableRow>
            <NumberTableCell align="center">#</NumberTableCell>
            <TableCell align="left">Player</TableCell>
            <TableCell align="center">
              <MoveIconsWrapper>
                <ArrowWrapper>
                  <ArrowUpIcon width={10} height={10} fill={colors.transparentLight25} />
                </ArrowWrapper>

                <ArrowDownIcon width={10} height={10} fill={colors.transparentLight25} />
              </MoveIconsWrapper>
            </TableCell>
            <TableCell align="center">Kills</TableCell>
            <TableCell align="center">Assists</TableCell>
            <TableCell align="center">Points</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {initialPlayers?.map((item, rowIndex) => (
            <TableRow key={item.userId} selectedsecond={String(item.isMe)}>
              <TableCell align="center">
                <IsYouWrapper>
                  {item.isMe && <IsYouChip label="You" variant="filled" size="small" color="primary" />}
                  <Typography variant="body1" color={colors.primary}>
                    {rowIndex + 1}
                  </Typography>
                </IsYouWrapper>
              </TableCell>
              <InfoTableCell align="left">
                <InfoWrapper>
                  <Avatar src={item.avatar} alt="Avatar" />
                  <Description>
                    <Typography textTransform="capitalize" variant="body3" color={colors.white}>
                      {item.username}
                    </Typography>

                    <Typography display="flex" alignItems="center" variant="subtitle2" color={colors.transparentLight50}>
                      Playing as:
                      <Typography paragraph ml={0.4} variant="subtitle1">
                        {item.playingAs ?? '-'}
                      </Typography>
                    </Typography>
                  </Description>
                </InfoWrapper>
              </InfoTableCell>
              <TableCell align="center">
                <LeaderboardRank currentRank={item.currentRank} previousRank={item.previousRank} />
              </TableCell>
              <TableCell align="center">{item.kills ?? '-'}</TableCell>
              <TableCell align="center">{item.assists ?? '-'}</TableCell>
              <TableCell align="center">
                <PointsText>{item.points ?? '-'}</PointsText>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {isDividerAppear && <ViewDivider label="View All" onClick={handleOpenViewAll} />}
    </Container>
  );
};
