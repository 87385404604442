import { createContainer } from 'unstated-next';
import { useGetCommunityById } from '../../queries/community/useGetCommunityById';
import { useGetEventsByCommunityId } from '../../queries/event/useGetEventsByCommunityId';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import Routes from '../../constants/routes';
import { useMemo } from 'react';
import { useGetEventLeaderboard } from '../../queries/event/useGetEventLeaderboard';
import { hasEventFinished, hasEventStarted } from '../event/helpers';
import { useStep } from '../../hooks/useStep';

const UseCommunityState = () => {
  const { communityId } = useParams();
  const { data: community, isLoading: isLoadingCommunity } = useGetCommunityById({ communityId: Number(communityId) });

  const shareLink = useMemo(
    () =>
      `${process.env.REACT_APP_URL}${generatePath(Routes.INVITATION_SIGNUP, {
        invitationCode: community?.invitationCode ?? '',
      })}`,
    [community?.invitationCode]
  );

  const { data: events, isLoading: isLoadingEvents } = useGetEventsByCommunityId({
    communityId: Number(communityId),
  });
  const { current, maxSteps, previous, next } = useStep({ start: 0, maxSteps: events?.length ?? 1 });
  const currentEvent = events?.[current];

  const isEventStarted = useMemo(() => hasEventStarted(currentEvent?.startDate ?? ''), [currentEvent?.startDate]);
  const isEventFinished = useMemo(() => hasEventFinished(currentEvent?.endDate ?? ''), [currentEvent?.endDate]);

  const { data: leaderboardTeams, isLoading: isLoadingLeaderboardTeams } = useGetEventLeaderboard({
    eventId: currentEvent?.id || 0,
    enabled: isEventStarted,
  });

  const navigate = useNavigate();
  const handleGotoTeamPage = (teamId: number) => {
    navigate(generatePath(Routes.TEAM, { eventId: String(currentEvent?.id), teamId: String(teamId) }));
  };

  return {
    shareLink,
    community,
    events,
    currentEvent,
    current,
    maxSteps,
    previous,
    next,
    isEventStarted,
    isEventFinished,
    leaderboardTeams,
    isLoadingEvents,
    isLoadingCommunity,
    isLoadingLeaderboardTeams,
    handleGotoTeamPage,
  };
};

export const CommunityState = createContainer(UseCommunityState);
