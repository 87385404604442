import httpClient from '../../configs/axios';
import { Community } from './types';

export const getCommunities = (): Promise<Community[]> => {
  return httpClient.get('/communities');
};

export const getCommunityById = (communityId: number): Promise<Community> => {
  return httpClient.get(`/communities/${communityId}`);
};

export const getCommunityByEventId = (eventId: number): Promise<Community> => {
  return httpClient.get(`/communities/by_event_id/${eventId}`);
};

export const getCommunityByInvitationCode = (invitationCode: string): Promise<Community> => {
  return httpClient.get(`/communities/by_code/${invitationCode}`);
};
