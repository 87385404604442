import { ConfirmationText, InputWrapper, SignupTitle, SignupWrapper } from './SignUpStyled';
import { Input } from '../../components/input';
import { Button } from '../../components/button';
import { Link } from '../../components/link';
import Routes from '../../constants/routes';
import { useSignUpForm } from './useSignUpForm';
import { LoginLinkWrapper } from '../../molecules/layout/authLayout/AuthLayoutStyled';
import { Typography } from '../../components/typography';
import { colors } from '../../theme';

export const SignUp = () => {
  const { values, touched, errors, community, errorMessage, handleChange, handleSubmit } = useSignUpForm();

  return (
    <SignupWrapper onSubmit={handleSubmit}>
      <SignupTitle variant="h6">Sign Up</SignupTitle>

      <InputWrapper>
        <Input
          name="communityName"
          label="Your Community's Name"
          color="secondary"
          value={values.communityName}
          error={Boolean(touched.communityName && errors.communityName)}
          helperText={touched.communityName && errors.communityName}
          onChange={handleChange}
          fullWidth
          autoComplete="off"
          focused={Boolean(values.communityName)}
          disabled={Boolean(community)}
        />
      </InputWrapper>

      <InputWrapper>
        <Input
          name="username"
          label="Username"
          color="secondary"
          value={values.username}
          error={Boolean(touched.username && errors.username)}
          helperText={touched.username && errors.username}
          onChange={handleChange}
          fullWidth
          autoComplete="off"
          focused={Boolean(values.username)}
        />
      </InputWrapper>

      <InputWrapper>
        <Input
          name="email"
          label="Email"
          color="secondary"
          value={values.email}
          error={Boolean(touched.email && errors.email)}
          helperText={touched.email && errors.email}
          onChange={handleChange}
          fullWidth
          autoComplete="off"
          focused={Boolean(values.email)}
        />
      </InputWrapper>

      <InputWrapper>
        <Input
          type="password"
          name="password"
          label="Password"
          color="secondary"
          value={values.password}
          error={Boolean(touched.password && errors.password)}
          helperText={touched.password && errors.password}
          onChange={handleChange}
          fullWidth
          focused={Boolean(values.password)}
        />
      </InputWrapper>

      {errorMessage && (
        <Typography mb={0.8} variant="subtitle3" color={colors.accentRed}>
          {errorMessage}
        </Typography>
      )}

      <ConfirmationText>
        By signing up you confirm that you are 13 years of age or older, and also agree to our
        <Link to={Routes.TERMS_OF_SERVICE}>
          <Typography variant="subtitle4" mx={0.2} display="inline">
            Terms of Service
          </Typography>
        </Link>
        and
        <Link to={Routes.PRIVACY_POLICY}>
          <Typography variant="subtitle4" mx={0.2} display="inline">
            Privacy Policy.
          </Typography>
        </Link>
      </ConfirmationText>

      <Button type="submit" variant="contained" size="medium" fullWidth>
        Sign Up
      </Button>

      <LoginLinkWrapper variant="subtitle2">
        Have an account? <Link to={Routes.LOGIN}>Login</Link>
      </LoginLinkWrapper>
    </SignupWrapper>
  );
};
