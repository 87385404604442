import React from 'react';
import { TableHead as MaterialTableHead, TableHeadProps as MaterialTableHeadProps } from '@mui/material';

type TableHeadProps = {
  children: React.ReactNode;
} & MaterialTableHeadProps;

export const TableHead = ({ children, ...rest }: TableHeadProps) => {
  return <MaterialTableHead {...rest}>{children}</MaterialTableHead>;
};
