export const colors = {
  background: '#000',

  primary: '#E6FF01',
  accentRed: '#FF064D',
  accentOrange: '#FFAE00',
  accentGreen: '#3FF701',
  accentBlue: '#71F9F7',

  transparentDark05: 'rgba(0, 0, 0, 0.05)',
  transparentDark15: 'rgba(0, 0, 0, 0.15)',
  transparentDark25: 'rgba(0, 0, 0, 0.25)',
  transparentDark50: 'rgba(0, 0, 0, 0.5)',
  transparentDark80: 'rgba(0, 0, 0, 0.8)',

  transparentLight05: 'rgba(242, 248, 255, 0.05)',
  transparentLight10: 'rgba(242, 248, 255, 0.1)',
  transparentLight15: 'rgba(242, 248, 255, 0.1)',
  transparentLight25: 'rgba(242, 248, 255, 0.25)',
  transparentLight50: 'rgba(247, 251, 255, 0.5)',
  transparentLight80: 'rgba(247, 251, 255, 0.85)',

  white: '#fff',
  black: '#000',
  gray: '#222222',

  communityCard: '#232428',
  eventCard: '#232428',
};
