import { Container } from '../../../components/container';
import {
  TeamImage,
  TeamImageSkeleton,
  TeamInfoContent,
  TeamInfoContentWrapper,
  TeamInfoWrapper,
  TeamName,
  TeamSkeleton,
  TeamStatItem,
  TeamStats,
} from './TeamInfoStyled';
import { Typography } from '../../../components/typography';
import { breakpoints, colors } from '../../../theme';
import { TeamState } from '../TeamState';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

export const TeamInfo = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { teamInfo, isLoadingTeamInfo } = TeamState.useContainer();
  const team = teamInfo?.team;
  const teamStats = teamInfo?.teamStats;

  const teamStatsContent = (
    <TeamStats>
      {isLoadingTeamInfo ? (
        <TeamSkeleton height={38} width={300} />
      ) : (
        <>
          <TeamStatItem>
            <Typography variant="subtitle3" textTransform="uppercase" color={colors.white}>
              Team rank
            </Typography>
            <Typography variant="h4" textTransform="capitalize" color={colors.white}>
              {teamStats?.currentRank ?? '-'}
            </Typography>
          </TeamStatItem>
          <TeamStatItem>
            <Typography variant="subtitle3" textTransform="uppercase" color={colors.white}>
              Points
            </Typography>
            <Typography variant="h4" textTransform="capitalize" color={colors.white}>
              {teamStats?.points ?? '-'}
            </Typography>
          </TeamStatItem>
          <TeamStatItem>
            <Typography variant="subtitle3" textTransform="uppercase" color={colors.white}>
              Players
            </Typography>
            <Typography variant="h4" textTransform="capitalize" color={colors.white}>
              {teamStats?.teamTotalUsers ?? '-'}
            </Typography>
          </TeamStatItem>
        </>
      )}
    </TeamStats>
  );

  return (
    <TeamInfoWrapper>
      <Container>
        <TeamInfoContentWrapper>
          {isLoadingTeamInfo ? <TeamImageSkeleton /> : <TeamImage src={team?.avatarPath} alt="teamAvatar" />}

          <TeamInfoContent>
            <TeamName>
              <Typography variant="subtitle3" textTransform="uppercase" color={colors.white}>
                {isLoadingTeamInfo ? <TeamSkeleton width={100} /> : 'Team'}
              </Typography>
              <Typography mt={0.4} variant="h4" textTransform="capitalize" color={colors.white}>
                {isLoadingTeamInfo ? <TeamSkeleton width="70%" /> : team?.name}
              </Typography>
            </TeamName>

            {!isMobile && teamStatsContent}
          </TeamInfoContent>
        </TeamInfoContentWrapper>

        {isMobile && teamStatsContent}
      </Container>
    </TeamInfoWrapper>
  );
};
