import { DailyPerformerResponse, TeamLeaderboardResponse, TeamResponse } from './types';

export const mapTeamResponse = (response: TeamResponse) => {
  return {
    id: response.id,
    name: response.name,
    avatarPath: response.avatar_path,
    headerImagePath: response.header_image_path,
    createdAt: response.createdAt,
    updatedAt: response.updatedAt,
    deletedAt: response.deletedAt,
  };
};

export const mapTeamLeaderboardResponse = (response: TeamLeaderboardResponse) => {
  return {
    id: response?.id,
    teamId: response?.team_id,
    eventId: response?.event_id,
    currentRank: response?.current_rank,
    previousRank: response?.previous_rank,
    kills: response?.kills,
    assists: response?.assists,
    points: response?.points,
    teamTotalUsers: response?.team_total_users,
    createdAt: response?.createdAt,
    updatedAt: response?.updatedAt,
  };
};

export const mapDailyPerformerResponse = (response: DailyPerformerResponse) => {
  return {
    userId: response.user_id,
    teamId: response.team_id,
    username: response.username,
    playingAs: response.playing_as,
    userAvatar: response.user_avatar,
    currentRank: response.current_rank,
    previousRank: response.previous_rank,
    kills: response.kills,
    assists: response.assists,
    points: response.points,
    isMe: response.isMe,
  };
};
