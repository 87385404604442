import { Typography } from '../../components/typography';
import {
  Avatar,
  AvatarWrapper,
  HexagonDarkBackground,
  IsYouTag,
  PlayerCardContainer,
  PlayerCardWrapper,
  PlayerRank,
} from './PlayerCardStyled';
import { colors } from '../../theme';
import { ReactComponent as HexagonOutline } from '../../assets/svg/hexagon-outline.svg';
import { PlayerCardProps } from './types';

export const PlayerCard = ({ selected = false, player }: PlayerCardProps) => {
  return (
    <PlayerCardContainer>
      {selected && (
        <IsYouTag>
          <Typography variant="subtitle3" color={colors.primary}>
            You
          </Typography>
        </IsYouTag>
      )}

      <PlayerCardWrapper selected={selected}>
        <AvatarWrapper>
          <HexagonOutline fill={selected ? colors.black : colors.primary} width={140} height={153} />
          {selected && <HexagonDarkBackground />}
          <Avatar src={player.avatar} alt="performer-avatar" />
          <PlayerRank>
            <Typography variant="h7" color={colors.primary}>
              {player.rank}
            </Typography>
          </PlayerRank>
        </AvatarWrapper>

        <Typography mt={1.2} variant="body1" color={selected ? colors.black : colors.white}>
          {player.name}
        </Typography>

        <Typography
          display="flex"
          alignItems="center"
          mt={0.4}
          variant="subtitle2"
          color={selected ? colors.black : colors.transparentLight50}
        >
          Playing as:
          <Typography ml={0.4} paragraph variant="subtitle1" color={selected ? colors.black : colors.transparentLight50}>
            {player.playingAs ?? '-'}
          </Typography>
        </Typography>

        <Typography mt={0.4} variant="h7" color={selected ? colors.black : colors.primary}>
          {player.points}
        </Typography>
      </PlayerCardWrapper>
    </PlayerCardContainer>
  );
};
