import styled from '@emotion/styled';
import { breakpoints, colors, mediaQuery } from '../../theme';
import { DialogContentWrapper } from '../dialog';
import { ReactComponent as LoLLogo } from '../../assets/svg/game_logo_lol.svg';

export const GameRewardsContentWrapper = styled(DialogContentWrapper)`
  max-width: 512px;
`;

export const GameRewardsHeadline = styled.div`
  margin-top: 0.4rem;
  text-align: center;
`;

export const RewardWrapper = styled.div`
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
  display: flex;
  align-items: center;
`;

export const MobileRewardWrapper = styled.div`
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
  display: flex;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  background: ${colors.primary};
  width: 8.8rem;
  height: 8.8rem;
  display: flex;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  ${mediaQuery(breakpoints.mobile)} {
    width: 4rem;
    height: 4rem;
    border-radius: 8px;
  }
`;

export const LoLLogoStyled = styled(LoLLogo)`
  ${mediaQuery(breakpoints.mobile)} {
    width: 24px;
    height: 24px;
  }
`;

export const RewardDetails = styled.div`
  margin-left: 2.4rem;
  width: 100%;
`;

export const Reward = styled.div`
  display: flex;

  ${mediaQuery(breakpoints.mobile)} {
    margin-top: 1.6rem;
    margin-bottom: 3.6rem;
    justify-content: space-between;
  }
`;

export const RewardItem = styled.div`
  display: flex;
  margin-right: 3.2rem;

  ${mediaQuery(breakpoints.mobile)} {
    margin-right: 1rem;
    flex-shrink: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const RewardPoint = styled.div`
  margin-left: 0.8rem;
`;
