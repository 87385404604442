import { Table, TableBody, TableCell, TableHead, TableRow } from '../../../components/table';
import { breakpoints, colors } from '../../../theme';
import { Typography } from '../../../components/typography';
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrow-up-icon.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down-icon.svg';
import { BoardEmptyState } from '../../../molecules/boardEmptyState';
import { ViewDivider } from '../../../molecules/viewDivider';
import { CommunityState } from '../../community/CommunityState';
import { useToggle } from '../../../hooks/useToggle';
import { useMemo } from 'react';
import { sortBy, take } from 'lodash';
import { MAX_ITEMS_IN_TABLE } from '../helpers';
import {
  ArrowWrapper,
  Avatar,
  Description,
  InfoTableCell,
  InfoWrapper,
  IsYouChip,
  IsYouWrapper,
  MoveIconsWrapper,
  NumberTableCell,
  PointsText,
  StyledTableRow,
  LeaderboardRank,
} from '../../../molecules/leaderboard';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { LeaderboardMobileItem } from '../../../molecules/leaderboardMobileItem';
import { Container } from '../../../components/container';

export const EventLeaderboardTable = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { leaderboardTeams, isLoadingLeaderboardTeams, handleGotoTeamPage, isEventFinished } = CommunityState.useContainer();

  const { isOpen: isViewAllOpen, handleOpen: handleOpenViewAll } = useToggle();

  const initialLeaderboardTeams = useMemo(() => {
    const orderedLeaderboard = isEventFinished
      ? sortBy(leaderboardTeams, 'points').reverse()
      : sortBy(leaderboardTeams, ['isMyTeam', 'points']).reverse();

    return isViewAllOpen ? orderedLeaderboard : take(orderedLeaderboard, MAX_ITEMS_IN_TABLE);
  }, [isViewAllOpen, leaderboardTeams, isEventFinished]);

  const isDividerAppear = useMemo(
    () => leaderboardTeams && leaderboardTeams?.length > MAX_ITEMS_IN_TABLE && !isLoadingLeaderboardTeams && !isViewAllOpen,
    [leaderboardTeams, isViewAllOpen, isLoadingLeaderboardTeams]
  );

  if (!Boolean(leaderboardTeams) || leaderboardTeams?.length === 0) {
    return (
      <Container>
        <BoardEmptyState label="Teams and Player will show up here once they join" />
      </Container>
    );
  }

  if (isMobile) {
    return (
      <>
        {initialLeaderboardTeams?.map((item, index) => (
          <LeaderboardMobileItem
            key={item.teamId}
            data={{
              rowNumber: index + 1,
              primaryActive: isEventFinished && index === 0,
              secondaryActive: item.isMyTeam,
              avatar: item.teamAvatar,
              title: item.teamName,
              subtitle: `${item.totalMembers} players`,
              previousRank: item.previousRank,
              currentRank: item.currentRank,
              kills: item.kills,
              assists: item.assists,
              points: item.points,
            }}
            chipLabel="Your Team"
            onClick={() => handleGotoTeamPage(item.teamId)}
          />
        ))}

        {isDividerAppear && <ViewDivider label="View All" onClick={handleOpenViewAll} />}
      </>
    );
  }

  return (
    <Container>
      <Table>
        <TableHead>
          <TableRow>
            <NumberTableCell align="center">#</NumberTableCell>
            <TableCell align="left">Team</TableCell>
            <TableCell align="center">
              <MoveIconsWrapper>
                <ArrowWrapper>
                  <ArrowUpIcon width={10} height={10} fill={colors.transparentLight25} />
                </ArrowWrapper>

                <ArrowDownIcon width={10} height={10} fill={colors.transparentLight25} />
              </MoveIconsWrapper>
            </TableCell>
            <TableCell align="center">Kills</TableCell>
            <TableCell align="center">Assists</TableCell>
            <TableCell align="center">Points</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {initialLeaderboardTeams?.map((item, rowIndex) => (
            <StyledTableRow
              key={item.teamId}
              selected={isEventFinished && rowIndex === 0}
              selectedsecond={String(item.isMyTeam)}
              onClick={() => handleGotoTeamPage(item.teamId)}
            >
              <TableCell align="center">
                <IsYouWrapper>
                  {item.isMyTeam && <IsYouChip label="Your Team" variant="filled" size="small" color="primary" />}
                  <Typography variant="body1" color={colors.primary}>
                    {rowIndex + 1}
                  </Typography>
                </IsYouWrapper>
              </TableCell>
              <InfoTableCell align="left">
                <InfoWrapper>
                  <Avatar src={item.teamAvatar} alt="Avatar" />
                  <Description>
                    <Typography variant="body3" color={colors.white}>
                      {item.teamName}
                    </Typography>

                    <Typography variant="subtitle2" color={colors.transparentLight50}>
                      {`${item.totalMembers} players`}
                    </Typography>
                  </Description>
                </InfoWrapper>
              </InfoTableCell>
              <TableCell align="center">
                <LeaderboardRank currentRank={item.currentRank} previousRank={item.previousRank} />
              </TableCell>
              <TableCell align="center">{item.kills ?? '-'}</TableCell>
              <TableCell align="center">{item.assists ?? '-'}</TableCell>
              <TableCell align="center">
                <PointsText>{item.points ?? '-'}</PointsText>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {isDividerAppear && <ViewDivider label="View All" onClick={handleOpenViewAll} />}
    </Container>
  );
};
