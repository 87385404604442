import { useEffect, useState } from 'react';
import Routes from '../../constants/routes';
import { Link } from '../../components/link';
import { Link as ScrollLink } from 'react-scroll';
import CountUp from 'react-countup';
import { colors } from '../../theme';

import { Button } from '../../components/button';
import { Typography } from '../../components/typography';

import {
  LandingWrapper,
  TaglineWrapper,
  Tagline,
  ToolsWrapper,
  TaglineDescription,
  LoginWrapper,
  HeroSection,
  CreatorSection,
  CreatorDescription,
  ToolsSection,
  TrimSection,
  TrimContent,
  StatsDescription,
  StatsContent,
  TwitchIcon,
  LogosWrapper,
  SocMedSection,
  SocMedDescription,
  BrandsSection,
  BrandsContent,
  GrowContent,
  GrowDescription,
} from './LandingStyled';

import Grid from '@mui/material/Grid';
import ExternalLink from '@mui/material/Link';
import Box from '@mui/material/Box';

import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

import tool1Img from '../../assets/images/landing/tool1.png';
import tool2Img from '../../assets/images/landing/tool2.png';
import tool3Img from '../../assets/images/landing/tool3.png';
import tool4Img from '../../assets/images/landing/tool4.png';

import lacerationImg from '../../assets/images/landing/creator1_laceration.png';
import pinkWardImg from '../../assets/images/landing/creator2_pinkward.png';
import crumbzImg from '../../assets/images/landing/creator3_crumbz.png';
import spaceImg from '../../assets/images/landing/creator4_space.png';
import ryujincloImg from '../../assets/images/landing/creator5_ryujinclo.png';

import brandsImg from '../../assets/images/landing/brands.png';
import socMedImg from '../../assets/images/landing/socmed_following.png';
import demographicsImg from '../../assets/images/landing/demographics.png';

export const Landing = () => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(true);
  }, []);

  const creators = [
    { name: 'lacerration', imgSrc: lacerationImg, link: 'https://www.twitch.tv/lacerration', linkType: 'Twitch', following: '130,000' },
    { name: 'Pink Ward', imgSrc: pinkWardImg, link: 'https://www.twitch.tv/pinkwardlol', linkType: 'Twitch', following: '130,000' },
    { name: 'Crumbz', imgSrc: crumbzImg, link: 'https://twitter.com/crumbzworld', linkType: 'Instagram', following: '86,600' },
    { name: 'Latestinspace', imgSrc: spaceImg, link: 'https://twitter.com/latestinspace', linkType: 'Twitter', following: '834,000' },
    {
      name: 'Ryujinclo',
      imgSrc: ryujincloImg,
      link: 'https://www.instagram.com/ryujinclo/',
      linkType: 'Instagram',
      following: '1,000,000',
    },
  ];

  const getIcon = (linkType: string) => {
    switch (linkType) {
      case 'Instagram':
        return <InstagramIcon />;
      case 'Twitter':
        return <TwitterIcon />;
      case 'Twitch':
        return <TwitchIcon />;
      default:
        return <InstagramIcon />;
    }
  };

  return (
    <LandingWrapper>
      <HeroSection>
        <TaglineWrapper in={checked} {...(checked ? { timeout: 2000 } : {})} orientation={'vertical'}>
          <Tagline>
            <Typography variant="h2" color={colors.primary}>
              We put creators first
            </Typography>
            <TaglineDescription>
              <Typography>Full service influencer marketing & producing agency</Typography>
            </TaglineDescription>
          </Tagline>

          <LoginWrapper>
            <Link to={Routes.LOGIN}>
              <Button sx={{ mr: '2rem' }} type="submit" variant="contained" size="medium">
                Log in
              </Button>
            </Link>

            <ScrollLink to="who-are-we" smooth={true}>
              <Button sx={{ mr: '2rem' }} type="submit" variant="outlined" size="medium">
                More about us
              </Button>
            </ScrollLink>
          </LoginWrapper>
        </TaglineWrapper>
      </HeroSection>

      <TrimSection id="who-are-we">
        <TrimContent>
          <StatsDescription>
            <Typography variant="h3" mb={2} color={colors.black}>
              WHO ARE WE
            </Typography>
            <Typography mb={2}>Nemesis is a talent agency partnering with content creators and brands.</Typography>
          </StatsDescription>

          <StatsContent>
            <Typography mt={2}>Total Following</Typography>
            <Typography variant="h3">
              <CountUp end={25868600} duration={3} />
            </Typography>
          </StatsContent>
        </TrimContent>
      </TrimSection>

      <ToolsSection>
        <ToolsWrapper>
          <Grid container spacing={4} sx={{ mb: '9rem' }}>
            <Grid item sx={{ maxWidth: '25%' }}>
              <img src={tool1Img} alt="brand-deals" draggable="false" width={'100%'} />
            </Grid>
            <Grid item sx={{ maxWidth: '25%' }}>
              <img src={tool2Img} alt="software-tools" draggable="false" width={'100%'} />
            </Grid>
            <Grid item sx={{ maxWidth: '25%' }}>
              <img src={tool3Img} alt="strategic-development" draggable="false" width={'100%'} />
            </Grid>
            <Grid item sx={{ maxWidth: '25%' }}>
              <img src={tool4Img} alt="content-management" draggable="false" width={'100%'} />
            </Grid>
          </Grid>

          <Typography mb={2} color={colors.white}>
            We are here to shake up the agency world by truly putting creators first in everything we do.
            <br />
            <br />
            We will help you maximize your engagement, revenue, and growth.
          </Typography>
        </ToolsWrapper>
      </ToolsSection>

      <TrimSection id="creator-showcase">
        <CreatorDescription>
          <Typography variant="h3" mt={4} mb={2} color={colors.black}>
            CREATOR SHOWCASE
          </Typography>
          <Typography mb={2}>
            We're constantly on the lookout for amazing talent, here are some of the creators we currently work with
          </Typography>
        </CreatorDescription>
      </TrimSection>

      <CreatorSection>
        <Grid container spacing={1} justifyContent={'center'}>
          {creators.map((creator, index) => (
            <Grid item key={index} sx={{ mt: '2rem' }}>
              <Box
                sx={{
                  margin: '15px',
                  width: 240,
                  height: 240,
                  background: `url(${creator.imgSrc})`,
                  backgroundSize: 'contain',
                }}
              >
                <Box
                  sx={{
                    width: 240,
                    height: 240,
                    display: 'inline-grid',
                    alignItems: 'end',
                    backgroundColor: colors.transparentDark80,
                    transition: '0.1s',
                    opacity: 0,
                    '&:hover': {
                      opacity: 1,
                    },
                  }}
                >
                  <ExternalLink sx={{ msTransform: '2rem', marginInline: 'auto' }} href={creator.link} target={'_blank'}>
                    <Button variant="contained" color="primary" startIcon={getIcon(creator.linkType)}>
                      {creator.linkType}
                    </Button>
                  </ExternalLink>
                  <Typography mb={'2rem'} color={colors.white}>
                    Total following: {creator.following}
                  </Typography>
                </Box>
              </Box>

              <Typography color={colors.primary}>{creator.name}</Typography>
            </Grid>
          ))}
        </Grid>
      </CreatorSection>

      <SocMedSection>
        <SocMedDescription>
          <Typography variant={'h4'} mb={2} color={colors.white}>
            Total of 5+ million followers across all platforms
            <br />
            Generating 5+ billion impressions per month
          </Typography>
        </SocMedDescription>
        <LogosWrapper>
          <img src={socMedImg} alt="social-media-stats" draggable="false" width={'100%'} />
        </LogosWrapper>

        <SocMedDescription>
          <Typography variant={'h4'} mb={2} color={colors.white}>
            Audience demographic
          </Typography>
        </SocMedDescription>
        <LogosWrapper>
          <img src={demographicsImg} alt="demographics" draggable="false" width={'15%'} />
        </LogosWrapper>
      </SocMedSection>

      <BrandsSection id="partners">
        <BrandsContent>
          <Typography variant="h3" mt={6.4} mb={2.4} color={colors.black}>
            OUR PARTNERS
          </Typography>
          <Typography color={colors.black}>
            Our team has worked with the top brands in gaming and secured millions of dollars in sponsorship revenue.
          </Typography>
        </BrandsContent>

        <LogosWrapper>
          <img src={brandsImg} alt="partner-brands" draggable="false" width={'100%'} />
        </LogosWrapper>
      </BrandsSection>

      <TrimSection>
        <GrowContent>
          <Typography variant="h3" mt={6.4} mb={2.4} color={colors.black}>
            GROW WITH US TODAY
          </Typography>

          <GrowDescription>
            <Typography mb={2} color={colors.black}>
              Favorable pricing models compared to other talent agencies
              <br />
              <br />
              Active support for strategic brand development, collabs with other creators
              <br />
              <br />
              Brand deals are catered to your content
              <br />
              <br />
              In-house software tools to support your community and maximize engagement
            </Typography>
          </GrowDescription>
        </GrowContent>
      </TrimSection>
    </LandingWrapper>
  );
};
