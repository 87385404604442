import { createContainer } from 'unstated-next';
import { useEffect, useMemo, useState } from 'react';
import { StorageKeys } from '../../constants/storageKeys';
import { DecodedToken } from './types';
import jwtDecode from 'jwt-decode';
import { setAxiosToken } from '../../configs/axios';

const useAuth = () => {
  const [token, setToken] = useState<string | null>(localStorage.getItem(StorageKeys.JWT_TOKEN));

  const user: DecodedToken | null = useMemo(() => {
    if (!token) return null;
    return jwtDecode(token);
  }, [token]);

  const isTokenExpired = useMemo(() => {
    if (!user) return true;
    return user.exp < Date.now() / 1000;
  }, [user]);

  useEffect(() => {
    if (isTokenExpired) {
      clearAuthToken();
    }
  }, [isTokenExpired]);

  const setAuthToken = (token: string) => {
    localStorage.setItem(StorageKeys.JWT_TOKEN, token);
    setAxiosToken(token);
    setToken(token);
  };

  const clearAuthToken = () => {
    localStorage.removeItem(StorageKeys.JWT_TOKEN);
    setAxiosToken(null);
    setToken(null);
  };

  return { user, isTokenExpired, setAuthToken, clearAuthToken };
};

export const Auth = createContainer(useAuth);
